import { IGlobalState } from '../../rootReducer'

export interface ISigningViewUrls {
  signedUrl: string
  cancelledUrl: string
  expiredUrl: string
  deniedUrl: string
}

export interface ISigningViewActions {
  signedAction: any // Called after signed. etc. Dashboard success message.
  cancelledAction: any // Called after signing was cancelled. etc. Dashboard cancel message.
  expiredAction: any // Called after expired. etc. Dashboard fail message.
  deniedAction: any // Called after denied. etc. Dashboard fail message.
  cleanAction: any // Called after signed. etc. transfer/issuance draft cleaning
}

export interface ISigningViewTexts {
  text: any
  successText: any
  title: any
  successTitle: any
}

export const getUrls = (state: IGlobalState): ISigningViewUrls => {
  return {
    signedUrl: state.signingView.signedUrl,
    expiredUrl: state.signingView.expiredUrl,
    deniedUrl: state.signingView.deniedUrl,
    cancelledUrl: state.signingView.cancelledUrl,
  }
}

export const getIsInitial = (state: IGlobalState): boolean => {
  return state.signingView.isInitial
}

export const getActions = (state: IGlobalState): ISigningViewActions => {
  return {
    signedAction: state.signingView.signedAction,
    cancelledAction: state.signingView.cancelledAction,
    expiredAction: state.signingView.expiredAction,
    deniedAction: state.signingView.deniedAction,
    cleanAction: state.signingView.cleanAction,
  }
}

export const getTexts = (state: IGlobalState): ISigningViewTexts => {
  return {
    text: state.signingView.text,
    successText: state.signingView.successText,
    title: state.signingView.title,
    successTitle: state.signingView.successTitle,
  }
}

export const getStatus = (state: IGlobalState) => state.signingView.status
export const getType = (state: IGlobalState) => state.signingView.type
export const getSignActionId = (state: IGlobalState) => state.signingView.signActionId
export const getVerificationCode = (state: IGlobalState) => state.signingView.verificationCode
export const getAuthenticated = (state: IGlobalState) => state.auth.whoAmI.authenticated
