import { Action, handleActions } from 'redux-actions'
import {
  GetStockData,
  GetStockDataFail,
  GetStockDataSuccess,
  GetStockFormPrefaceData,
  GetStockFormPrefaceDataFailure,
  GetStockFormPrefaceDataSuccess,
  SaveStockFormPrefaceData,
  SaveDelawareStockAsInitial,
} from './StockFormActions'
import { GetStockList$Response, StockListDto, StockSeriesListDto, StockType } from 'services/api.types'
import { IStockFormFields, IStockFormTypes } from './StockFormFieldNames'
import { sortBy } from 'lodash'

export enum StockFormMode {
  SIMPLE = 'simple',
  FULL = 'full',
}

export enum CommonStockMode {
  SINGLE = 'single',
  CLASSES = 'classes',
}

export interface IStockFormMode {
  mode: StockFormMode | null
  commonStock: CommonStockMode | null
}

export interface IStockFormPrefaceData {
  formMode: IStockFormMode
  delawareStock: IStockFormTypes
}

class State {
  stock: IStockFormTypes = {
    commonStock: [],
    otherStock: [],
    preferredStock: [],
  }
  prefaceData: IStockFormPrefaceData = {
    formMode: {
      mode: null,
      commonStock: null,
    },
    delawareStock: {
      commonStock: [],
      otherStock: [],
      preferredStock: [],
    },
  }
  historicLedger: boolean = undefined
  prefaceDataLoading: boolean = false
  useDelawareAsInitial: boolean = false
  loading: boolean = false
  loaded: boolean = false
}

function getStockForm(stock: StockListDto): IStockFormFields {
  return {
    id: stock.id,
    isRequired: false,
    name: stock.stockName,
    firstInOrder: stock.firstInOrder,
    noParValue: !stock.parValue,
    parValue: stock.parValue,
    totalNumberOfShares: stock.totalNumberOfShares,
    type: stock.type,
    votesPerShare: stock.votingRights,
    voting: stock.voting,
    series: stock.series,
    parentId: undefined,
    parentTotalNumberOfShares: undefined,
  }
}

function getPreferredStockForm(stock: StockListDto, series: StockSeriesListDto): IStockFormFields {
  return {
    id: series.id,
    isRequired: false,
    name: series.stockName,
    firstInOrder: false,
    noParValue: !stock.parValue,
    parValue: series.parValue,
    totalNumberOfShares: series.totalNumberOfShares,
    type: stock.type,
    votesPerShare: series.votingRights,
    voting: series.voting,
    series: [],
    parentId: series.parentId,
    parentTotalNumberOfShares: stock.totalNumberOfShares,
  }
}

export default handleActions<State, any>(
  {
    /* Get stock Data */
    [String(GetStockData)]: (state: State): State => {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    },
    /* Get stock Data Success */
    [String(GetStockDataSuccess)]: (state: State, action: Action<GetStockList$Response>): State => {
      // TODO: move data handling with modifications to selectors
      // Convert payload into stock data
      let commonStock: IStockFormFields[] = action.payload.list
        .filter(stock => stock.type === StockType.COMMON)
        .map(stock => {
          return getStockForm(stock)
        })
      commonStock = sortBy(commonStock, stock => !stock.firstInOrder)

      let otherStock: IStockFormFields[] = action.payload.list
        .filter(stock => stock.type === StockType.OTHER)
        .map(stock => getStockForm(stock))
      otherStock = sortBy(otherStock, stock => !stock.firstInOrder)

      const filteredSeriesFromPreferredStock: any[] = action.payload.list
        .filter(stock => stock.type === StockType.PREFERRED && stock.series)
        .map(stock => {
          return stock.series.map(series => {
            return getPreferredStockForm(stock, series)
          })
        })
      let preferredStock: IStockFormFields[] = []
      filteredSeriesFromPreferredStock.map(stock => stock.map((serie: any) => preferredStock.push(serie)))

      // If preferred stock is defined but it doesn't have any series yet
      if (!preferredStock.length) {
        preferredStock = action.payload.list
          .filter(stock => stock.type === StockType.PREFERRED)
          .map(stock => getStockForm(stock))
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        stock: {
          commonStock,
          otherStock,
          preferredStock,
        },
      }
    },
    /* Get stock Data Fail */
    [String(GetStockDataFail)]: (state: State): State => {
      return {
        ...state,
        loading: false,
      }
    },
    /* Get preface data */
    [String(GetStockFormPrefaceData)]: (state: State): State => {
      return {
        ...state,
        prefaceDataLoading: true,
      }
    },
    [String(GetStockFormPrefaceDataSuccess)]: (state: State, action: Action<IStockFormPrefaceData>): State => {
      return {
        ...state,
        prefaceDataLoading: false,
        prefaceData: {
          ...state.prefaceData,
          ...action.payload,
        },
      }
    },
    [String(GetStockFormPrefaceDataFailure)]: (state: State): State => {
      return {
        ...state,
        prefaceDataLoading: false,
      }
    },
    /* Save preface data */
    [String(SaveStockFormPrefaceData)]: (state: State, action: Action<IStockFormPrefaceData>): State => {
      return {
        ...state,
        prefaceData: {
          ...state.prefaceData,
          ...action.payload,
        },
      }
    },
    [String(SaveDelawareStockAsInitial)]: (state: State, action: Action<boolean>): State => {
      return {
        ...state,
        useDelawareAsInitial: action.payload,
      }
    },
  },
  new State(),
)

export { State as StockFormState }
