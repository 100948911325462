import { combineReducers } from 'redux'
import { Action } from 'redux-actions'
import { RouterState } from 'connected-react-router'

import { LogOut } from 'views/login/LoginViewActions'
import auth, { AuthState } from 'common/auth/AuthReducer'
import notifications, { INotification, NotificationState } from 'common/store/notifications/NotificationReducer'
import transfersView, { TransfersViewState } from 'views/transfers/TransfersViewReducer'
import issuanceView, { IssuanceViewState } from 'views/issuance/IssuanceViewReducer'
import holderListView, { HolderListViewState } from 'views/holder-list/HolderListViewReducer'
import votingListView, { VotingListViewState } from 'views/voting-list/VotingListViewReducer'
import verificationView, { VerificationViewState } from 'views/verification/VerificationViewReducer'
import signingView, { SigningViewState } from 'views/signing/SigningViewReducer'
import recoverySmsView, { RecoverySmsViewState } from 'views/recovery-sms/RecoverySmsViewReducer'
import settingsView, { SettingsViewState } from 'views/settings/SettingsViewReducer'
import credentialsView, { CredentialsViewState } from 'views/settings/children/credentials/CredentialsViewReducer'
import userForm, { UserFormState } from 'views/settings/children/user/UserFormReducer'
import legalEntityForm, { LegalEntityFormState } from 'views/settings/children/legal-entity/LegalEntityFormReducer'
import companyForm, { CompanyFormState } from 'views/settings/children/company/CompanyFormReducer'
import companyHistory, { CompanyHistoryState } from 'views/settings/children/company-history/CompanyHistory.reducer'
import stockForm, { StockFormState } from 'views/settings/children/stock/StockFormReducer'
import ledgerPreferencesView, {
  LedgerPreferencesViewState,
} from 'views/settings/children/ledger-preferences/LedgerPreferences.reducer'
import dashboardView, { DashboardViewState } from 'views/dashboard/DashboardViewReducer'
import stocks, { StocksState } from 'common/store/stocks/stocksReducer'
import common, { CommonState } from 'common/store/common/commonReducer'
import registrationView, { RegistrationViewState } from 'views/registration/RegistrationViewReducer'

const appReducer = combineReducers({
  auth,
  notifications,
  stocks,
  common,
  verificationView,
  dashboardView,
  settingsView,
  transfersView,
  issuanceView,
  holderListView,
  votingListView,
  signingView,
  credentialsView,
  userForm,
  legalEntityForm,
  companyForm,
  companyHistory,
  stockForm,
  registrationView,
  recoverySmsView,
  ledgerPreferencesView,
})

const rootReducer = (state: IGlobalState, action: Action<INotification>) => {
  if (action.type === String(LogOut) && !!action.payload) {
    state = { ...state, notifications: { notifications: [action.payload] } }
  }

  return appReducer(state, action)
}

export interface IGlobalState {
  router: RouterState
  auth: AuthState
  notifications: NotificationState
  stocks: StocksState
  common: CommonState
  issuanceView: IssuanceViewState
  verificationView: VerificationViewState
  dashboardView: DashboardViewState
  settingsView: SettingsViewState
  transfersView: TransfersViewState
  holderListView: HolderListViewState
  votingListView: VotingListViewState
  signingView: SigningViewState
  credentialsView: CredentialsViewState
  userForm: UserFormState
  companyForm: CompanyFormState
  companyHistory: CompanyHistoryState
  legalEntityForm: LegalEntityFormState
  stockForm: StockFormState
  registrationView: RegistrationViewState
  recoverySmsView: RecoverySmsViewState
  ledgerPreferencesView: LedgerPreferencesViewState
}

export default rootReducer
