import * as React from 'react'

import TextField, { ITextFieldProps } from '../textfield/TextField'
import { ICONS } from '../../icon/Icon'
import { BaseStatefulComponent } from '../../../BaseStatefulComponent'

import './PassField.scss'

export interface IPassFieldProps extends ITextFieldProps {
  /**
   * Allow show/hide password
   */
  showToggle?: boolean
  /**
   * Show password strength bar
   */
  showStrengthBar?: boolean
}

export interface IPassFieldState {
  /**
   * If password should be visible.
   */
  isVisible: boolean
}

class PassField extends BaseStatefulComponent<IPassFieldProps, IPassFieldState> {
  constructor(props: IPassFieldProps) {
    super(props)

    this.state = {
      isVisible: false,
    }
  }

  onChange = (value: string): void => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  BEM(): string {
    const classArray = ['pass-field']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.value && this.props.showStrengthBar) {
      classArray.push('pass-field--strength-bar')
    }

    if (String(this.props.value).length >= 5) {
      classArray.push('pass-field--20')
    }

    if (String(this.props.value).length >= 7) {
      classArray.push('pass-field--40')
    }

    if (String(this.props.value).length >= 9) {
      classArray.push('pass-field--70')
    }

    if (String(this.props.value).length >= 11) {
      classArray.push('pass-field--100')
    }

    return classArray.join(' ')
  }

  onIconClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.setState(prevState => {
      return {
        isVisible: !prevState.isVisible,
      }
    })
  }

  getInputType(): string {
    return this.state.isVisible ? 'text' : 'password'
  }

  getIcon(): ICONS {
    return this.state.isVisible ? ICONS.VISIBLE : ICONS.INVISIBLE
  }

  render(): JSX.Element {
    return (
      <TextField
        {...this.props}
        className={this.BEM()}
        inputClass="pass-field__input"
        type={this.getInputType()}
        onChange={this.onChange}
        value={this.props.value}
        iconRight={this.props.showToggle && this.getIcon()}
        isIconClickable={this.props.showToggle && true}
        onIconClick={this.props.showToggle && this.onIconClick}
        autocomplete
      />
    )
  }
}

export default PassField
