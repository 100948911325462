import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  AddDashboardNotification,
  GenerateContractFile,
  GenerateContractFileFail,
  GenerateContractFileSuccess,
  GetDashboard,
  GetDashboardDataOnly,
  GetDashboardDataOnlyFail,
  GetDashboardDataOnlySuccess,
  GetDashboardFail,
  GetDashboardSuccess,
  GetDrafts,
  GetDraftsFailure,
  GetDraftsSuccess,
  RolesDisabled,
  SignLedgerContract,
  SignLedgerContractFileFinished,
  SignLedgerContractFileStarted,
} from './DashboardViewActions'
import { getI18n } from 'react-i18next'
import api from 'services/Api.services'
import { INewTransferFormFields } from '../transfers/children/new-transfer-form/NewTransferFormFieldNames'
import {
  CreateLegalHolder$Request,
  CreateNaturalHolder$Request,
  GenerateContractFile$Request,
  GenerateContractFile$Response,
  GetDashboard$Response,
  GetMyPendingTransferList$Response,
  HolderType,
  MyPendingTransferListDto,
  ProfileRole,
  SaveLedgerHistoricImportDraftTransfer$Request,
  SignLedgerContract$Request,
  SignLedgerContract$Response,
  UpdateLegalEntityHolderDetails$Request,
  UpdateNaturalPersonHolderDetails$Request,
  WhoAmI$Response,
} from 'services/api.types'
import { IIssuanceDraft } from '../issuance/IssuanceViewReducer'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { addNotification } from 'common/store/notifications/NotificationActions'
import RoleUtil from 'common/utils/roleUtil'
import { userDetails, userRole } from 'common/auth/AuthSelectors'
import { Sign } from '../signing/SigningViewActions'
import { Action } from 'redux-actions'
import { DashboardNotificationType } from './children/notification/DashboardNotification'
import { ICancelledTransferDraft } from '../transfers/children/cancel-transfer-form/CancelTransferFormFieldNames'
import DraftStorage from 'common/utils/draftStorage'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { SessionStorageKeys } from 'common/constants/session-storage-keys'
import { paths } from 'common/router/routePaths'
import { push } from 'connected-react-router'

/**
 * Used for fetching only dashboard endpoint data
 */
function* sagaGetDashboardDataOnly() {
  try {
    const payload: GetDashboard$Response = yield call(api.ledger.getDashboard)
    yield put({ type: String(GetDashboardDataOnlySuccess), payload })
  } catch (e) {
    console.log('sagaGetDashboardDataOnly failed:', e)
    yield put({ type: String(GetDashboardDataOnlyFail) })
  }
}

/**
 * Used for getting all dashbboard/onboarding related data
 */
function* sagaGetDashboard() {
  try {
    const dashboard: GetDashboard$Response = yield call(api.ledger.getDashboard)
    const whoAmI: WhoAmI$Response = yield select(userDetails)
    const userDisabled = !whoAmI.hasProfiles && dashboard.progress.hasDisabledProfiles
    const rolesDisabled =
      !whoAmI.hasProfiles &&
      !whoAmI.ledgerCreation &&
      !dashboard.progress.additionalAdminOnboarding &&
      !dashboard.progress.ledgerExists

    yield put({ type: String(RolesDisabled), payload: userDisabled || rolesDisabled })

    if (userDisabled) {
      yield put({
        type: String(AddDashboardNotification),
        payload: {
          title: getI18n().t('error.no-active-profiles', {
            ns: 'frontendNotifications',
          }),
          type: NotificationType.ERROR,
        },
      })
    } else if (rolesDisabled) {
      yield put({
        type: String(AddDashboardNotification),
        payload: {
          title: getI18n().t('error.no-profiles', {
            ns: 'frontendNotifications',
          }),
          type: NotificationType.ERROR,
        },
      })
    } else if (dashboard.ledger && dashboard.ledger.subscriptionExpired) {
      const currentRole: ProfileRole = yield select(userRole)
      const isAdmin = RoleUtil.matchAll([ProfileRole.LEDGER_ADMIN], [currentRole])
      if (isAdmin) {
        yield put({
          type: String(AddDashboardNotification),
          payload: {
            title: getI18n().t('dashboard:ledger-subscription-expired.title'),
            text: getI18n().t('dashboard:ledger-subscription-expired.text'),
            type: DashboardNotificationType.INFO,
          },
        })
      }
    }
    yield put({ type: String(GetDashboardSuccess), payload: dashboard })
  } catch (e) {
    yield put({ type: String(GetDashboardFail), payload: e.message })
    console.log('sagaGetDashboard failed:', e)
  }
}

function* sagaGetDrafts(): any {
  try {
    let issuances: IIssuanceDraft[] = yield call(DraftStorage.get, DraftStorageEnum.ISSUANCES) || []
    issuances = issuances?.filter(issuance => issuance.addedAccounts.length > 0)

    const transfer: INewTransferFormFields = yield call(DraftStorage.get, DraftStorageEnum.NEW_TRANSFER)
    const roles: ProfileRole = yield select(userRole)
    const isHolder = RoleUtil.matchAll([ProfileRole.HOLDER], [roles])
    let pendingTransfers: MyPendingTransferListDto[] = []

    if (isHolder) {
      const pendingTransfersList: GetMyPendingTransferList$Response = yield call(api.ledger.getMyPendingTransferList)
      pendingTransfers = pendingTransfersList.list
    }

    const cancelledTransfers: ICancelledTransferDraft = yield call(
      DraftStorage.get,
      DraftStorageEnum.CANCELLED_TRANSFER,
    )

    yield put({
      type: String(GetDraftsSuccess),
      payload: { issuances, transfer, pendingTransfers, cancelledTransfers, todoItems: [] }, // TODO: check what todoItems is meant for
    })
  } catch (e) {
    const notification: INotification = {
      ...e?.response?.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(GetDraftsFailure) })
    yield put({ type: String(addNotification), payload: notification })
    console.log('sagaGetDrafts failed:', e)
  }
}

function* sagaGenerateContractFile({ payload }: Action<GenerateContractFile$Request>) {
  try {
    const response: GenerateContractFile$Response = yield call(api.ledger.generateContractFile, payload)

    yield put({ type: String(GenerateContractFileSuccess), payload: response })

    window.scrollTo(0, 0)
  } catch (e) {
    yield put({ type: String(GenerateContractFileFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaSignLedgerContract({ payload }: Action<SignLedgerContract$Request>) {
  try {
    yield put({ type: String(SignLedgerContractFileStarted) })
    const response: SignLedgerContract$Response = yield call(api.ledger.signLedgerContract, payload)
    yield put({ type: String(SignLedgerContractFileFinished) })

    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.onboarding-completed', {
          ns: 'frontendNotifications',
        }),
      },
    }

    // redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        isInitial: true,
      },
    })

    sessionStorage.removeItem(SessionStorageKeys.SHOW_ONBOARDING_GETTING_STARTED)
  } catch (e) {
    const notification: INotification = {
      description: getI18n().t('error.ledger-contract-signing', {
        ns: 'frontendNotifications',
      }),
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export interface ICreateLedgerHistoricHolderPayload {
  naturalPersonRequest: CreateNaturalHolder$Request | null
  legalEntityRequest: CreateLegalHolder$Request | null
  transferRequest: Omit<SaveLedgerHistoricImportDraftTransfer$Request, 'toAccountId' | 'id'>
  type: HolderType
}

export interface IUpdateLedgerHistoricHolderPayload {
  naturalPersonRequest: UpdateNaturalPersonHolderDetails$Request | null
  legalEntityRequest: UpdateLegalEntityHolderDetails$Request | null
  transferRequest: SaveLedgerHistoricImportDraftTransfer$Request
  type: HolderType
}

function* sagaAddDashboardNotification() {
  yield window.scrollTo(0, 0)
  yield put(push(paths.dashboard.root))
}

export default function* dashboardViewSagas() {
  yield all([
    takeLatest(String(GetDashboardDataOnly), sagaGetDashboardDataOnly),
    takeLatest(String(GetDashboard), sagaGetDashboard),
    takeLatest(String(GetDrafts), sagaGetDrafts),
    takeLatest(String(SignLedgerContract), sagaSignLedgerContract),
    takeLatest(String(GenerateContractFile), sagaGenerateContractFile),
    takeLatest(String(AddDashboardNotification), sagaAddDashboardNotification),
  ])
}
