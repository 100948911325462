import { createAction } from 'redux-actions'

const ns = 'signing/'

export const Sign = createAction(`${ns}SIGN`)
export const SetSigningUrls = createAction(`${ns}SET_SIGNING_URLS`)
export const ClearSigningData = createAction(`${ns}CLEAR_SIGNING_DATA`)
export const GetSigningStatus = createAction(`${ns}GET_SIGNING_STATUS`)
export const CompleteSigning = createAction(`${ns}COMPLETE_SIGNING`)
export const InitRecoverBySms = createAction(`${ns}INIT_RECOVER_BY_SMS`)
export const CancelSigning = createAction(`${ns}CANCEL_SIGNING`)

export const SetStatus = createAction(`${ns}SET_SIGNING_STATUS`)
