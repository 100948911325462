import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import {
  GetUserData,
  GetUserDataFail,
  GetUserDataSuccess,
  SaveUserOnboardingDetails,
  SetLoading,
  UpdateUserDetails,
} from './UserFormActions'
import { GetUserDetails$Response, SaveUserOnboardingDetails$Request } from 'services/api.types'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { Action } from 'redux-actions'
import { push } from 'connected-react-router'
import { paths } from 'common/router/routePaths'
import { AddDashboardNotification, GetDashboard } from 'views/dashboard/DashboardViewActions'
import { whoAmI } from 'common/auth/AuthActions'
import { IUserFormFields } from './UserFormFieldNames'
import api from 'services/Api.services'
import { NotificationType } from 'components/ui/notification/Notification'
import { getUserDetailsLoading } from './UserFormSelectors'
import { SetLoaded } from 'views/issuance/IssuanceViewActions'

function* sagaGetUserData() {
  try {
    const UserData: GetUserDetails$Response = yield call(api.user.getUserDetails)
    const data: IUserFormFields = { tin: '', holderId: null, accountId: null, ...UserData }
    yield put({ type: String(GetUserDataSuccess), payload: data })
  } catch (e) {
    yield put({ type: String(GetUserDataFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaSaveUserOnboardingDetails(action: Action<SaveUserOnboardingDetails$Request>) {
  const isLoading: boolean = yield select(getUserDetailsLoading)
  if (isLoading) {
    return
  }
  try {
    yield put({ type: String(SetLoading) })
    yield call(api.user.saveUserOnboardingDetails, action.payload)

    yield put({ type: String(whoAmI) })
    yield put({ type: String(GetDashboard) })

    yield put(push(paths.onboarding.root))
    yield put({ type: String(SetLoaded) })
  } catch (e) {
    yield put({ type: String(SetLoaded) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaUpdateUserDetails(action: Action<SaveUserOnboardingDetails$Request>) {
  const isLoading: boolean = yield select(getUserDetailsLoading)
  if (isLoading) {
    return
  }
  try {
    yield put({ type: String(SetLoading) })
    yield call(api.user.updateUserDetails, action.payload)
    yield put(push(paths.dashboard.root))

    yield put({ type: String(whoAmI) })

    // set dashboard success message
    yield put({
      type: String(AddDashboardNotification),
      payload: {
        title: i18n.t('frontendNotifications:success.update-user-details'),
      },
    })
    yield put({ type: String(SetLoaded) })
  } catch (e) {
    yield put({ type: String(SetLoaded) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* UserFormSagas() {
  yield all([
    takeEvery(String(GetUserData), sagaGetUserData),
    takeEvery(String(SaveUserOnboardingDetails), sagaSaveUserOnboardingDetails),
    takeEvery(String(UpdateUserDetails), sagaUpdateUserDetails),
  ])
}
