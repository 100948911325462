import { Action, handleActions } from 'redux-actions'
import { GetUserData, GetUserDataFail, GetUserDataSuccess } from './UserFormActions'
import { IUserFormFields } from './UserFormFieldNames'
import { SetLoaded, SetLoading } from 'views/issuance/IssuanceViewActions'

class State {
  user: IUserFormFields = {
    id: '',
    holderId: '',
    accountId: '',
    givenNames: '',
    middleName: '',
    nameSuffix: '',
    lastName: '',
    ssn: '',
    tin: '',
    address: {
      apartment: '',
      buildingNumber: '',
      city: '',
      country: '',
      state: '',
      streetAddress: '',
      zipCode: '',
    },
    dateOfBirth: '',
    documents: [],
    previousNames: [],
  }
  loading: boolean = false
  loaded: boolean = false
}

export default handleActions<State, any>(
  {
    /* Get User Data */
    [String(GetUserData)]: (state: State): State => {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    },
    /* Get User Data Success */
    [String(GetUserDataSuccess)]: (state: State, action: Action<IUserFormFields>): State => {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        loading: false,
        loaded: true,
      }
    },
    /* Get User Data Fail */
    [String(GetUserDataFail)]: (state: State): State => {
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    },
    [String(SetLoading)]: (state: State): State => {
      return { ...state, loading: true }
    },
    [String(SetLoaded)]: (state: State): State => {
      return { ...state, loading: false }
    },
  },
  new State(),
)

export { State as UserFormState }
