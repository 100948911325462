import createFieldNameConstants from 'common/utils/fieldNames'

export interface IChangePasswordFieldNames {
  newPassword: string
  newPasswordRepeat: string
  oldPassword: string
}

export const fieldNamesSource: IChangePasswordFieldNames = {
  newPassword: '',
  newPasswordRepeat: '',
  oldPassword: '',
}
const fieldNames = createFieldNameConstants(fieldNamesSource)

export default fieldNames
