import { all, call, put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import api from 'services/Api.services'
import {
  GetTransferRestrictions$Response,
  SaveTransferRestrictions$Request,
  SaveTransferRestrictions$Response,
} from 'services/api.types'
import {
  GetTransferRestrictions,
  GetTransferRestrictionsFailure,
  GetTransferRestrictionsSuccess,
  SaveTransferRestrictions,
  SaveTransferRestrictionsFailure,
  SaveTransferRestrictionsSuccess,
} from './LedgerPreferences.actions'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'

function* sagaGetTransferRestrictions() {
  try {
    const payload: GetTransferRestrictions$Response = yield call(api.ledger.getTransferRestrictions)
    yield put({ type: String(GetTransferRestrictionsSuccess), payload })
  } catch (e) {
    yield put({ type: String(GetTransferRestrictionsFailure) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaSaveTransferRestrictions(action: Action<SaveTransferRestrictions$Request>) {
  try {
    const payload: SaveTransferRestrictions$Response = yield call(api.ledger.saveTransferRestrictions, action.payload)
    yield put({ type: String(SaveTransferRestrictionsSuccess), payload })
  } catch (e) {
    yield put({ type: String(SaveTransferRestrictionsFailure) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* settingsViewSagas() {
  yield all([
    takeLatest(String(GetTransferRestrictions), sagaGetTransferRestrictions),
    takeLatest(String(SaveTransferRestrictions), sagaSaveTransferRestrictions),
  ])
}
