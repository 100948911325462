import { Action, handleActions } from 'redux-actions'
import {
  GetLegalEntityDetails,
  GetLegalEntityDetailsSuccess,
  GetLegalEntityDetailsFail,
} from './LegalEntityFormActions'
import { ILegalEntityFields } from './LegalEntityFormFieldNames'

class State {
  legalEntityCompany: ILegalEntityFields = {
    address: {
      apartment: '',
      buildingNumber: '',
      city: '',
      country: '',
      state: '',
      streetAddress: '',
      zipCode: '',
    },
    streetAddress: '',
    apartment: '',
    name: '',
    tin: '',
  }
  loading: boolean = false
  loaded: boolean = false
}

export default handleActions<State, any>(
  {
    /* Get Legal Entity Company Data */
    [String(GetLegalEntityDetails)]: (state: State): State => {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    },
    /* Get Legal Entity Company Data Success */
    [String(GetLegalEntityDetailsSuccess)]: (state: State, action: Action<ILegalEntityFields>): State => {
      return {
        legalEntityCompany: action.payload,
        loading: false,
        loaded: true,
      }
    },
    /* Get Legal Entity Company Data Fail */
    [String(GetLegalEntityDetailsFail)]: (state: State): State => {
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    },
  },
  new State(),
)

export { State as LegalEntityFormState }
