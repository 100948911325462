import moment from 'moment'

export interface ILocalStorageResponse<T> {
  data: T
  changeDate: string
}

export default class LS<ILS> {
  static put(key: string, value: any): void {
    value = { data: value, changeDate: moment() }
    localStorage.setItem(key, JSON.stringify(value))
  }

  static get<T>(key: string): ILocalStorageResponse<T> {
    return JSON.parse(localStorage.getItem(key))
  }

  static getData<T>(key: string): T {
    return this.get<T>(key) ? this.get<T>(key).data : null
  }

  static remove(key: string): void {
    localStorage.removeItem(key)
  }

  static clear(): void {
    localStorage.clear()
  }
}
