import { Action, handleActions } from 'redux-actions'
import {
  ChangeView,
  GetContractListSuccess,
  GetLedgerUserList,
  GetLedgerUserListFail,
  GetLedgerUserListSuccess,
  GetPendingLedgerUserList,
  GetPendingLedgerUserListFail,
  GetPendingLedgerUserListSuccess,
} from './SettingsViewActions'
import { ContractListDto, LedgerAdminUserDto, PendingLedgerAdminEmailDto } from 'services/api.types'

export enum SettingsViews {
  CREDENTIALS = 'CREDENTIALS',
  COMPANY = 'COMPANY',
  COMPANY_HISTORY = 'COMPANY_HISTORY',
  USER = 'USER',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  STOCK_LEDGER_AUDIT = 'STOCK_LEDGER_AUDIT',
  LEDGER_PREFERENCES = 'LEDGER_PREFERENCES',
}

class State {
  settingsView: SettingsViews = SettingsViews.USER
  ledgerUserList: LedgerAdminUserDto[] = []
  ledgerUserListLoading: boolean = false
  ledgerUserListLoaded: boolean = false
  pendingLedgerUserList: PendingLedgerAdminEmailDto[] = []
  pendingLedgerUserListLoading: boolean = false
  pendingLedgerUserListLoaded: boolean = false
  contractList: ContractListDto[] = []
}

export default handleActions<State, any>(
  {
    /* Change settingsView */
    [String(ChangeView)]: (state: State, action: Action<SettingsViews>): State => {
      return {
        ...state,
        settingsView: action.payload,
      }
    },
    [String(GetLedgerUserList)]: (state: State): State => {
      return { ...state, ledgerUserListLoading: true }
    },
    [String(GetLedgerUserListSuccess)]: (state: State, action: Action<LedgerAdminUserDto[]>): State => {
      return {
        ...state,
        ledgerUserListLoading: false,
        ledgerUserListLoaded: true,
        ledgerUserList: action.payload,
      }
    },
    [String(GetLedgerUserListFail)]: (state: State): State => {
      return { ...state, ledgerUserListLoading: false, ledgerUserListLoaded: true }
    },
    [String(GetPendingLedgerUserList)]: (state: State): State => {
      return { ...state, pendingLedgerUserListLoading: true }
    },
    [String(GetPendingLedgerUserListSuccess)]: (state: State, action: Action<PendingLedgerAdminEmailDto[]>): State => {
      return {
        ...state,
        pendingLedgerUserListLoading: false,
        pendingLedgerUserListLoaded: true,
        pendingLedgerUserList: action.payload,
      }
    },
    [String(GetPendingLedgerUserListFail)]: (state: State): State => {
      return { ...state, pendingLedgerUserListLoading: false, pendingLedgerUserListLoaded: true }
    },
    [String(GetContractListSuccess)]: (state: State, action: Action<ContractListDto[]>): State => {
      return {
        ...state,
        contractList: action.payload,
      }
    },
  },
  new State(),
)

export { State as SettingsViewState }
