import * as React from 'react'
import { BaseComponent } from 'components/BaseComponent'
import { default as Icon, ICONS } from 'components/ui/icon/Icon'

import Container from 'components/ui/container/Container'
import GroupItems from 'components/ui/group-items/GroupItems'
import GroupItem from 'components/ui/group-items/GroupItem'
import Button from 'components/ui/button/Button'

import './DashboardNotification.scss'

export enum DashboardNotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface IDashboardNotificationProps {
  /**
   * Notification title
   */
  title?: string
  /**
   * Notification text
   */
  text?: string | JSX.Element | JSX.Element[]
  /**
   * Redirect link in notification
   */
  redirectUrl?: { url: string; text: string }
  /**
   * Show download link
   */
  downloadFile?: boolean
  /**
   * Additional classes.
   */
  className?: string
  /**
   * Dashboard notification type
   */
  type?: DashboardNotificationType
  /**
   * Message icon
   */
  icon?: ICONS
  /**
   * Clears/closes the notification
   */
  clearNotification: () => void
}

class DashboardNotification extends BaseComponent<IDashboardNotificationProps> {
  static defaultProps: Partial<IDashboardNotificationProps> = {
    downloadFile: false,
    type: DashboardNotificationType.SUCCESS,
  }

  BEM(): string {
    const classArray = ['dashboard-notification']

    classArray.push(`dashboard-notification--${this.props.type.toLowerCase()}`)

    return classArray.join(' ')
  }

  render(): JSX.Element {
    const icon: ICONS = this.props.type === DashboardNotificationType.SUCCESS ? ICONS.CHECKED : ICONS.ERROR
    return (
      <div className={this.BEM()}>
        <Container>
          <GroupItems align="space-between" valign="baseline" inline={true}>
            <GroupItem>
              <GroupItems inline={true} valign="baseline">
                <GroupItem valign="center">
                  <Icon className="dashboard-notification__icon" size="lg" name={icon} />
                </GroupItem>
                <GroupItem>
                  <h3 className="dashboard-notification__title">{this.props.title}</h3>
                </GroupItem>
                <GroupItem>
                  <p className="dashboard-notification__text">{this.props.text}</p>
                </GroupItem>
                <GroupItem>
                  {this.props.redirectUrl && (
                    <Button
                      link={true}
                      block={false}
                      url={this.props.redirectUrl.url}
                      text={this.props.redirectUrl.text}
                      iconRight={ICONS.ARROW_RIGHT}
                      variant="link"
                      className="dashboard-notification__link"
                    />
                  )}
                </GroupItem>
              </GroupItems>
            </GroupItem>
            <GroupItem>
              {this.props.downloadFile && <Button link={true} variant="alt" text="Download file" />}
              {!this.props.downloadFile && (
                <Icon
                  className="dashboard-notification__remove"
                  name={ICONS.CROSS}
                  onClick={this.props.clearNotification}
                />
              )}
            </GroupItem>
          </GroupItems>
        </Container>
      </div>
    )
  }
}

export default DashboardNotification
