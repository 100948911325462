import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { getApplicationEnvironment } from 'common/utils/applicationEnvironment'

Sentry.init({
  dsn: 'https://56f173cb9c1042688be6c031bb388632@o447760.ingest.sentry.io/5448726',
  release: 'stocktreasury-web@' + process.env.npm_package_version,
  integrations: [new TracingIntegrations.BrowserTracing()],
  environment: getApplicationEnvironment(),
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

export default Sentry
