import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { BaseStatefulComponent } from 'components/BaseStatefulComponent'
import { paths } from './routePaths'
import { Dispatchable0, Dispatchable1 } from 'redux-dispatchers'
import RouterLoadable from './RouterLoadable'
import PrivateRoute, { IRouteGuard } from './PrivateRoute'
import {
  GetDashboard$Response$Progress,
  LedgerWithProfilesDto,
  ProfileRole,
  SelectProfile$Request,
  StockListDto,
  TransferListDto,
  WhoAmI$Response,
  WhoAmI$Response$CurrentProfile,
} from 'services/api.types'
import RoleUtil from '../utils/roleUtil'

import HeaderWrapper from 'components/ui/header/HeaderWrapper'
import FooterWrapper from 'components/ui/footer/FooterWrapper'
import LS from '../utils/localStorage'
import { USER_CHANGE } from '../constants/localstorage-keys'
import { configLocale } from '../utils/locale'
import Background, { IBackgroundProps } from 'components/ui/background/Background'
import { GlobalNotificationIntent } from 'components/ui/global-notification/GlobalNotification'
import GroupItems from 'components/ui/group-items/GroupItems'
import Loader from 'components/ui/loader/Loader'
import { getI18n } from 'react-i18next'

// dynamic component loading
const AsyncDashboardViewWrapper = RouterLoadable({
  loader: () => import('views/dashboard/DashboardViewWrapper'),
  modules: ['views/dashboard/DashboardViewWrapper'],
  webpack: () => [require.resolveWeak('views/dashboard/DashboardViewWrapper')],
})
const AsyncOnboardingViewWrapper = RouterLoadable({
  loader: () => import('views/onboarding/OnboardingView.wrapper'),
  modules: ['views/onboarding/OnboardingView.wrapper'],
  webpack: () => [require.resolveWeak('views/onboarding/OnboardingView.wrapper')],
})
const AsyncHolderListViewWrapper = RouterLoadable({
  loader: () => import('views/holder-list/HolderListViewWrapper'),
  modules: ['views/holder-list/HolderListViewWrapper'],
  webpack: () => [require.resolveWeak('views/holder-list/HolderListViewWrapper')],
})
const AsyncEditStockHolderDetailsViewWrapper = RouterLoadable({
  loader: () => import('views/holder-list/children/edit-stock-holder/EditStockHolderDetailsViewWrapper'),
  modules: ['views/holder-list/children/edit-stock-holder/EditStockHolderDetailsViewWrapper'],
  webpack: () => [
    require.resolveWeak('views/holder-list/children/edit-stock-holder/EditStockHolderDetailsViewWrapper'),
  ],
})
const AsyncViewStockHolderDetailsViewWrapper = RouterLoadable({
  loader: () => import('views/holder-list/children/view-stock-holder/ViewStockHolderDetailsViewWrapper'),
  modules: ['views/holder-list/children/view-stock-holder/ViewStockHolderDetailsViewWrapper'],
  webpack: () => [
    require.resolveWeak('views/holder-list/children/view-stock-holder/ViewStockHolderDetailsViewWrapper'),
  ],
})
const AsyncVotingListViewWrapper = RouterLoadable({
  loader: () => import('views/voting-list/VotingListViewWrapper'),
  modules: ['views/voting-list/VotingListViewWrapper'],
  webpack: () => [require.resolveWeak('views/voting-list/VotingListViewWrapper')],
})
const AsyncNotificationViewWrapper = RouterLoadable({
  loader: () => import('views/notification/NotificationView'),
  modules: ['views/notification/NotificationView'],
  webpack: () => [require.resolveWeak('views/notification/NotificationView')],
})
const AsyncBulkImportViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/bulk-import-view/BulkImportViewWrapper'),
  modules: ['views/transfers/children/bulk-import-view/BulkImportViewWrapper'],
  webpack: () => [require.resolveWeak('views/transfers/children/bulk-import-view/BulkImportViewWrapper')],
})
const AsyncBulkImportPreviewViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/bulk-import-preview-view/BulkImportPreviewViewWrapper'),
  modules: ['views/transfers/children/bulk-import-preview-view/BulkImportPreviewViewWrapper'],
  webpack: () => [
    require.resolveWeak('views/transfers/children/bulk-import-preview-view/BulkImportPreviewViewWrapper'),
  ],
})
const AsyncNewTransfersViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/new-transfer-view/NewTransferViewWrapper'),
  modules: ['views/transfers/children/new-transfer-view/NewTransferViewWrapper'],
  webpack: () => [require.resolveWeak('views/transfers/children/new-transfer-view/NewTransferViewWrapper')],
})
const AsyncNewTransfersHistoricViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/new-transfer-historic-view/NewTransferHistoricViewWrapper'),
  modules: ['views/transfers/children/new-transfer-historic-view/NewTransferHistoricViewWrapper'],
  webpack: () => [
    require.resolveWeak('views/transfers/children/new-transfer-historic-view/NewTransferHistoricViewWrapper'),
  ],
})
const AsyncCancelTransfersViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/cancel-transfer-view/CancelTransferViewWrapper'),
  modules: ['views/transfers/children/cancel-transfer-view/CancelTransferViewWrapper'],
  webpack: () => [require.resolveWeak('views/transfers/children/cancel-transfer-view/CancelTransferViewWrapper')],
})
const AsyncCancelTransferReportViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/cancel-transfer-report-view/CancelTransferReportViewWrapper'),
  modules: ['views/transfers/children/cancel-transfer-report-view/CancelTransferReportViewWrapper'],
  webpack: () => [
    require.resolveWeak('views/transfers/children/cancel-transfer-report-view/CancelTransferReportViewWrapper'),
  ],
})
const AsyncTransferReportHistoricViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/transfer-report-historic-view/TransferReportHistoricViewWrapper'),
  modules: ['views/transfers/children/transfer-report-historic-view/TransferReportHistoricViewWrapper'],
  webpack: () => [
    require.resolveWeak('views/transfers/children/transfer-report-historic-view/TransferReportHistoricViewWrapper'),
  ],
})
const AsyncTransfersDetailsViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/transfer-details-view/TransferDetailsViewWrapper'),
  modules: ['views/transfers/children/transfer-details-view/TransferDetailsViewWrapper'],
  webpack: () => [require.resolveWeak('views/transfers/children/transfer-details-view/TransferDetailsViewWrapper')],
})
const AsyncMagicLinkViewWrapper = RouterLoadable({
  loader: () => import('views/magic-link/MagicLinkViewWrapper'),
  modules: ['views/magic-link/MagicLinkViewWrapper'],
  webpack: () => [require.resolveWeak('views/magic-link/MagicLinkViewWrapper')],
})
const AsyncLoginViewWrapper = RouterLoadable({
  loader: () => import('views/login/LoginViewWrapper'),
  modules: ['views/login/LoginViewWrapper'],
  webpack: () => [require.resolveWeak('views/login/LoginViewWrapper')],
})
const AsyncForgotPasswordViewWrapper = RouterLoadable({
  loader: () => import('views/forgot-password/ForgotPasswordViewWrapper'),
  modules: ['views/forgot-password/ForgotPasswordViewWrapper'],
  webpack: () => [require.resolveWeak('views/forgot-password/ForgotPasswordViewWrapper')],
})
const AsyncResetPasswordViewWrapper = RouterLoadable({
  loader: () => import('views/forgot-password/children/reset-password/ResetPasswordViewWrapper'),
  modules: ['views/forgot-password/children/reset-password/ResetPasswordViewWrapper'],
  webpack: () => [require.resolveWeak('views/forgot-password/children/reset-password/ResetPasswordViewWrapper')],
})
const AsyncRecoverySmsLinkViewWrapper = RouterLoadable({
  loader: () => import('views/recovery-sms/RecoverySmsViewWrapper'),
  modules: ['views/recovery-sms/RecoverySmsViewWrapper'],
  webpack: () => [require.resolveWeak('views/recovery-sms/RecoverySmsViewWrapper')],
})
const AsyncTransfersViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/TransfersViewWrapper'),
  modules: ['views/transfers/TransfersViewWrapper'],
  webpack: () => [require.resolveWeak('views/transfers/TransfersViewWrapper')],
})
const AsyncTransferReportViewWrapper = RouterLoadable({
  loader: () => import('views/transfers/children/transfer-report-view/TransferReportViewWrapper'),
  modules: ['views/transfers/children/transfer-report-view/TransferReportViewWrapper'],
  webpack: () => [require.resolveWeak('views/transfers/children/transfer-report-view/TransferReportViewWrapper')],
})
const AsyncIssuanceViewWrapper = RouterLoadable({
  loader: () => import('views/issuance/IssuanceViewWrapper'),
  modules: ['views/issuance/IssuanceViewWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/IssuanceViewWrapper')],
})
const AsyncStockDetailsNewWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/stock-details-new/StockDetailsNewWrapper'),
  modules: ['views/issuance/children/stock-details-new/StockDetailsNewWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-details-new/StockDetailsNewWrapper')],
})
const AsyncStockAmendmentWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/stock-amendment/StockAmendment.wrapper'),
  modules: ['views/issuance/children/stock-amendment/StockAmendment.wrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-amendment/StockAmendment.wrapper')],
})
const AsyncStockAmendmentOverviewWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/stock-amendment-overview/StockAmendmentOverview.wrapper'),
  modules: ['views/issuance/children/stock-amendment-overview/StockAmendmentOverview.wrapper'],
  webpack: () => [
    require.resolveWeak('views/issuance/children/stock-amendment-overview/StockAmendmentOverview.wrapper'),
  ],
})
const AsyncDefineSeriesWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/define-series/DefineSeriesViewWrapper'),
  modules: ['views/issuance/children/define-series/DefineSeriesViewWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/define-series/DefineSeriesViewWrapper')],
})
const AsyncStockDetailsViewWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/stock-details/StockDetailsWrapper'),
  modules: ['views/issuance/children/stock-details/StockDetailsWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-details/StockDetailsWrapper')],
})
const AsyncVerificationViewWrapper = RouterLoadable({
  loader: () => import('views/verification/VerificationViewWrapper'),
  modules: ['views/verification/VerificationViewWrapper'],
  webpack: () => [require.resolveWeak('views/verification/VerificationViewWrapper')],
})
const AsyncSigningViewWrapper = RouterLoadable({
  loader: () => import('views/signing/SigningViewWrapper'),
  modules: ['views/signing/SigningViewWrapper'],
  webpack: () => [require.resolveWeak('views/signing/SigningViewWrapper')],
})
const AsyncIssueStockWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/issue-stock/IssueStockWrapper'),
  modules: ['views/issuance/children/issue-stock/IssueStockWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/issue-stock/IssueStockWrapper')],
})
const AsyncIssueStockProformaWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/issue-stock-proforma/IssueStockProforma.wrapper'),
  modules: ['views/issuance/children/issue-stock-proforma/IssueStockProforma.wrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/issue-stock-proforma/IssueStockProforma.wrapper')],
})
const AsyncIssueStockProformaHolderWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/issue-stock-proforma/children/holder/Holder.wrapper'),
  modules: ['views/issuance/children/issue-stock-proforma/children/holder/Holder.wrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/issue-stock-proforma/children/holder/Holder.wrapper')],
})
const AsyncIssueStockProformaConfirmWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/issue-stock-proforma/children/issue-confirm/IssueConfirm.wrapper'),
  modules: ['views/issuance/children/issue-stock-proforma/children/issue-confirm/IssueConfirm.wrapper'],
  webpack: () => [
    require.resolveWeak('views/issuance/children/issue-stock-proforma/children/issue-confirm/IssueConfirm.wrapper'),
  ],
})
const AsyncNewStockholderWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/new-stockholder/NewStockHolderWrapper'),
  modules: ['views/issuance/children/new-stockholder/NewStockHolderWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/new-stockholder/NewStockHolderWrapper')],
})
const AsyncPendingIssuanceWrapper = RouterLoadable({
  loader: () => import('views/issuance/children/pending-issuance/PendingIssuanceWrapper'),
  modules: ['views/issuance/children/pending-issuance/PendingIssuanceWrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/pending-issuance/PendingIssuanceWrapper')],
})
const AsyncSettingsViewWrapper = RouterLoadable({
  loader: () => import('views/settings/SettingsViewWrapper'),
  modules: ['views/settings/SettingsViewWrapper'],
  webpack: () => [require.resolveWeak('views/settings/SettingsViewWrapper')],
})
const AsyncHolderStocksViewWrapper = RouterLoadable({
  loader: () => import('views/holder-stocks/HolderStocksViewWrapper'),
  modules: ['views/holder-stocks/HolderStocksViewWrapper'],
  webpack: () => [require.resolveWeak('views/holder-stocks/HolderStocksViewWrapper')],
})
const AsyncHolderStockDetailsView = RouterLoadable({
  loader: () => import('views/holder-stocks/children/holder-stock-details/HolderStockDetails'),
  modules: ['views/holder-stocks/children/holder-stock-details/HolderStockDetails'],
  webpack: () => [require.resolveWeak('views/holder-stocks/children/holder-stock-details/HolderStockDetails')],
})
const AsyncStockRestrictionsView = RouterLoadable({
  loader: () => import('views/issuance/children/stock-restrictions/StockRestriction'),
  modules: ['views/issuance/children/stock-restrictions/StockRestriction'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-restrictions/StockRestriction')],
})
const AsyncProfilesViewWrapper = RouterLoadable({
  loader: () => import('views/profiles/ProfilesViewWrapper'),
  modules: ['views/profiles/ProfilesViewWrapper'],
  webpack: () => [require.resolveWeak('views/profiles/ProfilesViewWrapper')],
})
const AsyncStockAddView = RouterLoadable({
  loader: () => import('views/issuance/children/stock-add/StockAdd.wrapper'),
  modules: ['views/issuance/children/stock-add/StockAdd.wrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-add/StockAdd.wrapper')],
})
const AsyncStockAddNewView = RouterLoadable({
  loader: () => import('views/issuance/children/stock-add-new/StockAddNew.wrapper'),
  modules: ['views/issuance/children/stock-add-new/StockAddNew.wrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-add-new/StockAddNew.wrapper')],
})
const AsyncStockAddOverviewView = RouterLoadable({
  loader: () => import('views/issuance/children/stock-add-overview/StockAddOverview.wrapper'),
  modules: ['views/issuance/children/stock-add-overview/StockAddOverview.wrapper'],
  webpack: () => [require.resolveWeak('views/issuance/children/stock-add-overview/StockAddOverview.wrapper')],
})

export interface IStateProps {
  authenticated: boolean
  whoAmIFetched: boolean
  whoAmIFetching: boolean
  profileSelectFetched: boolean
  profileSelectFetching: boolean
  currentPath: string
  userRole: ProfileRole
  userLedgers: LedgerWithProfilesDto[]
  userDetails: WhoAmI$Response
  currentProfile: WhoAmI$Response$CurrentProfile
  shouldDisableMenu: boolean
  dashboardFetched: boolean
  dashboardFetching: boolean
  isOnboardingCompleted: boolean
  ledgerBasicDetailsFetched: boolean
  getUserProgress: GetDashboard$Response$Progress
  historicLedger: boolean
  historicImportCompleted: boolean
  stockList: StockListDto[]
  stockListLoaded: boolean
  hasActiveStockClass: boolean
  historicImportMode: boolean
  transfers: TransferListDto[]
  editableLedger: boolean
}

export interface IDispatchProps {
  whoAmI: Dispatchable0
  LogOut: Dispatchable0
  SelectUserProfile: Dispatchable1<SelectProfile$Request>
  GetStockList: Dispatchable0
}

type IRoutesProps = IStateProps & IDispatchProps & RouteComponentProps<any>

export interface IRoutesState {
  sideNavOpen: boolean
}

class Routes extends BaseStatefulComponent<IRoutesProps, IRoutesState> {
  constructor(props: IRoutesProps) {
    super(props)
    configLocale()
    this.state = {
      sideNavOpen: false,
    }
  }

  componentDidMount() {
    this.props.whoAmI()
    if (this.props.currentProfile) {
      this.props.GetStockList()
    }
    window.addEventListener('storage', this.storageEventHandler.bind(this))
  }

  storageEventHandler(event: any) {
    const { userDetails, whoAmIFetched, whoAmIFetching, currentProfile } = this.props

    if (event.key === USER_CHANGE) {
      // If user changes in another browser window/tab reload page
      const oldValueUserId: string = userDetails && userDetails.userId ? userDetails.userId : ''
      const oldValueProfileId: string = currentProfile && currentProfile.id ? currentProfile.id : ''
      const oldValue: string = oldValueUserId + oldValueProfileId
      const newValue: string = LS.getData<string>(USER_CHANGE)
      if (whoAmIFetched && !whoAmIFetching && oldValue !== newValue) {
        window.location.href = paths.dashboard.root
      }
    }
  }

  /**
   * Guard that checks for loggedIn status
   */
  get loggedInGuard(): IRouteGuard {
    const { whoAmIFetched, whoAmIFetching, authenticated } = this.props

    const guard: IRouteGuard = {
      failCondition: !authenticated,
      requestDone: whoAmIFetched && !whoAmIFetching,
      onFail: paths.login,
    }

    return guard
  }

  /**
   * Guard that checks for not Authenticated status
   */
  get notAuthenticated(): IRouteGuard {
    const { whoAmIFetched, whoAmIFetching, authenticated } = this.props

    const guard: IRouteGuard = {
      failCondition: authenticated,
      requestDone: whoAmIFetched && !whoAmIFetching,
      onFail: paths.dashboard.root,
    }

    return guard
  }

  /**
   * Guard that checks if user has profile selected
   * Checks if u have role in profile.
   */
  get profileSelected(): IRouteGuard {
    const { userRole, profileSelectFetched, profileSelectFetching, whoAmIFetched, whoAmIFetching } = this.props

    const guard: IRouteGuard = {
      failCondition: !userRole,
      requestDone: (profileSelectFetched && !profileSelectFetching) || (whoAmIFetched && !whoAmIFetching),
      onFail: paths.dashboard.root,
    }

    return guard
  }

  /**
   * Guard that checks if user has onboarding completed
   */
  get onBoardingCompleted(): IRouteGuard {
    const { isOnboardingCompleted, whoAmIFetched, whoAmIFetching, dashboardFetched, dashboardFetching } = this.props

    const guard: IRouteGuard = {
      failCondition: !isOnboardingCompleted,
      requestDone: whoAmIFetched && !whoAmIFetching && !dashboardFetching && dashboardFetched,
      onFail: paths.onboarding.root,
    }

    return guard
  }

  /**
   * Historic ledger import completed
   * */
  get historicLedgerCompleted(): IRouteGuard {
    const { whoAmIFetched, whoAmIFetching, historicImportCompleted } = this.props

    const guard: IRouteGuard = {
      failCondition: !historicImportCompleted,
      requestDone: whoAmIFetched && !whoAmIFetching,
      onFail: paths.dashboard.root,
    }

    return guard
  }

  toggleNavBar(show?: boolean) {
    if (show !== undefined) {
      this.setState(() => ({
        sideNavOpen: show,
      }))
    } else {
      this.setState((prevState: IRoutesState) => ({
        sideNavOpen: !prevState.sideNavOpen,
      }))
    }
  }

  getBackgroundConfig = (currentPath: string) => {
    let backgroundConfig: IBackgroundProps = { type: 'angle' }
    const configOptions: Array<{ paths: string[]; config: IBackgroundProps }> = [
      {
        paths: [paths.magiclink, paths.notification, paths.signing],
        config: {
          showGuardtime: false,
        },
      },
      {
        paths: [paths.recoverySms],
        config: {
          type: 'angle-long',
          showGuardtime: false,
        },
      },
      {
        paths: [paths.signing],
        config: {
          type: 'full',
          noHeader: true,
        },
      },
      {
        paths: [paths.settings],
        config: {
          type: 'white',
        },
      },
    ]
    configOptions.forEach(option => {
      if (option.paths.includes(currentPath)) {
        backgroundConfig = {
          ...backgroundConfig,
          ...option.config,
        }
      }
    })
    return backgroundConfig
  }

  render(): JSX.Element {
    const {
      whoAmIFetching,
      profileSelectFetching,
      dashboardFetched,
      ledgerBasicDetailsFetched,
      currentPath,
      userDetails,
      hasActiveStockClass,
      historicImportMode,
      shouldDisableMenu,
      userRole,
      userLedgers,
      isOnboardingCompleted,
      historicLedger,
      transfers,
      editableLedger,
      SelectUserProfile,
      LogOut,
    } = this.props

    let disabledItems: string[] = []

    if (!shouldDisableMenu && dashboardFetched && ledgerBasicDetailsFetched) {
      if (historicImportMode && !hasActiveStockClass) {
        disabledItems = [paths.votingList, paths.stock.root, paths.transfers.root, paths.holderList.root]
      } else if (historicImportMode && hasActiveStockClass) {
        disabledItems = [paths.votingList, paths.stock.root]
      }

      if (historicImportMode && editableLedger && !hasActiveStockClass) {
        disabledItems.push(paths.transfers.root)
      } else if (historicImportMode && editableLedger && hasActiveStockClass && !transfers?.length) {
        disabledItems.push(paths.holderList.root)
      }
    } else if (shouldDisableMenu) {
      disabledItems = [
        paths.dashboard.root,
        paths.stock.root,
        paths.votingList,
        paths.transfers.root,
        paths.settings,
        paths.holderList.root,
      ]
    }

    if (
      (userDetails && userDetails.userId && (!dashboardFetched || !ledgerBasicDetailsFetched)) ||
      whoAmIFetching ||
      profileSelectFetching
    ) {
      return (
        <Background {...this.getBackgroundConfig(currentPath)}>
          <GroupItems valign="center" align="center">
            <Loader className="mt-5 mb-5" />
          </GroupItems>
        </Background>
      )
    }

    return (
      <div>
        {currentPath !== paths.signing && (
          <HeaderWrapper
            toggleNavBar={show => this.toggleNavBar(show)}
            sideNavOpen={this.state.sideNavOpen}
            userLedgers={userLedgers}
            selectProfile={payload => SelectUserProfile(payload)}
            logOut={LogOut}
            disabledItems={disabledItems}
            isOnboardingCompleted={isOnboardingCompleted}
            globalNotification={
              historicImportMode &&
              isOnboardingCompleted &&
              userDetails &&
              userDetails.userId && {
                intent: GlobalNotificationIntent.SUCCESS,
                text: getI18n().t('globalNotifications:historic-import'),
                icon: null,
              }
            }
          />
        )}
        <Background showGuardtime {...this.getBackgroundConfig(currentPath)}>
          <Switch>
            <Route exact path={paths.notification} component={AsyncNotificationViewWrapper} />
            <PrivateRoute
              exact
              path={paths.signing}
              component={AsyncSigningViewWrapper}
              guards={[
                {
                  failCondition: false,
                  onFail: paths.dashboard.root,
                  requestDone: true,
                },
              ]}
            />
            <PrivateRoute
              exact
              path={paths.magiclink}
              component={AsyncMagicLinkViewWrapper}
              guards={[this.notAuthenticated]}
            />
            <PrivateRoute exact path={paths.login} component={AsyncLoginViewWrapper} guards={[this.notAuthenticated]} />
            <PrivateRoute
              exact
              path={paths.forgotPassword}
              component={AsyncForgotPasswordViewWrapper}
              guards={[this.notAuthenticated]}
            />
            <PrivateRoute
              exact
              path={paths.resetPassword}
              component={AsyncResetPasswordViewWrapper}
              guards={[this.notAuthenticated]}
            />
            <PrivateRoute
              exact
              path={paths.verification}
              component={AsyncVerificationViewWrapper}
              guards={[this.loggedInGuard]}
            />
            <PrivateRoute
              exact
              path={paths.onboarding.root}
              guards={[this.loggedInGuard]}
              component={AsyncOnboardingViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.dashboard.root}
              guards={[this.loggedInGuard, this.onBoardingCompleted]}
              component={AsyncDashboardViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.recoverySms}
              component={AsyncRecoverySmsLinkViewWrapper}
              guards={[this.notAuthenticated]}
            />
            <PrivateRoute
              exact
              path={paths.transfers.root}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncTransfersViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.new}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={historicImportMode ? AsyncNewTransfersHistoricViewWrapper : AsyncNewTransfersViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.cancel}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncCancelTransfersViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.cancelReport}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncCancelTransferReportViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.importReport}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncTransferReportHistoricViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.report}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncTransferReportViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.details}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncTransfersDetailsViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.transfers.editHistoric}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={historicImportMode ? AsyncNewTransfersHistoricViewWrapper : AsyncNewTransfersViewWrapper}
            />
            <PrivateRoute
              exact={true}
              path={paths.transfers.bulkImport}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncBulkImportViewWrapper}
            />
            <PrivateRoute
              exact={true}
              path={paths.transfers.bulkImportReview}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncBulkImportPreviewViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.root}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={
                RoleUtil.matchOne([ProfileRole.LEDGER_ADMIN], [userRole])
                  ? AsyncIssuanceViewWrapper
                  : AsyncHolderStocksViewWrapper
              }
            />
            <PrivateRoute
              exact
              path={paths.stock.details}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={
                RoleUtil.matchOne([ProfileRole.LEDGER_ADMIN], [userRole])
                  ? AsyncStockDetailsViewWrapper
                  : AsyncHolderStockDetailsView
              }
            />
            <PrivateRoute
              exact
              path={paths.stock.restrictions}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncStockRestrictionsView}
            />
            <PrivateRoute
              exact
              path={paths.stock.addDetails}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncStockDetailsNewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.amend}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncStockAmendmentWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.amendmentOverview}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncStockAmendmentOverviewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.defineSeries}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncDefineSeriesWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.issueStock}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncIssueStockWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.proformaIssuance.issue}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncIssueStockProformaWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.proformaIssuance.holder}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncIssueStockProformaHolderWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.proformaIssuance.confirm}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncIssueStockProformaConfirmWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.newStockHolder}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncNewStockholderWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.pendingIssuance}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncPendingIssuanceWrapper}
            />
            <PrivateRoute
              exact
              path={paths.holderList.root}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncHolderListViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.holderList.editStockHolder}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncEditStockHolderDetailsViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.holderList.viewStockHolder}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncViewStockHolderDetailsViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.votingList}
              guards={[
                this.loggedInGuard,
                this.profileSelected,
                this.onBoardingCompleted,
                ...(historicLedger ? [this.onBoardingCompleted] : []),
              ]}
              component={AsyncVotingListViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.settings}
              guards={[this.loggedInGuard, this.onBoardingCompleted]}
              component={AsyncSettingsViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.profiles}
              guards={[this.loggedInGuard, this.onBoardingCompleted]}
              component={AsyncProfilesViewWrapper}
            />
            <PrivateRoute
              exact
              path={paths.stock.addStock}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncStockAddView}
            />
            <PrivateRoute
              exact
              path={paths.stock.addStockNew}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncStockAddNewView}
            />
            <PrivateRoute
              exact
              path={paths.stock.addStockOverview}
              guards={[this.loggedInGuard, this.profileSelected, this.onBoardingCompleted]}
              component={AsyncStockAddOverviewView}
            />
            <Redirect to={paths.dashboard.root} />
          </Switch>
        </Background>
        {currentPath !== paths.signing && <FooterWrapper />}
      </div>
    )
  }
}

export default Routes
