import { all, put, call, takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import { addNotification, clearNotification } from '../store/notifications/NotificationActions'
import { Action } from 'redux-actions'
import { INotification } from '../store/notifications/NotificationReducer'
import { LogOut } from 'views/login/LoginViewActions'

export function* sagaLocationChange(action: Action<any>) {
  const notifications =
    action.payload.location && action.payload.location.state && action.payload.location.state.notifications
      ? action.payload.location.state.notifications
      : null
  try {
    if (action.payload.location.pathname === '/sign-out') {
      yield call(sagaHandleSignOutPageRequest)
    }

    yield put({ type: String(clearNotification) })

    if (notifications && notifications.length > 0) {
      yield all(
        notifications.map((notification: INotification) =>
          put({ type: String(addNotification), payload: notification }),
        ),
      )
    }
  } catch (e) {}
}

export function* sagaHandleSignOutPageRequest() {
  yield put(LogOut())
}

export default function* authSagas() {
  yield all([takeLatest(String(LOCATION_CHANGE), sagaLocationChange)])
}
