import api from 'services/Api.services'
import { DraftStorageEnum } from '../constants/draft-storage'

export default class DraftStorage {
  /**
   * Determines if given draft by the key is available between ledger admins or for current account only
   * @param {DraftStorageEnum} key - defines which kind of draft key to check
   */
  static isLedgerAdminDraft(key: DraftStorageEnum): boolean {
    return [DraftStorageEnum.HISTORIC_LEDGER_IMPORT].includes(key)
  }

  /**
   * @param {DraftStorageEnum} key - defines which kind of draft to fetch
   */
  static async get<T>(key: DraftStorageEnum): Promise<T> {
    const ledgerAdminDraft = DraftStorage.isLedgerAdminDraft(key)
    const response: any = await api.ledger.getDraft({
      key,
      type: key,
      ledgerAdminDraft,
    })

    if (response?.drafts.length) {
      const draft = JSON.parse(response.drafts[0].data) as unknown as T
      return draft
    }

    return null
  }

  /**
   * Saves draft data under given key
   * @param {DraftStorageEnum} key - defines which kind of draft to save
   * @param {string} data - data as serialized json string
   */
  static async save<T>(key: DraftStorageEnum, data: string): Promise<void> {
    const ledgerAdminDraft = DraftStorage.isLedgerAdminDraft(key)
    await api.ledger.saveDraft({
      key,
      type: key,
      data,
      ledgerAdminDraft,
    })
  }

  /**
   * Deletes stored draft data under given key
   * @param {DraftStorageEnum} key - defines which kind of draft to delete
   */
  static async clear(key: DraftStorageEnum): Promise<void> {
    const ledgerAdminDraft = DraftStorage.isLedgerAdminDraft(key)
    await api.ledger.deleteDraft({
      key,
      type: key,
      ledgerAdminDraft,
    })
  }
}
