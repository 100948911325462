import { IGlobalState } from '../../../rootReducer'

export const getStockListSelector = (state: IGlobalState) => {
  return state.stocks.stocks.sort((a, b) => (a.stockName > b.stockName ? 1 : b.stockName > a.stockName ? -1 : 0))
}

export const getUserStockListSelector = (state: IGlobalState) => {
  return state.stocks.usersStocks.sort((a, b) => (a.stockName > b.stockName ? 1 : b.stockName > a.stockName ? -1 : 0))
}

export const getStockListLoaded = (state: IGlobalState) => state.stocks.stockListLoaded

export const getStockListLoading = (state: IGlobalState) => state.stocks.stockListLoading

// get if Stock list is loading/loaded.
export const getStockListLoadingState = (state: IGlobalState) => {
  const loading = state.stocks.stockListLoading
  const loaded = state.stocks.stockListLoaded
  return { loaded, loading }
}

export const getHasActiveStockClass = (state: IGlobalState) => state.stocks.stocks.some(stock => stock.active)
