import { createAction } from 'redux-actions'

const ns = 'settings/user'

/* Get user data */
export const GetUserData = createAction(`${ns}GET_USER_DATA`)
export const GetUserDataSuccess = createAction(`${ns}GET_USER_DATA_SUCCESS`)
export const GetUserDataFail = createAction(`${ns}GET_USER_DATA_FAIL`)

/* Add user details */
export const SaveUserOnboardingDetails = createAction(`${ns}SAVE_USER_DETAILS`)

/* Update user details */
export const UpdateUserDetails = createAction(`${ns}UPDATE_USER_DETAILS`)

export const SetLoading = createAction(`${ns}SET_LOADING`)
export const SetLoaded = createAction(`${ns}SET_LOADED`)
