import { createAction } from 'redux-actions'

const ns = 'settings/stock/'

/* Get user data */
export const GetStockData = createAction(`${ns}GET_STOCK_DATA`)
export const GetStockDataSuccess = createAction(`${ns}GET_STOCK_DATA_SUCCESS`)
export const GetStockDataFail = createAction(`${ns}GET_STOCK_DATA_FAIL`)

/* Update user data */
export const SaveStockClass = createAction(`${ns}SAVE_STOCK_CLASS`)
export const ConfirmStockClass = createAction(`${ns}CONFIRM_STOCK_CLASS`)

/* Preface data */
export const GetStockFormPrefaceData = createAction(`${ns}GET_STOCK_FORM_PREFACE_DATA`)
export const GetStockFormPrefaceDataSuccess = createAction(`${ns}GET_STOCK_FORM_PREFACE_DATA_SUCCESS`)
export const GetStockFormPrefaceDataFailure = createAction(`${ns}GET_STOCK_FORM_PREFACE_DATA_FAILURE`)

/* */
export const GetDelawareStockAsInitial = createAction(`${ns}GET_DELAWARE_STOCK_AS_INITIAL`)
export const SaveDelawareStockAsInitial = createAction(`${ns}CHANGE_DELAWARE_STOCK_AS_INITIAL`)

export const SaveStockFormPrefaceData = createAction(`${ns}SAVE_STOCK_FORM_PREFACE_DATA`)
export const SaveStockFormPrefaceDataSuccess = createAction(`${ns}SAVE_STOCK_FORM_PREFACE_DATA_SUCCESS`)
export const SaveStockFormPrefaceDataFailure = createAction(`${ns}SAVE_STOCK_FORM_PREFACE_DATA_FAILURE`)
