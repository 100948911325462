import { createAction } from 'redux-actions'

const ns = 'stocks/'

/* Get Stock List */
export const GetStockList = createAction(`${ns}GET_STOCK_LIST`)
export const GetStockListSuccess = createAction(`${ns}GET_STOCK_LIST_SUCCESS`)
export const GetStockListFail = createAction(`${ns}GET_STOCK_LIST_FAIL`)

/* Get User Stock List */
export const GetUserStockList = createAction(`${ns}GET_USER_STOCK_LIST`)
export const GetUserStockListSuccess = createAction(`${ns}GET_USER_STOCK_LIST_SUCCESS`)
export const GetUserStockListFail = createAction(`${ns}GET_USER_STOCK_LIST_FAIL`)
