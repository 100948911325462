import * as React from 'react'
import { IGroupItemProps } from './GroupItem'
import { BaseComponent } from '../../BaseComponent'

import './GroupItems.scss'

export interface IGroupItemsProps {
  children: React.ReactElement<IGroupItemProps> | Array<React.ReactElement<IGroupItemProps>>
  /**
   * Custom class
   */
  className?: string
  /**
   * Align items inline
   */
  inline?: boolean
  /**
   * Align items inlineFlex
   */
  inlineFlex?: boolean
  /**
   * Justify content
   */
  align?: 'center' | 'space-between' | 'left' | 'right' | 'flow'
  /**
   * Align content
   */
  valign?: 'center' | 'baseline' | 'top' | 'bottom'
}

export default class GroupItems extends BaseComponent<IGroupItemsProps> {
  BEM(): string {
    const classArray = ['group-items']

    if (this.props.align) {
      classArray.push('group-items--' + this.props.align)
    }

    if (this.props.valign) {
      classArray.push('group-items--v-' + this.props.valign)
    }

    if (this.props.inline) {
      if (this.props.inlineFlex) {
        classArray.push('group-items--inline-flex')
      } else {
        classArray.push('group-items--inline')
      }
    } else {
      classArray.push('group-items--block')
    }

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  public render(): JSX.Element {
    return (
      <div className="group-items-container">
        <div className={this.BEM()}>{this.props.children}</div>
      </div>
    )
  }
}
