import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { History } from 'history'
import { getI18n } from 'react-i18next'
import { push } from 'connected-react-router'
import { Action } from 'redux-actions'
import * as _ from 'lodash'

import {
  AccountDto,
  CreateStockSeries$Request,
  CreateStockSeries$Response,
  UpdateStockClass$Request,
  UpdateStockClass$Response,
  UpdateStockSeries$Request,
  UpdateStockSeries$Response,
  CreateStockClass$Response,
  HolderType,
  CreateBulkIssuance$Response,
  CreateBulkIssuance$Request,
  CreateIssuance$Response,
  CreateIssuance$Request,
  NonCashType,
  CreateBulkIssuance$Request$CurrencyType,
} from 'services/api.types'
import {
  AddHolderToIssuance,
  ClearIssuanceDraft,
  ConfirmStockDetails,
  ConfirmSeriesDetails,
  CreateIssuance,
  CreateStockSeries,
  SaveHolderToIssuance,
  SaveIssuanceDraft,
  SetLoaded,
  SetLoading,
  UpdateStockClass,
  AmendStockDetails,
  ConfirmAmendStockClassDetails,
  ConfirmAmendStockSeriesDetails,
  InitCreateStockClass,
  ConfirmCreateStockClass,
  CreateStockClass,
  SaveProformaIssuanceDraft,
  GetProformaIssuanceDraft,
  ClearProformaIssuanceDraft,
  EditProformaIssuanceHolder,
  GetProformaIssuanceDraftFailure,
  GetProformaIssuanceDraftSuccess,
  SaveProformaIssuanceDraftSuccess,
  SaveProformaIssuanceDraftFailure,
  ClearProformaIssuanceDraftSuccess,
  ClearProformaIssuanceDraftFailure,
  CreateProformaIssuanceHolder,
  UpdateProformaIssuanceHolder,
  CreateProformaIssuanceHolderSuccess,
  CreateProformaIssuanceHolderFailure,
  UpdateProformaIssuanceHolderSuccess,
  UpdateProformaIssuanceHolderFailure,
  SubmitProformaIssuanceDraft,
  ConfirmProformaIssuanceDraft,
  ConfirmProformaIssuanceDraftSuccess,
  ConfirmProformaIssuanceDraftFailure,
  GetIssuanceDraftFailure,
  GetIssuanceDraftSuccess,
  GetIssuanceDraft,
  SaveIssuanceDraftSuccess,
  ClearIssuanceDraftSuccess,
  ClearIssuanceDraftFailure,
  SaveIssuanceDraftFailure,
} from './IssuanceViewActions'
import { paths } from 'common/router/routePaths'
import { AddDashboardNotification } from '../dashboard/DashboardViewActions'
import { getLoading, getProformaIssuanceDraft } from './IssuanceViewSelectors'
import { addNotification, clearNotification } from 'common/store/notifications/NotificationActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { GetStockList } from 'common/store/stocks/stocksAction'
import { Sign } from '../signing/SigningViewActions'
import api from 'services/Api.services'
import { NotificationType } from 'components/ui/notification/Notification'
import { INewStockholderRouterState, INewStockholderState } from './children/new-stockholder/NewStockholder'
import { IIssuanceDraft } from './IssuanceViewReducer'
import { IssueStockFieldNames } from './children/new-stockholder/NewStockholderFieldNames'
import { IStockDetails } from './children/stock-details-new/StockDetailsNew'
import { IStockAmendmentOverviewRouterState } from './children/stock-amendment-overview/StockAmendmentOverview'
import { IStockAddOverviewRouterState } from './children/stock-add-overview/StockAddOverview'
import { IStockAddNewRouterState } from './children/stock-add-new/StockAddNew'
import {
  IStockFormFields as IProformaStockFormFields,
  IFormFields as IProformaFormFields,
} from './children/issue-stock-proforma/IssueStockProforma.fieldNames'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import {
  ICreateProformaIssuanceHolderPayload,
  IHolderRouterState,
  IUpdateProformaIssuanceHolderPayload,
} from './children/issue-stock-proforma/children/holder/Holder'
import DraftStorage from 'common/utils/draftStorage'
import LocationState = History.LocationState
import { getIsHistoricImportMode } from 'common/store/common/commonSelector'

function* sagaUpdateStockClass(action: Action<UpdateStockClass$Request>) {
  try {
    yield put({ type: String(SetLoading) })
    const { signActionId }: UpdateStockClass$Response = yield call(api.ledger.updateStockClass, action.payload)
    // Set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.stock-class-updated', {
          ns: 'frontendNotifications',
        }),
      },
    }
    // Redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId,
        signedAction: successAction,
      },
    })
    window.scrollTo(0, 0)
    yield put({ type: String(GetStockList) })
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    const { code, translationKey } = e?.response?.data
    if (code && translationKey) {
      window.scrollTo(0, 0)
      const notification: INotification = {
        type: NotificationType.ERROR,
        code,
        translationKey,
        description: getI18n().t(`error.${translationKey}`, { ns: 'frontendNotifications' }),
      }
      yield put({ type: String(addNotification), payload: notification })
      yield put({ type: String(SetLoaded) })
    }
  }
}

function* sagaCreateIssuance(
  action: Action<{ issuance: CreateBulkIssuance$Request; meta: { stockName: string; stockClassIds: string[] } }>,
) {
  const isLoading: boolean = yield select(getLoading)

  if (isLoading) {
    return
  }

  yield put({ type: String(clearNotification) })

  try {
    yield put({ type: String(SetLoading) })
    // CreateBulkIssuance not yet ready for regular implementation, transform data first
    const regularIssuance: CreateIssuance$Request = {
      stockClassId: action.payload.issuance.accountStocks[0].stockClassId,
      accountStocks: action.payload.issuance.accountStocks.map(as => ({
        accountId: as.accountId,
        documents: as.documents,
        numberOfShares: as.numberOfShares,
        payment: {
          fullyPaid: {
            money: as.payment.amountPaid,
            nonCash: undefined,
          },
          partlyPaid: undefined,
        },
        stockRestriction: as.stockRestriction,
      })),
    }
    const response: CreateIssuance$Response = yield call(api.ledger.createIssuance, regularIssuance)
    const shareHolderCount = action.payload.issuance.accountStocks.length

    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.issuance', {
          stockName: action.payload.meta.stockName,
          shareHolderCount,
          ns: 'frontendNotifications',
          count: shareHolderCount,
        }),
      },
    }

    const cleanAction = { type: String(ClearIssuanceDraft), payload: action.payload.meta.stockClassIds }

    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedAction: successAction,
        cleanAction,
      },
    })

    window.scrollTo(0, 0)

    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    yield put({ type: String(SetLoaded) })

    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }

    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaCreateStockSeries(action: Action<{ seriesData: CreateStockSeries$Request; stateData: LocationState }>) {
  try {
    yield put({ type: String(SetLoading) })

    const response: CreateStockSeries$Response = yield call(api.ledger.createStockSeries, action.payload.seriesData)

    const cancelledUrl = {
      pathname: paths.stock.defineSeries,
      state: action.payload.stateData,
    }

    const historicImportMode: boolean = yield select(getIsHistoricImportMode)

    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        signedUrl: historicImportMode ? paths.dashboard.root : paths.stock.root,
        cancelledUrl,
        deniedUrl: cancelledUrl,
      },
    })
    window.scrollTo(0, 0)
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    yield put({ type: String(SetLoaded) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaSaveIssuanceDraft(action: Action<IIssuanceDraft>): any {
  try {
    let data: IIssuanceDraft[]
    const stockId = action.payload.stockDetails.id
    const issuances: IIssuanceDraft[] = yield call(DraftStorage.get, DraftStorageEnum.ISSUANCES) || []
    const draftId = `${DraftStorageEnum.ISSUANCE_KEY_BASE}${stockId}`
    const draftExistsInStorage = !!issuances?.find(i => i.id === draftId)

    if (!issuances?.length || !Array.isArray(issuances)) {
      data = [{ ...action.payload, dateCreated: new Date().getTime() }]
    } else if (draftExistsInStorage) {
      if (action.payload.addedAccounts.length > 0) {
        data = issuances.map(i => {
          if (i.id === draftId) {
            return action.payload
          }
          return i
        })
      } else {
        data = issuances.filter(i => i.id !== action.payload.id)
      }
    } else {
      data = _.clone(issuances)
      data.push({ ...action.payload, dateCreated: new Date().getTime() })
    }

    data = data.filter(i => !!i.id)

    yield call(DraftStorage.save, DraftStorageEnum.ISSUANCES, JSON.stringify(data))
    yield put({ type: String(SaveIssuanceDraftSuccess), payload: action.payload })
  } catch (e: any) {
    yield put({ type: String(SaveIssuanceDraftFailure) })
    console.log('sagaSaveIssuanceDraft failed', e)
  }
}

function* sagaClearIssuanceDraft(action: Action<string[]>): any {
  try {
    let data: IIssuanceDraft[]
    const draftIds = action.payload.map(stockId => `${DraftStorageEnum.ISSUANCE_KEY_BASE}${stockId}`)
    const issuances: IIssuanceDraft[] = yield call(DraftStorage.get, DraftStorageEnum.ISSUANCES) || []

    data = issuances.filter(i => !draftIds.includes(i.id) && !!i.id)

    yield call(DraftStorage.save, DraftStorageEnum.ISSUANCES, JSON.stringify(data))
    yield put({ type: String(ClearIssuanceDraftSuccess), payload: action.payload })
  } catch (e: any) {
    yield put({ type: String(ClearIssuanceDraftFailure) })
    console.log('sagaClearIssuanceDraft failed', e)
  }
}

function* sagaAddHolderToIssuance(action: Action<INewStockholderRouterState>) {
  yield put(
    push({
      pathname: paths.stock.newStockHolder,
      state: action.payload,
    }),
  )
}

export interface ISaveHolderToIssuancePayload {
  accountStockDetails: IssueStockFieldNames
  issuanceDraft: IIssuanceDraft
  account: AccountDto
}

function* sagaSaveHolderToIssuance(action: Action<ISaveHolderToIssuancePayload>) {
  const { accountStockDetails, account, issuanceDraft } = action.payload
  const addedAccounts = issuanceDraft.addedAccounts
    ? issuanceDraft.addedAccounts.filter(holder => holder.account.id !== account.id)
    : []

  const newIssuanceDraft: IIssuanceDraft = {
    ...issuanceDraft,
    addedAccounts: [...addedAccounts, { ...accountStockDetails, account }],
  }

  yield put({ type: String(SaveIssuanceDraft), payload: newIssuanceDraft })

  // Redirect to IssueStock page
  yield put(
    push({
      pathname: paths.stock.issueStock,
      state: {
        stock: issuanceDraft.stockDetails,
      },
    }),
  )
}

function* sagaConfirmStockDetails(action: Action<IStockDetails>) {
  // Redirect to StockDetails page
  yield put(
    push({
      pathname: paths.stock.details,
      state: {
        details: action.payload,
        backUrl: {
          pathname: paths.stock.addDetails,
          state: { details: action.payload },
        },
        isConfirmation: true,
      },
    }),
  )
}

function* sagaConfirmSeriesDetails(action: Action<IStockDetails>) {
  // Redirect to StockDetails page
  yield put(
    push({
      pathname: paths.stock.details,
      state: {
        details: action.payload,
        backUrl: {
          pathname: paths.stock.addDetails,
          state: { details: action.payload },
        },
        isConfirmation: true,
      },
    }),
  )
}

function* sagaAmendStockDetails(action: Action<IStockAmendmentOverviewRouterState>) {
  // Redirect to amendment overview page
  yield put(
    push({
      pathname: paths.stock.amendmentOverview,
      state: action.payload,
    }),
  )
}

function* sagaConfirmAmendStockClassDetails(action: Action<UpdateStockClass$Request>) {
  try {
    yield put({ type: String(SetLoading) })
    const { signActionId }: UpdateStockClass$Response = yield call(api.ledger.updateStockClass, action.payload)

    // Set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.stock-amendment', {
          ns: 'frontendNotifications',
        }),
      },
    }

    // Redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId,
        signedAction: successAction,
      },
    })
    window.scrollTo(0, 0)
    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    const { code, translationKey } = e?.response?.data
    if (code && translationKey) {
      window.scrollTo(0, 0)
      const notification: INotification = {
        type: NotificationType.ERROR,
        code,
        translationKey,
        description: getI18n().t(`error.${translationKey}`, { ns: 'frontendNotifications' }),
      }
      yield put({ type: String(addNotification), payload: notification })
      yield put({ type: String(SetLoaded) })
    }
  }
}

function* sagaConfirmAmendStockSeriesDetails(action: Action<UpdateStockSeries$Request>) {
  try {
    yield put({ type: String(SetLoading) })
    const { signActionId }: UpdateStockSeries$Response = yield call(api.ledger.updateStockSeries, action.payload)

    // Set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.stock-amendment', {
          ns: 'frontendNotifications',
        }),
      },
    }

    // Redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId,
        signedAction: successAction,
      },
    })
    window.scrollTo(0, 0)

    yield put({ type: String(SetLoaded) })
  } catch (e: any) {
    const { code, translationKey } = e?.response?.data
    if (code && translationKey) {
      window.scrollTo(0, 0)
      const notification: INotification = {
        type: NotificationType.ERROR,
        code,
        translationKey,
        description: getI18n().t(`error.${translationKey}`, { ns: 'frontendNotifications' }),
      }
      yield put({ type: String(addNotification), payload: notification })
      yield put({ type: String(SetLoaded) })
    }
  }
}

/**
 * Stock create view - step 1 submission
 */
function* sagaInitCreateStockClass(action: Action<IStockAddNewRouterState>) {
  yield put(
    push({
      pathname: paths.stock.addStockNew,
      state: action.payload,
    }),
  )
}
/**
 * Stock create view - step 2 submission
 */
function* sagaConfirmCreateStockClass(action: Action<IStockAddOverviewRouterState>) {
  yield put(
    push({
      pathname: paths.stock.addStockOverview,
      state: action.payload,
    }),
  )
}
/**
 * Stock create view - step 3 submission
 */
function* sagaCreateStockClass(action: Action<any>) {
  try {
    yield put({ type: String(SetLoading) })
    const { signActionId }: CreateStockClass$Response = yield call(api.ledger.createStockClass, action.payload)

    // Set dashboard success message
    const successAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.stock-class-added', {
          ns: 'frontendNotifications',
        }),
      },
    }

    // Redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId,
        signedAction: successAction,
      },
    })
    window.scrollTo(0, 0)

    yield put({ type: String(SetLoaded) })
  } catch (err) {
    yield put({ type: String(SetLoaded) })
  }
}

// Navigates to selected stockholder's details form page
function* sagaEditProformaIssuanceHolder(action: Action<IHolderRouterState>) {
  yield put(
    push({
      pathname: paths.stock.proformaIssuance.holder,
      state: action.payload,
    }),
  )
}

function* sagaGetProformaIssuanceDraft(action: Action<any>) {
  try {
    const draft: IProformaFormFields = yield call(DraftStorage.get, DraftStorageEnum.PROFORMA_ISSUANCE)
    yield put(GetProformaIssuanceDraftSuccess(draft))
  } catch (e: any) {
    yield put(GetProformaIssuanceDraftFailure())
  }
}

function* sagaSaveProformaIssuanceDraft(action: Action<IProformaFormFields>) {
  try {
    const data = JSON.stringify(action.payload)
    yield call(DraftStorage.save, DraftStorageEnum.PROFORMA_ISSUANCE, data)
    yield put(SaveProformaIssuanceDraftSuccess())
  } catch (e: any) {
    yield put(SaveProformaIssuanceDraftFailure())
  }
}

function* sagaClearProformaIssuanceDraft(action: Action<any>) {
  try {
    yield call(DraftStorage.clear, DraftStorageEnum.PROFORMA_ISSUANCE)
    yield put(GetProformaIssuanceDraft())
    yield put(ClearProformaIssuanceDraftSuccess())
  } catch (e: any) {
    yield put(ClearProformaIssuanceDraftFailure())
  }
}

function* sagaCreateProformaIssuanceHolder(action: Action<ICreateProformaIssuanceHolderPayload>) {
  try {
    const { payload } = action
    const details = payload.type === HolderType.LEGAL_ENTITY ? payload.legalEntity : payload.naturalPerson

    yield put(
      CreateProformaIssuanceHolderSuccess({
        holderTempId: payload.holderTempId,
        type: payload.type,
        holderId: details.holderId,
        accountId: details.accountId,
        details,
      }),
    )
    const draft: IProformaFormFields = yield select(getProformaIssuanceDraft)
    yield put(SaveProformaIssuanceDraft(draft))
    yield put(
      push({
        pathname: paths.stock.proformaIssuance.issue,
        state: {
          activeStockId: payload.stockId,
        },
      }),
    )
  } catch (e: any) {
    yield put(CreateProformaIssuanceHolderFailure())
  }
  return
}

function* sagaUpdateProformaIssuanceHolder(action: Action<IUpdateProformaIssuanceHolderPayload>) {
  try {
    const { payload } = action
    const details = payload.type === HolderType.LEGAL_ENTITY ? payload.legalEntity : payload.naturalPerson

    yield put(
      UpdateProformaIssuanceHolderSuccess({
        holderTempId: payload.holderTempId,
        type: payload.type,
        holderId: details.holderId,
        details,
      }),
    )
    const draft: IProformaStockFormFields = yield select(getProformaIssuanceDraft)
    yield put(SaveProformaIssuanceDraft(draft))
    yield put(
      push({
        pathname: paths.stock.proformaIssuance.issue,
        state: {
          activeStockId: payload.stockId,
        },
      }),
    )
  } catch (e: any) {
    yield put(UpdateProformaIssuanceHolderFailure())
  }
}

function* sagaSubmitProformaIssuanceDraft(action: Action<IProformaFormFields>) {
  yield put(SaveProformaIssuanceDraft(action.payload))
  yield put(
    push({
      pathname: paths.stock.proformaIssuance.confirm,
      state: {
        backUrl: paths.stock.proformaIssuance.issue,
      },
    }),
  )
}

function* sagaConfirmProformaIssuanceDraft(action: Action<IProformaFormFields>) {
  try {
    const { payload } = action
    const data: CreateBulkIssuance$Request = {
      accountStocks: [],
    }

    for (const stock of payload.data) {
      for (const holder of stock.holders) {
        const holderDetails =
          holder.type === HolderType.LEGAL_ENTITY ? holder.addedLegalHolder : holder.addedNaturalHolder
        data.accountStocks.push({
          accountId: holderDetails.accountId,
          documents: [],
          numberOfShares: holder.numberOfShares,
          payment: {
            amountPaid: holder.amountPaid,
            currencyType: CreateBulkIssuance$Request$CurrencyType.FIAT_MONEY,
            cryptoCurrency: null,
          },
          stockClassId: stock.stock.id,
          stockRestriction: null,
        })
      }
    }

    const { signActionId }: CreateBulkIssuance$Response = yield call(api.ledger.createBulkIssuance, data)

    // Set dashboard success message
    const signedAction = {
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t('success.issuance_plural_stocks', {
          ns: 'frontendNotifications',
          shareHolderCount: data.accountStocks.length,
        }),
      },
    }
    // Set Clean action
    const cleanAction = { type: String(ClearProformaIssuanceDraft) }
    // Redirect to signing
    yield put({
      type: String(Sign),
      payload: {
        signActionId,
        signedAction,
        cleanAction,
      },
    })

    yield put(ConfirmProformaIssuanceDraftSuccess())
  } catch (e: any) {
    yield put(ConfirmProformaIssuanceDraftFailure())
  }
}

// payload: stockId
function* sagaGetIssuanceDraft(action: Action<string>): any {
  try {
    const draftKey = `${DraftStorageEnum.ISSUANCE_KEY_BASE}${action.payload}`
    const drafts: IIssuanceDraft[] = yield call(DraftStorage.get, DraftStorageEnum.ISSUANCES) || []
    let draft = drafts.find(d => d.id === draftKey)

    if (!draft) {
      draft = { id: draftKey, stockDetails: null, addedAccounts: [], dateCreated: new Date().getTime() }
    }

    yield put(GetIssuanceDraftSuccess(draft))
  } catch (e: any) {
    yield put(GetIssuanceDraftFailure())
  }
}

export default function* issuanceViewSagas() {
  yield all([
    takeEvery(String(UpdateStockClass), sagaUpdateStockClass),
    takeEvery(String(CreateIssuance), sagaCreateIssuance),
    takeEvery(String(CreateStockSeries), sagaCreateStockSeries),
    takeEvery(String(SaveIssuanceDraft), sagaSaveIssuanceDraft),
    takeEvery(String(ClearIssuanceDraft), sagaClearIssuanceDraft),
    takeEvery(String(AddHolderToIssuance), sagaAddHolderToIssuance),
    takeEvery(String(SaveHolderToIssuance), sagaSaveHolderToIssuance),
    takeEvery(String(ConfirmStockDetails), sagaConfirmStockDetails),
    takeEvery(String(ConfirmSeriesDetails), sagaConfirmSeriesDetails),
    takeEvery(String(AmendStockDetails), sagaAmendStockDetails),
    takeEvery(String(ConfirmAmendStockClassDetails), sagaConfirmAmendStockClassDetails),
    takeEvery(String(ConfirmAmendStockSeriesDetails), sagaConfirmAmendStockSeriesDetails),
    takeEvery(String(ConfirmCreateStockClass), sagaConfirmCreateStockClass),
    takeEvery(String(InitCreateStockClass), sagaInitCreateStockClass),
    takeEvery(String(CreateStockClass), sagaCreateStockClass),
    takeEvery(String(GetProformaIssuanceDraft), sagaGetProformaIssuanceDraft),
    takeEvery(String(ClearProformaIssuanceDraft), sagaClearProformaIssuanceDraft),
    takeEvery(String(SaveProformaIssuanceDraft), sagaSaveProformaIssuanceDraft),
    takeEvery(String(SubmitProformaIssuanceDraft), sagaSubmitProformaIssuanceDraft),
    takeEvery(String(ConfirmProformaIssuanceDraft), sagaConfirmProformaIssuanceDraft),
    takeEvery(String(EditProformaIssuanceHolder), sagaEditProformaIssuanceHolder),
    takeEvery(String(CreateProformaIssuanceHolder), sagaCreateProformaIssuanceHolder),
    takeEvery(String(UpdateProformaIssuanceHolder), sagaUpdateProformaIssuanceHolder),
    takeEvery(String(GetIssuanceDraft), sagaGetIssuanceDraft),
  ])
}
