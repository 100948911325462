import { INaturalHolderFields, ILegalHolderFields } from 'components/functional/new-holder/NewHolderFieldNames'
import { UpdateNaturalPersonHolderDetails$Request, UpdateLegalEntityHolderDetails$Request } from 'services/api.types'

export function getUpdateNaturalPersonHolderDetailsRequestData(
  payload: INaturalHolderFields,
): UpdateNaturalPersonHolderDetails$Request {
  const { address, email, givenNames, lastName, ssn, holderId, middleName, nameSuffix } = payload

  return {
    address: { ...address, notEmpty: false },
    apartment: undefined,
    streetAddress: undefined,
    email,
    givenNames,
    middleName,
    lastName,
    nameSuffix,
    ssn,
    holderId,
  }
}

export function getUpdateLegalEntityHolderDetailsRequestData(
  payload: ILegalHolderFields,
): UpdateLegalEntityHolderDetails$Request {
  const { address, email, name, tin, holderId } = payload

  return {
    address: { ...address, notEmpty: false },
    apartment: undefined,
    streetAddress: undefined,
    email,
    name,
    tin,
    holderId,
  }
}
