import * as React from 'react'
import Icon, { ICONS } from '../../../icon/Icon'
import { BaseComponent } from 'components/BaseComponent'
import { WhoAmI$Response$CurrentProfile } from 'services/api.types'

import './User.scss'

export interface IUserProps {
  /**
   * User name
   */
  user?: string
  /**
   * Active company
   */
  company?: string
  /**
   * Toggle navBar callback handler
   */
  toggleNavBar: () => void
  /**
   * Active profile
   */
  profile?: WhoAmI$Response$CurrentProfile
  /**
   * Close function for mobile navigator modal
   */
  closeMobileNav: () => void
}

class User extends BaseComponent<IUserProps> {
  constructor(props: IUserProps) {
    super(props)

    this.getOnClick = this.getOnClick.bind(this)
  }

  iconBEM(): string {
    const classArray = ['user__icon']

    if (!this.props.profile) {
      // classArray.push('user__icon--disabled');
    }
    return classArray.join(' ')
  }

  getOnClick() {
    this.props.closeMobileNav()
    this.props.toggleNavBar()
  }

  public render(): JSX.Element {
    const { user, company } = this.props

    return (
      <div className="user" onClick={this.getOnClick}>
        <div className="user__info">
          <p className="user__company">{company}</p>
          <div className="user__name">{user}</div>
        </div>
        <div className="user__icon-wrapper">
          <Icon name={ICONS.GEAR} className={this.iconBEM()} />
        </div>
      </div>
    )
  }
}

export default User
