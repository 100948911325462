import {
  AccountDto,
  AddressDto,
  GetAccountStockList$Response,
  GetTransferDetails$Response,
  HolderDto,
  HolderType,
  StockListDto,
  StockListDto$StockClassTotalsDto,
  TransferListDto,
  TransferStatusType,
  UploadedDocumentDto,
} from 'services/api.types'
import { IHistoricLedgerImportDraft } from 'views/transfers/TransfersViewReducer'

export interface IHistoricTransferListDto extends TransferListDto {
  fromAccount: string
  toAccount: string
  stockClass: string
  documents: UploadedDocumentDto[]
}

// Returns total number of shares for given holder with given stock class id
export function getHolderSharesFromHistoricDraft(
  stockClassId: string,
  accountId: string,
  draft: IHistoricLedgerImportDraft,
  time?: Date,
): number {
  const result = draft.transfers.reduce((total, t) => {
    if (t.stockClassId.value === stockClassId && t.to.value === accountId) {
      return total + Number(t.numberOfShares)
    } else if (t.stockClassId.value === stockClassId && t.from.value === accountId) {
      return total - Number(t.numberOfShares)
    }
    return total
  }, 0)
  return result
}

export function getHolderSharesDateFromOriginalIssuance(
  stockClassId: string,
  accountId: string,
  draft: IHistoricLedgerImportDraft,
): string {
  const orderedByDateTransfers = draft?.transfers
    ?.filter(t => t.stockClassId.value === stockClassId)
    .sort((a, b) => new Date(a.dateOfTransfer).getTime() - new Date(b.dateOfTransfer).getTime())

  const result = orderedByDateTransfers?.filter(t => t.to.value === accountId)

  if (result.length > 0) {
    return result[0].dateOfTransfer
  } else {
    return undefined
  }
}

// Historic draft needs to calculate stock totals on FE
export function getStockTotalsFromHistoricDraft(
  stockClassId: string,
  stockList: StockListDto[],
  draft: IHistoricLedgerImportDraft,
): StockListDto$StockClassTotalsDto {
  const stock = stockList.find(s => s.id === stockClassId)
  const defaults: StockListDto$StockClassTotalsDto = {
    authorized: 0,
    issued: 0,
    outstanding: 0,
    treasury: 0,
    unissued: 0,
  }

  if (!stock) {
    return defaults
  }

  const issued = draft.transfers.reduce(
    (total, t) => (t.issuance && t.stockClassId.value === stockClassId ? total + Number(t.numberOfShares) : total),
    0,
  )

  return {
    ...stock.totals,
    issued,
    treasury: stock.totals.treasury,
    unissued: stock.totals.authorized - issued,
  }
}

export function searchAccountListFromHistoricDraft(
  search: string,
  draft: IHistoricLedgerImportDraft,
  onlyWithStock?: boolean,
  stockList?: StockListDto[],
  time?: Date,
): AccountDto[] {
  const result: AccountDto[] = draft.holders
    .filter(h => {
      if (onlyWithStock) {
        const accountStocks = getAccountStockListFromHistoricDraft(h?.data?.accountId, draft, stockList)
        return !!accountStocks.account
      }

      return h.data?.email.includes(search) || h.data?.calculatedName.includes(search)
    })
    .map(h => {
      const holder: HolderDto = {
        address: h.data.address as AddressDto,
        confirmed: false,
        email: h.data.email,
        emailSentAt: null,
        id: h.data.holderId,
        lastName: '',
        ledgerId: null,
        middleName: '',
        name: '',
        nameSuffix: '',
        phones: [],
        registrationNumber: null,
        ssn: null,
        type: h.type,
        userId: null,
      }

      return {
        calculatedName: h.data.name || h.data.calculatedName, // TODO: check holder data create & update differences. One is initial name, one is updated currently. (need better separation for clarification)
        name: h.type === HolderType.LEGAL_ENTITY ? h.data.name : h.data.calculatedName,
        draftAccount: false,
        holders: [holder],
        id: h.data.accountId,
        ledgerRootAccount: false,
        temporaryAccount: false,
      } as AccountDto
    })

  return result
}

export function getAccountStockListFromHistoricDraft(
  accountId: string,
  draft: IHistoricLedgerImportDraft,
  stockList: StockListDto[],
): GetAccountStockList$Response {
  const stockIds: string[] = []
  const account = draft.holders.find(h => h.data?.accountId === accountId)
  const response: GetAccountStockList$Response = {
    account: null,
  }

  if (!account?.data) {
    return response
  }

  response.account = {
    id: account.data.accountId,
    name: account.data.name,
    stocks: [],
  }

  draft.transfers.forEach(t => {
    if (t.to.value === accountId && !stockIds.includes(t.stockClassId.value)) {
      stockIds.push(t.stockClassId.value)
    }
  })

  let hasUsableShares = false
  response.account.stocks = stockIds
    .map(classId => {
      let stock = stockList.find(s => s.id === classId)

      // Check special stock series also
      if (!stock) {
        stockList.forEach(s => {
          const seriesMatch = s.series?.find(ss => ss.id === classId)
          if (seriesMatch) {
            stock = { ...s, ...seriesMatch }
          }
        })
      }

      if (!stock) {
        return null
      }

      const usableNumberOfShares = getHolderSharesFromHistoricDraft(classId, account.data.accountId, draft)
      const firstTransferDateFromIssuance = getHolderSharesDateFromOriginalIssuance(
        classId,
        account.data.accountId,
        draft,
      )

      if (usableNumberOfShares) {
        hasUsableShares = true
      }

      return {
        classId,
        parValue: stock.parValue,
        pendingNumberOfShares: 0,
        restrictions: stock.restrictions,
        stockName: stock.stockName,
        totalNumberOfShares: stock.totalNumberOfShares,
        type: stock.type,
        usableNumberOfShares,
        firstTransferDateFromIssuance,
        voting: stock.voting,
        votingRights: stock.votingRights,
      }
    })
    .filter(s => s !== null)

  if (!hasUsableShares) {
    return { account: null }
  }

  return response
}

export function getTransferDetailsFromHistoricDraft(
  transferId: string,
  draft: IHistoricLedgerImportDraft,
): GetTransferDetails$Response {
  const transfer = draft.transfers.find(t => t.id === transferId)
  const fromHolder = draft.holders.find(h => h.data?.accountId === transfer?.from.value)
  const toHolder = draft.holders.find(h => h.data?.accountId === transfer?.to.value)

  if (!transfer || !toHolder) {
    return null
  }

  const details: GetTransferDetails$Response = {
    adminTransfer: true,
    amountPaid: transfer.amountPaid,
    canAccept: false,
    canCancel: false,
    canDecline: false,
    canEdit: false,
    cancellation: null,
    createdAt: new Date(transfer.dateOfTransfer).getTime(),
    createdBy: null,
    createdByAdmin: {
      emails: [],
      name: 'Original Issuance',
    },
    declinedAt: null,
    declinedBy: null,
    declinedByAdmin: null,
    documents: transfer.documents,
    from: {
      emails: [fromHolder?.data.email],
      name: fromHolder?.data.name,
      onboarded: false,
      type: fromHolder?.type,
    },
    fromAccountName: fromHolder?.data?.name,
    certificatedTransfer: transfer.isCertificated ? transfer.certificatedTransfer : null,
    historicImport: true,
    informativeRow: false,
    issuance: transfer.issuance,
    numberOfShares: transfer.numberOfShares,
    pendingTransfer: true,
    pendingTransferAttributes: null,
    restrictions: '',
    status: TransferStatusType.PENDING,
    stockClass: { hasRestrictions: false, stockClassName: transfer.stockClassId.label },
    stockClassName: transfer.stockClassId.label,
    to: {
      emails: [toHolder.data.email],
      name: toHolder.data.name,
      onboarded: false,
      type: toHolder.type,
    },
    toAccountName: toHolder.data.name,
  }

  return details
}
