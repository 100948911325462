import { IGlobalState } from '../../rootReducer'

export interface IRecoverySmsViewUrls {
  successUrl: string
  errorUrl: string
}

export const getUrls = (state: IGlobalState): IRecoverySmsViewUrls => {
  return {
    successUrl: state.recoverySmsView.successUrl,
    errorUrl: state.recoverySmsView.errorUrl,
  }
}

export const getSignActionId = (state: IGlobalState) => state.recoverySmsView.signActionId
