import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import api from 'services/Api.services'
import { Action } from 'redux-actions'
import { push } from 'connected-react-router'
import { paths } from 'common/router/routePaths'
import { addNotification, clearNotification } from 'common/store/notifications/NotificationActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { ResetPassword, StartResetPassword } from './ForgotPasswordViewActions'
import { IForgotPasswordFormFields } from './children/ForgotPasswordFieldNames'
import { ResetPassword$Request, ResetPassword$Response } from 'services/api.types'
import { Sign } from '../signing/SigningViewActions'

function* sagaStartResetPassword({ payload }: Action<IForgotPasswordFormFields>) {
  const { value, dateOfBirth } = payload
  const startResetPasswordRequest = { value, dateOfBirth }

  // Clear notifications
  yield put({ type: String(clearNotification) })

  try {
    yield call(api.auth.startResetPassword, startResetPasswordRequest)
    const notifications: INotification[] = [
      {
        type: NotificationType.SUCCESS,
        description: getI18n().t('success.password-recovery', {
          ns: 'frontendNotifications',
        }),
      },
    ]
    yield put(push(paths.login, { notifications }))
  } catch (e) {
    const response = e.response.data
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaResetPassword(action: Action<ResetPassword$Request>) {
  try {
    const response: ResetPassword$Response = yield call(api.auth.resetPassword, action.payload)
    const successAction = {
      type: String(addNotification),
      payload: {
        description: getI18n().t('success.password-reset', {
          ns: 'frontendNotifications',
        }),
        type: NotificationType.SUCCESS,
      },
    }
    const failAction = {
      type: String(addNotification),
      payload: {
        description: getI18n().t('error.password-reset', {
          ns: 'frontendNotifications',
        }),
        type: NotificationType.ERROR,
      },
    }
    const cancelAction = {
      type: String(addNotification),
      payload: {
        description: getI18n().t('error.password-reset-canceled', {
          ns: 'frontendNotifications',
        }),
        type: NotificationType.ERROR,
      },
    }
    if (response.signActionId) {
      yield put({
        type: String(Sign),
        payload: {
          signActionId: response.signActionId,
          signedAction: successAction,
          signedUrl: paths.login,
          expiredAction: failAction,
          deniedAction: failAction,
          cancelledAction: cancelAction,
          expiredUrl: paths.forgotPassword,
          deniedUrl: paths.forgotPassword,
          cancelledUrl: paths.forgotPassword,
          title: getI18n().t('password-reset.title', {
            ns: 'signingView',
          }),
          successTitle: getI18n().t('password-reset.successTitle', {
            ns: 'signingView',
          }),
        },
      })
      yield put(push(paths.login, {}))
    } else {
      const notifications: INotification[] = [
        {
          type: NotificationType.SUCCESS,
          description: getI18n().t('success.password-reset', {
            ns: 'frontendNotifications',
          }),
        },
      ]
      yield put(push(paths.login, { notifications }))
    }
  } catch (e) {
    const response = e.response.data
    let notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    if (response.code === 'INVALID_STATE' && response.description === 'action expired') {
      notification = {
        description: getI18n().t('error.password-reset-expired', {
          ns: 'frontendNotifications',
        }),
        type: NotificationType.ERROR,
      }
    }

    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* forgotPasswordViewSagas() {
  yield all([
    takeEvery(String(StartResetPassword), sagaStartResetPassword),
    takeEvery(String(ResetPassword), sagaResetPassword),
  ])
}
