import * as React from 'react'
import { Link } from 'react-router-dom'
import { BaseStatefulComponent } from '../../BaseStatefulComponent'
import Icon, { ICONS } from '../icon/Icon'
import Container from '../container/Container'

import { LedgerWithProfilesDto, ProfileRole, SelectProfile$Request, WhoAmI$Response } from 'services/api.types'
import { paths } from 'common/router/routePaths'
import RoleUtil from 'common/utils/roleUtil'
import { getFullName } from 'common/utils/nameUtil'
import Navigation, { INavigationLink } from './children/navigation/Navigation'
import User from './children/user/User'

import './Header.scss'
import SideNav from 'components/functional/side-nav/SideNav'
import GlobalNotification, {
  GlobalNotificationIntent,
  IGlobalNotificationProps,
} from 'components/ui/global-notification/GlobalNotification'

export interface IStateProps {
  userDetails: WhoAmI$Response
  isLoggedIn: boolean
  links: INavigationLink[]
  toggleNavBar: (show?: boolean) => void
  sideNavOpen: boolean
  userLedgers: LedgerWithProfilesDto[]
  selectProfile: (payload: SelectProfile$Request) => void
  logOut: () => void
  disabledItems: string[]
  isOnboardingCompleted: boolean
  globalNotification?: IGlobalNotificationProps
}

export interface IDispatchProps {}

export type IHeaderProps = IStateProps & IDispatchProps

export interface IHeaderState {
  mobileNavVisible: boolean
}

class Header extends BaseStatefulComponent<IHeaderProps, IHeaderState> {
  private mobileNavToggleRef = React.createRef<HTMLInputElement>()

  get companyName() {
    const { currentProfile } = this.props.userDetails
    if (currentProfile) {
      return RoleUtil.matchOne([ProfileRole.LEDGER_ADMIN, ProfileRole.HOLDER], [currentProfile.role])
        ? currentProfile.name
        : ''
    }
    return ''
  }

  constructor(props: IHeaderProps) {
    super(props)

    this.state = {
      mobileNavVisible: false,
    }
  }

  componentDidUpdate(prevProps: IHeaderProps) {
    if (this.state.mobileNavVisible) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }

  closeMobileNav = () => {
    this.setState({ mobileNavVisible: false })
    if (this.mobileNavToggleRef.current) {
      this.mobileNavToggleRef.current.checked = false
    }
    document.body.classList.remove('modal-open')
  }

  BEM() {
    const classArray = ['header']

    if (!this.props.isLoggedIn) {
      classArray.push('header--center')
    }

    if (this.props.globalNotification) {
      classArray.push('header--has-global-notification')
    }

    return classArray.join(' ')
  }

  public render(): JSX.Element {
    const { givenNames, lastName, middleName, nameSuffix, currentProfile } = this.props.userDetails
    const fullName = getFullName({ givenNames, lastName, middleName, nameSuffix })
    return (
      <div className={this.BEM()}>
        {this.props.globalNotification && <GlobalNotification {...this.props.globalNotification} />}
        <Container>
          <Link className="header__branding" to={paths.dashboard.root} onClick={this.closeMobileNav}>
            <Icon name={ICONS.LOGO} className="header__logo" />
          </Link>
          {this.props.isLoggedIn && (
            <>
              <div id="header-menu-toggle">
                <input
                  type="checkbox"
                  onChange={e => this.setState({ mobileNavVisible: e.target.checked })}
                  ref={this.mobileNavToggleRef}
                />
                <span />
                <span />
                <span />
              </div>

              <div
                className={`header__nav-container ${
                  this.state.mobileNavVisible ? 'header__nav-container--mobile-visible' : ''
                }`}
              >
                <div className="header__nav">
                  <Navigation links={this.props.links} closeMobileNav={this.closeMobileNav} />
                </div>
                <User
                  user={fullName}
                  company={this.companyName}
                  toggleNavBar={() => this.props.toggleNavBar()}
                  profile={currentProfile}
                  closeMobileNav={this.closeMobileNav}
                />
                <SideNav
                  toggleNavBar={() => this.props.toggleNavBar()}
                  closeMobileNav={this.closeMobileNav}
                  open={this.props.sideNavOpen}
                  userLedgers={this.props.userLedgers}
                  company={this.companyName}
                  user={fullName}
                  selectProfile={payload => this.props.selectProfile(payload)}
                  logOut={() => this.props.logOut()}
                  currentProfile={currentProfile}
                  disabledItems={this.props.disabledItems}
                  isOnboardingCompleted={this.props.isOnboardingCompleted}
                />
              </div>
            </>
          )}
        </Container>
      </div>
    )
  }
}

export default Header
