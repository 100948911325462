import { Action, handleActions } from 'redux-actions'
import { GetLedgerDocuments$LedgerDocument } from 'services/api.types'
import { GetCompanyDocuments, GetCompanyDocumentsFailure, GetCompanyDocumentsSuccess } from './CompanyHistory.actions'

class State {
  documents: GetLedgerDocuments$LedgerDocument[] = []
  documentsLoading: boolean = false
  documentsLoaded: boolean = false
}

export default handleActions<State, any>(
  {
    [String(GetCompanyDocuments)]: (state: State): State => {
      return {
        ...state,
        documentsLoading: true,
        documentsLoaded: false,
      }
    },

    [String(GetCompanyDocumentsSuccess)]: (
      state: State,
      action: Action<GetLedgerDocuments$LedgerDocument[]>,
    ): State => {
      return {
        ...state,
        documents: action.payload,
        documentsLoading: false,
        documentsLoaded: true,
      }
    },

    [String(GetCompanyDocumentsFailure)]: (state: State): State => {
      return {
        ...state,
        documentsLoading: false,
        documentsLoaded: true,
      }
    },
  },
  new State(),
)

export { State as CompanyHistoryState }
