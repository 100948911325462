import { Action, handleActions } from 'redux-actions'
import {
  GetCompanyData,
  GetCompanyDataFail,
  GetCompanyDataSuccess,
  GetCompanyFormPrefaceData,
  GetCompanyFormPrefaceDataFailure,
  GetCompanyFormPrefaceDataSuccess,
  GetDelawareScrapedData,
  GetDelawareScrapedDataSuccess,
  GetDelawareScrapedDataFailure,
} from './CompanyFormActions'
import { ICompanyFormFields } from './CompanyFormFieldNames'
import { SetLoaded, SetLoading } from 'views/issuance/IssuanceViewActions'
import { IDelawareData } from '../../../../common/utils/delawareScrape'

export interface ICompanyFormPrefaceData {
  loading: boolean
  registrationNumber: string
  delawareScrapedData?: IDelawareData
}

class State {
  company: ICompanyFormFields = {
    name: '',
    registrationDate: '',
    registrationNumber: '',
    address: {
      apartment: '',
      buildingNumber: '',
      city: '',
      country: 'United States of America',
      state: 'Delaware',
      streetAddress: '',
      zipCode: '',
    },
    historicLedger: undefined,
    documents: [],
  }
  prefaceData: ICompanyFormPrefaceData = {
    loading: false,
    registrationNumber: '',
  }
  delawareScrapedData: IDelawareData = {
    companyDetails: {
      address: '',
      city: '',
      corporationName: '',
      incorporationDate: '',
      state: '',
      zipCode: '',
    },
    stockDetails: [],
  }
  loading: boolean = false
  loaded: boolean = false
  delawareScrapeError: boolean = false
  delawareScrapeLoading: boolean = false
  delawareScrapeHasLoaded: boolean = false
}

export default handleActions<State, any>(
  {
    /* Get User Data */
    [String(GetCompanyData)]: (state: State): State => {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    },
    /* Get User Data Success */
    [String(GetCompanyDataSuccess)]: (state: State, action: Action<ICompanyFormFields>): State => {
      return {
        ...state,
        company: action.payload,
        loading: false,
        loaded: true,
      }
    },
    /* Get User Data Fail */
    [String(GetCompanyDataFail)]: (state: State): State => {
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    },
    [String(SetLoading)]: (state: State): State => {
      return { ...state, loading: true }
    },
    [String(SetLoaded)]: (state: State): State => {
      return { ...state, loading: false }
    },
    /* Get preface data */
    [String(GetCompanyFormPrefaceData)]: (state: State): State => {
      return {
        ...state,
        prefaceData: {
          ...state.prefaceData,
          loading: true,
        },
      }
    },
    [String(GetCompanyFormPrefaceDataSuccess)]: (state: State, action: Action<ICompanyFormPrefaceData>): State => {
      return {
        ...state,
        prefaceData: {
          ...action.payload,
          loading: false,
        },
      }
    },
    [String(GetCompanyFormPrefaceDataFailure)]: (state: State): State => {
      return {
        ...state,
        prefaceData: {
          ...state.prefaceData,
          loading: false,
        },
      }
    },
    /* Get preface data */
    [String(GetDelawareScrapedData)]: (state: State): State => {
      return {
        ...state,
        delawareScrapedData: {
          ...state.delawareScrapedData,
        },
        company: { ...state.company, historicLedger: undefined },
        delawareScrapeError: false,
        delawareScrapeLoading: true,
        delawareScrapeHasLoaded: false,
      }
    },
    [String(GetDelawareScrapedDataSuccess)]: (state: State, action: Action<IDelawareData>): State => {
      return {
        ...state,
        delawareScrapedData: {
          ...action.payload,
        },
        company: { ...state.company, historicLedger: undefined },
        delawareScrapeError: false,
        delawareScrapeLoading: false,
        delawareScrapeHasLoaded: true,
      }
    },
    [String(GetDelawareScrapedDataFailure)]: (state: State): State => {
      return {
        ...state,
        delawareScrapedData: {
          companyDetails: {
            address: '',
            city: '',
            corporationName: '',
            incorporationDate: '',
            state: '',
            zipCode: '',
          },
          stockDetails: [],
        },
        company: { ...state.company, historicLedger: undefined },
        delawareScrapeError: true,
        delawareScrapeLoading: false,
        delawareScrapeHasLoaded: true,
      }
    },
  },
  new State(),
)

export { State as CompanyFormState }
