import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'

import './Container.scss'

export interface IContainerProps {
  /**
   * Container type
   */
  type?: 'onboarding' | 'login' | 'recovery-by-sms' | 'dense'
  /**
   * ClassName
   */
  className?: string
}

class Container extends BaseComponent<IContainerProps> {
  BEM(): string {
    const classArray = ['container']

    if (this.props.type) {
      classArray.push(`container--${this.props.type}`)
    }

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  public render(): JSX.Element {
    return <div className={this.BEM()}>{this.props.children}</div>
  }
}

export default Container
