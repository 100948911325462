import { createAction } from 'redux-actions'

const ns = 'settings/legalHolderCompany/'

/* Get Legal Entity Company Details */
export const GetLegalEntityDetails = createAction(`${ns}GET_LEGAL_ENTITY_DETAILS`)
export const GetLegalEntityDetailsSuccess = createAction(`${ns}GET_LEGAL_ENTITY_DETAILS_SUCCESS`)
export const GetLegalEntityDetailsFail = createAction(`${ns}GET_LEGAL_ENTITY_DETAILS_FAIL`)

// Used to save Legal Entity data
export const UpdateLegalEntityDetails = createAction(`${ns}SAVE_LEGAL_ENTITY_DETAILS`)

// Add/Confirm Legal Entity data on first time.
export const AddLegalEntityDetails = createAction(`${ns}ADD_LEGAL_ENTITY_DETAILS`)
