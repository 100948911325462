import * as ISOCountries from 'i18n-iso-countries/index'
// Index left here intentionally
export function configLocale() {
  ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
}
export function getCountriesList() {
  const countries = ISOCountries.getNames('en')
  return Object.keys(countries).map(country => {
    return {
      code: country,
      name: countries[country],
    }
  })
}
