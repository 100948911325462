import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import {
  GetStockList,
  GetStockListFail,
  GetStockListSuccess,
  GetUserStockList,
  GetUserStockListFail,
  GetUserStockListSuccess,
} from './stocksAction'
import api from 'services/Api.services'
import { GetDashboard$Response$Progress, GetMyStockList$Response, GetStockList$Response } from 'services/api.types'
import { getStockTotalsFromHistoricDraft } from 'views/transfers/children/new-transfer-historic-view/NewTransferHistoricView.helpers'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { getIsHistoricImportMode } from '../common/commonSelector'
import DraftStorage from 'common/utils/draftStorage'
import { DEFAULT_HISTORIC_IMPORT_DRAFT, IHistoricLedgerImportDraft } from 'views/transfers/TransfersViewReducer'
import { getParsedStockList } from 'common/utils/stockUtil'

/* Get Stock List */
function* sagaGetStockList() {
  try {
    let stockListResponse: GetStockList$Response = yield call(api.ledger.getStockList)
    const historicImportMode: GetDashboard$Response$Progress = yield select(getIsHistoricImportMode)

    stockListResponse.list = getParsedStockList(stockListResponse.list)

    if (historicImportMode && stockListResponse?.list.length > 0) {
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

      stockListResponse = {
        list: stockListResponse.list.map(s => ({
          ...s,
          totals: getStockTotalsFromHistoricDraft(s.id, stockListResponse.list, draft),
        })),
      }
    }

    yield put({ type: String(GetStockListSuccess), payload: stockListResponse.list })
  } catch (e) {
    console.log('Error during GetStockList', e)
    yield put({ type: String(GetStockListFail) })
  }
}

/* Get User Stock List */
function* sagaUserGetStockList() {
  try {
    const stockList: GetMyStockList$Response = yield call(api.ledger.getMyStockList)
    yield put({
      type: String(GetUserStockListSuccess),
      payload: stockList && stockList.accounts ? stockList.accounts[0].stocks : [],
    })
  } catch (e) {
    console.log('Error during GetUserStockList', e)
    yield put({ type: String(GetUserStockListFail) })
  }
}

export default function* stocksSagas() {
  yield all([
    takeLatest(String(GetStockList), sagaGetStockList),
    takeLatest(String(GetUserStockList), sagaUserGetStockList),
  ])
}
