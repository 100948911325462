import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { BaseComponent } from 'components/BaseComponent'

import VALIDATIONS from 'common/constants/validation-rules'
import { ChangePassword$Request } from 'services/api.types'
import { default as fieldNames, fieldNamesSource, IChangePasswordFieldNames } from './ChangePasswordFieldNames'
import GroupItems from 'components/ui/group-items/GroupItems'
import GroupItem from 'components/ui/group-items/GroupItem'
import Button from 'components/ui/button/Button'
import PassField from 'components/ui/form/passfield/PassField'
import { formFieldTypes } from 'components/ui/form/textfield/TextField'

import './ChangePassword.scss'

export interface ICredentialsProps extends WithTranslation {
  /**
   * Change password call handler
   */
  changePassword: (values: ChangePassword$Request) => void
  /**
   * Custom class
   */
  className?: string
}

class ChangePassword extends BaseComponent<ICredentialsProps> {
  // create dynamic validation schema based on form values
  private validationSchema = Yup.lazy(values => {
    const t = this.props.t

    const validationRules = {
      oldPassword: VALIDATIONS.PASSWORD.required(t('input-old-password-required-error')),
      newPassword: VALIDATIONS.PASSWORD.required(t('input-new-password-required-error')),
      newPasswordRepeat: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('input-password-match-error'))
        .required(t('input-confirm-password-required-error')),
    }

    return Yup.object().shape(validationRules)
  })

  constructor(props: ICredentialsProps) {
    super(props)
  }

  BEM(): string {
    const classArray = ['change-password']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  onSubmit(values: IChangePasswordFieldNames, resetForm: any) {
    const { oldPassword, newPassword } = values
    this.props.changePassword({ newPassword, oldPassword })
    resetForm()
  }

  public render() {
    const t = this.props.t

    return (
      <div className={this.BEM()}>
        <Formik
          initialValues={fieldNamesSource}
          validateOnBlur={true}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => this.onSubmit(values, resetForm)}
          validationSchema={this.validationSchema}
          render={({
            handleSubmit,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            touched,
            submitForm,
          }) => (
            <GroupItems>
              <GroupItem>
                <PassField
                  id={fieldNames.oldPassword}
                  name={fieldNames.oldPassword}
                  showToggle={true}
                  label={t('input-old-password-label')}
                  autocomplete={false}
                  onChange={value => setFieldValue(fieldNames.oldPassword, value)}
                  onBlur={handleBlur}
                  value={values[fieldNames.oldPassword]}
                  isInvalid={touched[fieldNames.oldPassword] && errors[fieldNames.oldPassword]}
                  helper={
                    touched[fieldNames.oldPassword] && errors[fieldNames.oldPassword]
                      ? { type: 'error', text: t(errors[fieldNames.oldPassword]) }
                      : null
                  }
                  style={formFieldTypes.LIGHT}
                />
              </GroupItem>
              <GroupItem>
                <PassField
                  id={fieldNames.newPassword}
                  name={fieldNames.newPassword}
                  showToggle={true}
                  showStrengthBar={!(touched[fieldNames.newPassword] && errors[fieldNames.newPassword])}
                  label={t('input-new-password-label')}
                  autocomplete={false}
                  onChange={value => setFieldValue(fieldNames.newPassword, value)}
                  onBlur={handleBlur}
                  value={values[fieldNames.newPassword]}
                  isInvalid={touched[fieldNames.newPassword] && errors[fieldNames.newPassword]}
                  helper={
                    touched[fieldNames.newPassword] && errors[fieldNames.newPassword]
                      ? { type: 'error', text: t(errors[fieldNames.newPassword]) }
                      : null
                  }
                  style={formFieldTypes.LIGHT}
                />
              </GroupItem>
              <GroupItem>
                <PassField
                  id={fieldNames.newPasswordRepeat}
                  name={fieldNames.newPasswordRepeat}
                  showToggle={true}
                  label={t('input-confirm-password-label')}
                  autocomplete={false}
                  onChange={value => setFieldValue(fieldNames.newPasswordRepeat, value)}
                  onBlur={handleBlur}
                  value={values[fieldNames.newPasswordRepeat]}
                  isInvalid={touched[fieldNames.newPasswordRepeat] && errors[fieldNames.newPasswordRepeat]}
                  helper={
                    touched[fieldNames.newPasswordRepeat] && errors[fieldNames.newPasswordRepeat]
                      ? { type: 'error', text: t(errors[fieldNames.newPasswordRepeat]) }
                      : null
                  }
                  style={formFieldTypes.LIGHT}
                />
              </GroupItem>
              <GroupItem size="md">
                <Button
                  variant="alt"
                  className="change-password-btn"
                  text={t('change-password')}
                  onClick={submitForm}
                />
              </GroupItem>
            </GroupItems>
          )}
        />
      </div>
    )
  }
}

export default withTranslation('settingsCredentials')(ChangePassword)
