import { datadogLogs } from 'vendor/datadog'

let context: { userId: string | null } = { userId: null }
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'
const APPLICATION_HOST = window.location.host

// In JS, the "Error" class does not have any enumerable properties
// which means that Object.keys(new Error()) returns empty, which means
// that by default, for json logs, we'll be logging an empty object in
// place of an error this function replaces errors with their properties
// copy-pastaed
function normalizeDetails(details: Record<string, any> | undefined): Record<string, any> | undefined {
  if (details == null) {
    return details
  }

  const newDetails: typeof details = {}
  Object.keys(details).forEach(key => {
    if (details[key] instanceof Error) {
      const err = details[key]
      // eslint-disable-next-line no-param-reassign
      newDetails[key] = {
        level: 'error',
        code: err.code,
        name: err.name,
        message: err.message,
        stack: err.stack,
        ...err,
        // ^ All enumerable properties
      }
    } else {
      newDetails[key] = details[key]
    }
  })
  return newDetails
}

export function setContext(newContext: typeof context): void {
  context = newContext
}

function debug(message: string, originalDetails?: Record<string, any>): void {
  const details = normalizeDetails(originalDetails)
  if (IS_DEVELOPMENT) {
    // Frontend development logging
    console.debug(message, { details, context, site: APPLICATION_HOST })
  } else {
    // Frontend logging:
    datadogLogs.logger.debug(message, { details, context, site: APPLICATION_HOST })
  }
}

function info(message: string, originalDetails?: Record<string, any>): void {
  const details = normalizeDetails(originalDetails)
  if (IS_DEVELOPMENT) {
    // Frontend development logging
    console.info(message, { details, context, site: APPLICATION_HOST })
  } else {
    // Frontend logging:
    datadogLogs.logger.info(message, { details, context, site: APPLICATION_HOST })
  }
}

function warning(message: string, originalDetails?: Record<string, any>): void {
  const details = normalizeDetails(originalDetails)
  if (IS_DEVELOPMENT) {
    // Frontend development logging
    console.warn(message, { details, context, site: APPLICATION_HOST })
  } else {
    // Frontend logging:
    datadogLogs.logger.warn(message, { details, context, site: APPLICATION_HOST })
  }
}

function error(message: string, originalDetails?: Record<string, any>): void {
  const details = normalizeDetails(originalDetails)
  if (IS_DEVELOPMENT) {
    // Frontend development logging
    console.error(message, { details, context, site: APPLICATION_HOST })
  } else {
    // Frontend logging:
    datadogLogs.logger.error(message, { details, context, site: APPLICATION_HOST })
  }
}

const logger = { debug, info, warning, error }

export default logger
