import { Action, handleActions } from 'redux-actions'
import { UpdateState } from './RecoverySmsViewActions'

class State {
  successUrl: string = ''
  errorUrl: string = ''
  signActionId: string = ''

  constructor(props?: State) {
    if (props) {
      this.signActionId = props.signActionId || ''
      this.successUrl = props.successUrl || ''
      this.errorUrl = props.errorUrl || ''
    }
  }
}

export default handleActions<State, any>(
  {
    [String(UpdateState)]: (state: State, action: Action<State>): State => {
      return new State(action.payload)
    },
  },
  new State(),
)

export { State as RecoverySmsViewState }
