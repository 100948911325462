import { all, call, put, take, takeEvery } from 'redux-saga/effects'
import {
  AddAndTriggerAppKeyVerification,
  AddAndTriggerPhoneVerification,
  AddRegisterNotification,
  ClearRegisterNotification,
  CreatePassword,
  PhoneVerificationCodeFail,
  SendAppDownloadInfo,
  UpdateAndReTriggerPhoneVerification,
  UpdateAndTriggerAppKeyVerification,
  UpdatePhoneVerificationCode,
  VerifyWithSecondaryMethod,
} from './RegistrationViewActions'
import {
  AddCredential$Request,
  AddCredential$Response,
  ChangeCredential$Request,
  ChangeCredential$Response,
  CreatePassword$Request,
  VerifyWithSecondaryMethod$Request,
} from 'services/api.types'
import { Action } from 'redux-actions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { whoAmI, whoAmISuccess } from 'common/auth/AuthActions'
import api from 'services/Api.services'
import { NotificationType } from 'components/ui/notification/Notification'
import { GetUserCredentials } from '../settings/children/credentials/CredentialsViewActions'
import { Sign } from '../signing/SigningViewActions'
import { GetDashboardDataOnly, ShowRegistrationSuccess } from '../dashboard/DashboardViewActions'
import { IAddPhoneField } from './children/phone/AddPhoneFieldNames'
import i18n from 'i18next'

function* sagaCreatePassword(action: Action<CreatePassword$Request>) {
  try {
    yield call(api.user.createPassword, action.payload)
    yield put({ type: String(whoAmI) })
    yield take(String(whoAmISuccess))
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

function* sagaAddAndTriggerVerifyPhone(action: Action<AddCredential$Request>) {
  yield put({ type: String(ClearRegisterNotification) })
  try {
    yield call(api.user.addCredential, action.payload)
    yield put({ type: String(GetUserCredentials) })
    // yield call(sagaWhoAmI);
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

function* sagaReTriggerPhoneVerification(action: Action<ChangeCredential$Request>) {
  yield put({ type: String(ClearRegisterNotification) })
  try {
    yield call(api.user.changeCredential, action.payload)
    yield put({ type: String(GetUserCredentials) })
    yield put({ type: String(GetDashboardDataOnly) })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

function* sagaVerifyWithSecondaryMethod(action: Action<VerifyWithSecondaryMethod$Request>) {
  yield put({ type: String(ClearRegisterNotification) })
  try {
    yield call(api.user.verifyWithSecondaryMethod, action.payload)
    yield put({ type: String(GetUserCredentials) })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

function* sagaHandlePhoneVerifyCodeSubmit(action: Action<IAddPhoneField>) {
  yield put({ type: String(ClearRegisterNotification) })
  const payload = action.payload

  if (payload && payload.phoneVerificationCode && payload.phoneVerificationCode.length === 6) {
    try {
      yield call(api.user.verifyPhoneNumber, { code: action.payload.phoneVerificationCode })
      yield put({ type: String(GetDashboardDataOnly) })
      yield put({ type: String(SendAppDownloadInfo) })
    } catch (e) {
      yield put({ type: String(PhoneVerificationCodeFail), payload: e.message })
      const notification: INotification = {
        ...e.response.data,
        type: NotificationType.ERROR,
      }
      yield put({ type: String(AddRegisterNotification), payload: notification })
    }
  }
}

function* sagaAddVerifiedApp(action: Action<AddCredential$Request>) {
  yield put({ type: String(ClearRegisterNotification) })
  try {
    const response: AddCredential$Response = yield call(api.user.addCredential, action.payload)
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        ...getAppAddActions(),
        isInitial: true,
      },
    })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

function* sagaReTriggerAppVerification(action: Action<ChangeCredential$Request>) {
  yield put({ type: String(ClearRegisterNotification) })
  try {
    const response: ChangeCredential$Response = yield call(api.user.changeCredential, action.payload)
    yield put({
      type: String(Sign),
      payload: {
        signActionId: response.signActionId,
        ...getAppAddActions(),
        isInitial: true,
      },
    })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

function getAppAddActions() {
  return {
    signedAction: {
      type: String(ShowRegistrationSuccess),
      payload: true,
    },
    expiredAction: {
      type: String(AddRegisterNotification),
      payload: {
        description: i18n.t('signingView:default.expired.title'),
        type: NotificationType.ERROR,
      },
    },
    deniedAction: {
      type: String(AddRegisterNotification),
      payload: {
        description: i18n.t('signingView:default.denied.title'),
        type: NotificationType.ERROR,
      },
    },
    cancelledAction: {
      type: String(AddRegisterNotification),
      payload: {
        description: i18n.t('signingView:default.cancel.title'),
        type: NotificationType.ERROR,
      },
    },
  }
}

function* sagaSendAppDownloadInfo() {
  try {
    yield call(api.user.sendAppDownloadInfo)
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(AddRegisterNotification), payload: notification })
  }
}

export default function* registrationViewSagas() {
  yield all([
    takeEvery(String(CreatePassword), sagaCreatePassword),
    takeEvery(String(AddAndTriggerPhoneVerification), sagaAddAndTriggerVerifyPhone),
    takeEvery(String(UpdateAndReTriggerPhoneVerification), sagaReTriggerPhoneVerification),
    takeEvery(String(VerifyWithSecondaryMethod), sagaVerifyWithSecondaryMethod),
    takeEvery(String(UpdatePhoneVerificationCode), sagaHandlePhoneVerifyCodeSubmit),
    takeEvery(String(AddAndTriggerAppKeyVerification), sagaAddVerifiedApp),
    takeEvery(String(UpdateAndTriggerAppKeyVerification), sagaReTriggerAppVerification),
    takeEvery(String(SendAppDownloadInfo), sagaSendAppDownloadInfo),
  ])
}
