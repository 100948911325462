import { all, call, put, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import {
  AddLedgerAdmin,
  ChangeView,
  DeactivateProfile,
  GetContractList,
  GetContractListSuccess,
  GetLedgerUserList,
  GetLedgerUserListFail,
  GetLedgerUserListSuccess,
  GetPendingLedgerUserList,
  GetPendingLedgerUserListFail,
  GetPendingLedgerUserListSuccess,
  ReactivateProfile,
  RevokePendingLedgerAdmin,
} from './SettingsViewActions'
import { Action } from 'redux-actions'
import api from 'services/Api.services'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { addNotification, clearNotification } from 'common/store/notifications/NotificationActions'
import {
  GetContractList$Response,
  GetLedgerUserList$Response,
  GetPendingLedgerAdminEmailList$Response,
  ProfileId,
  RevokePendingLedgerAdminEmail$Request,
} from 'services/api.types'

/* On settings page tab change */
function* sagaChangeView() {
  yield put({ type: String(clearNotification) })
}

function* sagaGetLedgerUserList() {
  try {
    const ledgerUserList: GetLedgerUserList$Response = yield call(api.ledger.getLedgerUserList)
    yield put({ type: String(GetLedgerUserListSuccess), payload: ledgerUserList.list })
  } catch (e) {
    yield put({ type: String(GetLedgerUserListFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaGetPendingLedgerUserList() {
  try {
    const pendingLedgerUserList: GetPendingLedgerAdminEmailList$Response = yield call(
      api.ledger.getPendingLedgerAdminEmailList,
    )
    yield put({ type: String(GetPendingLedgerUserListSuccess), payload: pendingLedgerUserList.list })
  } catch (e) {
    yield put({ type: String(GetPendingLedgerUserListFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaDeactivateProfile(action: Action<ProfileId>) {
  try {
    yield call(api.ledger.deactivateProfile, { id: action.payload })
    yield put({ type: String(GetLedgerUserList) })
    const notification: INotification = {
      type: NotificationType.SUCCESS,
      description: i18n.t('frontendNotifications:success.deactivate-profile'),
    }
    yield put({ type: String(addNotification), payload: notification })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaReactivateProfile(action: Action<ProfileId>) {
  try {
    yield call(api.ledger.reactivateProfile, { id: action.payload })
    yield put({ type: String(GetLedgerUserList) })
    const notification: INotification = {
      type: NotificationType.SUCCESS,
      description: i18n.t('frontendNotifications:success.reactivate-profile'),
    }
    yield put({ type: String(addNotification), payload: notification })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaRevokePendingLedgerAdmin(action: Action<RevokePendingLedgerAdminEmail$Request>) {
  try {
    yield call(api.ledger.revokePendingLedgerAdminEmail, action.payload)
    yield put({ type: String(GetPendingLedgerUserList) })
    const notification: INotification = {
      type: NotificationType.SUCCESS,
      description: i18n.t('frontendNotifications:success.revoke-admin-email'),
    }
    yield put({ type: String(addNotification), payload: notification })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaAddLedgerAdmin(action: Action<string>) {
  try {
    yield call(api.ledger.addLedgerAdmin, { email: action.payload })
    yield put({ type: String(GetLedgerUserList) })
    yield put({ type: String(GetPendingLedgerUserList) })
    const notification: INotification = {
      type: NotificationType.SUCCESS,
      description: i18n.t('frontendNotifications:success.add-ledger-admin'),
    }
    yield put({ type: String(addNotification), payload: notification })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaGetContractList() {
  try {
    const contractList: GetContractList$Response = yield call(api.ledger.getContractList)
    yield put({ type: String(GetContractListSuccess), payload: contractList.contracts })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* settingsViewSagas() {
  yield all([
    takeEvery(String(GetLedgerUserList), sagaGetLedgerUserList),
    takeEvery(String(GetPendingLedgerUserList), sagaGetPendingLedgerUserList),
    takeEvery(String(GetContractList), sagaGetContractList),
    takeEvery(String(DeactivateProfile), sagaDeactivateProfile),
    takeEvery(String(ReactivateProfile), sagaReactivateProfile),
    takeEvery(String(RevokePendingLedgerAdmin), sagaRevokePendingLedgerAdmin),
    takeEvery(String(AddLedgerAdmin), sagaAddLedgerAdmin),
    takeEvery(String(ChangeView), sagaChangeView),
  ])
}
