import * as React from 'react'
import moment from 'moment'
import './Footer.scss'
import { INavigationLink } from 'components/ui/header/children/navigation/Navigation'
import { BaseComponent } from 'components/BaseComponent'
import Icon, { ICONS } from 'components/ui/icon/Icon'

export interface IStateProps {
  isLoggedIn: boolean
  links: INavigationLink[]
}

export interface IDispatchProps {}

export type IFooterProps = IStateProps & IDispatchProps

class Footer extends BaseComponent<IFooterProps> {
  public render(): JSX.Element {
    const { links } = this.props
    return (
      <div id="footer-main" className="footer">
        <ul className="footer__links footer__links--left">
          <li className="footer__link">
            <div className="footer__link__inner">
              <b>Copyright © {moment().format('YYYY')} StockTreasury</b>
            </div>
          </li>
        </ul>
        <ul className="footer__links footer__links--right">
          {this.props.isLoggedIn && (
            <>
              <li className="footer__link">
                <div className="footer__link__inner">
                  <a target="_blank" href="https://stocktreasury.com/user-guidance-tutorials/">
                    How-to guides
                  </a>
                </div>
              </li>
            </>
          )}
          <li className="footer__link">
            <div className="footer__link__inner">
              <a target="_blank" href="https://stocktreasury.com/frequently-asked-questions/">
                FAQ
              </a>
            </div>
          </li>
          <li className="footer__link">
            <div className="footer__link__inner">
              <Icon name={ICONS.MAIL} />
              <a href="mailto:support@stocktreasury.com">support@stocktreasury.com</a>
            </div>
          </li>
          <li className="footer__link">
            <div className="footer__link__inner">
              <Icon name={ICONS.PHONE} />
              <a href="tel:+13029569555">+1 302 956 9555</a>
            </div>
          </li>
        </ul>
        {/**
                 <ul className="footer__links">
                 {links.map((link, index) => {
                        return (
                            <li key={index} className="footer__link">
                                <NavLink
                                    to={link.url}
                                    className={`nav__link ${link.disabled ? 'nav__link--disabled' : ''}`}
                                    activeClassName="is-active"
                                >
                                    {link.label}
                                </NavLink>
                            </li>
                        );
                    })}
                 </ul>
                 */}
      </div>
    )
  }
}

export default Footer
