import { Action, handleActions } from 'redux-actions'
import {
  ClearIssuanceDraft,
  SaveIssuanceDraft,
  SaveIssuanceDraftSuccess,
  SaveIssuanceDraftFailure,
  SaveProformaIssuanceDraft,
  SaveProformaIssuanceDraftSuccess,
  SaveProformaIssuanceDraftFailure,
  GetProformaIssuanceDraft,
  GetProformaIssuanceDraftSuccess,
  GetProformaIssuanceDraftFailure,
  ClearProformaIssuanceDraft,
  ClearProformaIssuanceDraftSuccess,
  ClearProformaIssuanceDraftFailure,
  SetLoaded,
  SetLoading,
  CreateProformaIssuanceHolder,
  CreateProformaIssuanceHolderSuccess,
  CreateProformaIssuanceHolderFailure,
  UpdateProformaIssuanceHolder,
  UpdateProformaIssuanceHolderSuccess,
  UpdateProformaIssuanceHolderFailure,
  SetActiveProformaIssuanceHolder,
  UnsetActiveProformaIssuanceHolder,
  GetIssuanceDraft,
  GetIssuanceDraftSuccess,
  GetIssuanceDraftFailure,
  ClearIssuanceDraftFailure,
  ClearIssuanceDraftSuccess,
} from './IssuanceViewActions'
import { AccountDto, HolderType, StockListDto, UploadedDocumentDto, CurrencyType } from 'services/api.types'
import { IFormFields as IProformaFormFields } from './children/issue-stock-proforma/IssueStockProforma.fieldNames'
import { SelectUserProfile } from 'common/auth/AuthActions'

export interface IAccountStocks {
  account: AccountDto
  numberOfShares: number
  pricePerShare: number
  amountPaid: number
  currencyType: CurrencyType
  currency: Currency
  stockRestriction: string
  documents: UploadedDocumentDto[]
}

export interface IIssuanceDraft {
  id: string
  stockDetails: StockListDto
  addedAccounts: IAccountStocks[]
  dateCreated?: number
}

export interface IProformaDraftLoading {
  getProformaDraft: boolean
  saveProformaDraft: boolean
  clearProformaDraft: boolean
  saveHolder: boolean
}

export interface IDraftLoading {
  getDraft: boolean
  saveDraft: boolean
  clearDraft: boolean
}

export interface IProfformaIssuanceDraft {
  draft: IProformaFormFields
  selectedHolderTempId: string
  loading: IProformaDraftLoading
}

class State {
  issuanceDraft: IIssuanceDraft = {
    id: null,
    stockDetails: null,
    addedAccounts: [],
    dateCreated: null,
  }
  loading: boolean = false // General loading flag for different (early implementation) cases
  draftLoading: IDraftLoading = {
    getDraft: false,
    saveDraft: false,
    clearDraft: false,
  }
  proformaIssuance: IProfformaIssuanceDraft = {
    draft: { data: [] },
    selectedHolderTempId: null,
    loading: {
      clearProformaDraft: false,
      getProformaDraft: false,
      saveProformaDraft: false,
      saveHolder: false,
    },
  }
}

const defaultState = new State()

export default handleActions<State, any>(
  {
    [String(SelectUserProfile)]: (state: State): State => ({
      ...state,
      issuanceDraft: defaultState.issuanceDraft,
      proformaIssuance: defaultState.proformaIssuance,
      draftLoading: defaultState.draftLoading,
    }),
    [String(SaveIssuanceDraft)]: (state: State): State => {
      return { ...state, draftLoading: { ...state.draftLoading, saveDraft: true } }
    },
    [String(SaveIssuanceDraftSuccess)]: (state: State, action: Action<IIssuanceDraft>): State => {
      return { ...state, draftLoading: { ...state.draftLoading, saveDraft: false }, issuanceDraft: action.payload }
    },
    [String(SaveIssuanceDraftFailure)]: (state: State): State => {
      return { ...state, draftLoading: { ...state.draftLoading, saveDraft: false } }
    },
    [String(ClearIssuanceDraft)]: (state: State): State => {
      return {
        ...state,
        draftLoading: { ...state.draftLoading, clearDraft: true },
        issuanceDraft: defaultState.issuanceDraft,
      }
    },
    [String(ClearIssuanceDraftSuccess)]: (state: State): State => {
      return { ...state, draftLoading: { ...state.draftLoading, clearDraft: false } }
    },
    [String(ClearIssuanceDraftFailure)]: (state: State): State => {
      return { ...state, draftLoading: { ...state.draftLoading, clearDraft: false } }
    },
    [String(GetIssuanceDraft)]: (state: State): State => {
      return {
        ...state,
        draftLoading: { ...state.draftLoading, getDraft: true },
        issuanceDraft: defaultState.issuanceDraft,
      }
    },
    [String(GetIssuanceDraftSuccess)]: (state: State, action: Action<IIssuanceDraft>): State => {
      return {
        ...state,
        draftLoading: { ...state.draftLoading, getDraft: false },
        issuanceDraft: action.payload || defaultState.issuanceDraft,
      }
    },
    [String(GetIssuanceDraftFailure)]: (state: State): State => {
      return { ...state, draftLoading: { ...state.draftLoading, getDraft: false } }
    },
    [String(GetProformaIssuanceDraft)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, getProformaDraft: true },
        },
      }
    },
    [String(GetProformaIssuanceDraftSuccess)]: (state: State, action: Action<IProformaFormFields>): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          draft: action.payload,
          loading: { ...state.proformaIssuance.loading, getProformaDraft: false },
        },
      }
    },
    [String(GetProformaIssuanceDraftFailure)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, getProformaDraft: false },
        },
      }
    },
    [String(SaveProformaIssuanceDraft)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveProformaDraft: true },
        },
      }
    },
    [String(SaveProformaIssuanceDraftSuccess)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveProformaDraft: false },
        },
      }
    },
    [String(SaveProformaIssuanceDraftFailure)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveProformaDraft: false },
        },
      }
    },
    [String(ClearProformaIssuanceDraft)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, clearProformaDraft: true },
        },
      }
    },
    [String(ClearProformaIssuanceDraftSuccess)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          draft: { data: [] },
          loading: { ...state.proformaIssuance.loading, clearProformaDraft: false },
        },
      }
    },
    [String(ClearProformaIssuanceDraftFailure)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, clearProformaDraft: false },
        },
      }
    },
    [String(CreateProformaIssuanceHolder)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveHolder: true },
        },
      }
    },
    [String(CreateProformaIssuanceHolderSuccess)]: (state: State, action: Action<any>): State => {
      const { details, holderTempId, type } = action.payload
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveHolder: false },
          draft: {
            ...state.proformaIssuance.draft,
            data: state.proformaIssuance.draft.data.map(stock => ({
              ...stock,
              holders: stock.holders.map(holder => {
                return holder.id === holderTempId
                  ? {
                      ...holder,
                      type,
                      addedLegalHolder: type === HolderType.LEGAL_ENTITY ? details : null,
                      addedNaturalHolder: type === HolderType.NATURAL_PERSON ? details : null,
                    }
                  : holder
              }),
            })),
          },
        },
      }
    },
    [String(CreateProformaIssuanceHolderFailure)]: (state: State, action: Action<any>): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveHolder: false },
        },
      }
    },
    [String(UpdateProformaIssuanceHolder)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveHolder: true },
        },
      }
    },
    [String(UpdateProformaIssuanceHolderSuccess)]: (state: State, action: Action<any>): State => {
      const { details, holderTempId, type } = action.payload
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveHolder: false },
          draft: {
            ...state.proformaIssuance.draft,
            data: state.proformaIssuance.draft.data.map(stock => ({
              ...stock,
              holders: stock.holders.map(holder =>
                holder.id === holderTempId
                  ? {
                      ...holder,
                      type,
                      addedLegalHolder: type === HolderType.LEGAL_ENTITY ? details : null,
                      addedNaturalHolder: type === HolderType.NATURAL_PERSON ? details : null,
                    }
                  : holder,
              ),
            })),
          },
        },
      }
    },
    [String(UpdateProformaIssuanceHolderFailure)]: (state: State): State => {
      return {
        ...state,
        proformaIssuance: {
          ...state.proformaIssuance,
          loading: { ...state.proformaIssuance.loading, saveHolder: false },
        },
      }
    },
    [String(SetActiveProformaIssuanceHolder)]: (state: State, action: Action<string>): State => ({
      ...state,
      proformaIssuance: {
        ...state.proformaIssuance,
        selectedHolderTempId: action.payload,
      },
    }),
    [String(UnsetActiveProformaIssuanceHolder)]: (state: State): State => ({
      ...state,
      proformaIssuance: {
        ...state.proformaIssuance,
        selectedHolderTempId: null,
      },
    }),
    [String(SetLoading)]: (state: State): State => {
      return { ...state, loading: true }
    },
    [String(SetLoaded)]: (state: State): State => {
      return { ...state, loading: false }
    },
  },
  new State(),
)

export { State as IssuanceViewState }
