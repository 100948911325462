import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'
import Tooltip, { ITooltipProps } from '../tooltip/Tooltip'

import Menu from './icons/menu.svg'
import Mail from './icons/mail.svg'
import LogoElement from './icons/logo-element.svg'
import Logo from './icons/logo.svg'
import Gear from './icons/gear.svg'
import Folder from './icons/folder.svg'
import Plus from './icons/plus.svg'
import Search from './icons/search.svg'
import ArrowUp from './icons/arrowUp.svg'
import ArrowDown from './icons/arrowDown.svg'
import ArrowRight from './icons/arrowRight.svg'
import ArrowLeft from './icons/arrowLeft.svg'
import Cross from './icons/cross.svg'
import Pdf from './icons/pdf.svg'
import Sort from './icons/sort.svg'
import SortUp from './icons/sortUp.svg'
import SortDown from './icons/sortDown.svg'
import Checked from './icons/checked.svg'
import Error from './icons/error.svg'
import Am from './icons/am.svg'
import Pm from './icons/pm.svg'
import Visible from './icons/visible.svg'
import Invisible from './icons/invisible.svg'
import GuardTime from './icons/guardtime.svg'
import RoundPlus from './icons/roundPlus.svg'
import Calendar from './icons/calendar.svg'
import Transfer from './icons/transfer.svg'
import Pen from './icons/pen.svg'
import Download from './icons/download.svg'
import AnimatingArrows from './icons/animating-arrows.svg'
import Clock from './icons/clock.svg'
import Broken from './icons/broken.svg'
import Info from './icons/info.svg'
import AppleStore from './icons/apple_store.svg'
import PlayStore from './icons/play_store.svg'
import PrimeSigner from './icons/primeSigner.svg'
import PrimeSignerSmall from './icons/primeSignerSmall.svg'
import PrimeSignerLogo from './icons/primeSignerLogo.svg'
import Avatar from './icons/avatar.svg'
import Phone from './icons/phone.svg'
import Reload from './icons/reload_ico.svg'

import AlignCenter from './icons/align-center.svg'
import AlignJustify from './icons/align-justify.svg'
import AlignLeft from './icons/align-left.svg'
import AlignRight from './icons/align-right.svg'
import Bold from './icons/bold.svg'
import Italic from './icons/italic.svg'
import Link from './icons/link.svg'
import OrderedList from './icons/orderedlist.svg'
import Underline from './icons/underline.svg'
import UnorderedList from './icons/unorderedlist.svg'
import Paragraph from './icons/paragraph.svg'
import HeadingOne from './icons/heading-one.svg'
import HeadingTwo from './icons/heading-two.svg'
import HeadingThree from './icons/heading-three.svg'
import HeadingFour from './icons/heading-four.svg'
import HeadingFive from './icons/heading-five.svg'
import HeadingSix from './icons/heading-six.svg'
import Bulb from './icons/bulb.svg'
import Flag from './icons/flag.svg'
import Delete from './icons/delete.svg'
import Check from './icons/check.svg'
import GlobalError from './icons/global-error.svg'

import './Icon.scss'

// list of all the icons
export enum ICONS {
  MAIL = Mail.id,
  LOGO_ELEMENT = LogoElement.id,
  LOGO = Logo.id,
  GEAR = Gear.id,
  FOLDER = Folder.id,
  PLUS = Plus.id,
  SEARCH = Search.id,
  ARROW_UP = ArrowUp.id,
  ARROW_DOWN = ArrowDown.id,
  ARROW_RIGHT = ArrowRight.id,
  ARROW_LEFT = ArrowLeft.id,
  CROSS = Cross.id,
  PDF = Pdf.id,
  SORT = Sort.id,
  SORT_UP = SortUp.id,
  SORT_DOWN = SortDown.id,
  CHECKED = Checked.id,
  ERROR = Error.id,
  AM = Am.id,
  PM = Pm.id,
  VISIBLE = Visible.id,
  INVISIBLE = Invisible.id,
  GUARD_TIME = GuardTime.id,
  ROUND_PLUS = RoundPlus.id,
  CALENDAR = Calendar.id,
  TRANSFER = Transfer.id,
  PEN = Pen.id,
  DOWNLOAD = Download.id,
  ANIMATING_ARROWS = AnimatingArrows.id,
  CLOCK = Clock.id,
  BROKEN = Broken.id,
  INFO = Info.id,
  MENU = Menu.id,
  APPLE_STORE = AppleStore.id,
  PLAY_STORE = PlayStore.id,
  PRIME_SIGNER = PrimeSigner.id,
  PRIME_SIGNER_SMALL = PrimeSignerSmall.id,
  PRIME_SIGNER_LOGO = PrimeSignerLogo.id,
  AVATAR = Avatar.id,
  PHONE = Phone.id,
  RELOAD = Reload.id,
  ALIGN_CENTER = AlignCenter.id,
  ALIGN_JUSTIFY = AlignJustify.id,
  ALIGN_LEFT = AlignLeft.id,
  ALIGN_RIGHT = AlignRight.id,
  BOLD = Bold.id,
  ITALIC = Italic.id,
  LINK = Link.id,
  ORDERED_LIST = OrderedList.id,
  UNDERLINE = Underline.id,
  UNORDERED_LIST = UnorderedList.id,
  PARAGRAPH = Paragraph.id,
  HEADING_ONE = HeadingOne.id,
  HEADING_TWO = HeadingTwo.id,
  HEADING_THREE = HeadingThree.id,
  HEADING_FOUR = HeadingFour.id,
  HEADING_FIVE = HeadingFive.id,
  HEADING_SIX = HeadingSix.id,
  BULB = Bulb.id,
  FLAG = Flag.id,
  DELETE = Delete.id,
  CHECK = Check.id,
  GLOBAL_ERROR = GlobalError.id,
}
export interface IIconProps {
  /**
   * Use ICONS constant in Icon.tsx
   */
  name: ICONS
  /**
   * Custom class
   */
  className?: string
  /**
   * size of icon.
   */
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
  /**
   * OnClick handler.
   */
  onClick?: (event: React.MouseEvent<SVGSVGElement>) => void
  /**
   * onFocus callback handler.
   */
  onFocus?: (event: React.FocusEvent<SVGSVGElement>) => void
  /**
   * onBlur callback handler.
   */
  onBlur?: (event: React.FocusEvent<SVGSVGElement>) => void
  /**
   * Tooltip information.
   */
  tooltip?: ITooltipProps
}

class Icon extends BaseComponent<IIconProps> {
  constructor(props: IIconProps) {
    super(props)
  }

  BEM(): string {
    const classArray = ['icon']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.size) {
      classArray.push(`icon--${this.props.size}`)
    }

    if (this.props.name) {
      classArray.push(`icon-${String(this.props.name).toLowerCase()}`)
    }

    if (this.props.onClick || this.props.tooltip) {
      classArray.push('icon--clickable')
    }

    return classArray.join(' ')
  }

  public render() {
    return (
      <>
        <svg
          className={this.BEM()}
          onClick={this.props.onClick}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          data-tip
          data-for={this.props.tooltip ? this.props.tooltip.id : undefined}
        >
          <use xlinkHref={`#${this.props.name}`} />
        </svg>
        {this.props.tooltip && <Tooltip {...this.props.tooltip} />}
      </>
    )
  }
}

export default Icon
