import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'
import ReactTooltip, { TooltipProps } from 'react-tooltip'

import './Tooltip.scss'

export interface ITooltipProps extends TooltipProps {
  /**
   * Id of tooltip
   */
  id: string
  /**
   * header of the tooltip
   */
  header?: string
  /**
   * Body of the tooltip
   */
  body?: string | JSX.Element | JSX.Element[]
}

class Tooltip extends BaseComponent<ITooltipProps> {
  static defaultProps: Partial<ITooltipProps> = {
    event: 'click',
    globalEventOff: 'click',
    effect: 'solid',
  }

  doubleUpdatePosition(ref: any) {
    ref.updatePosition()
    ref.updatePosition()
  }

  tooltipRef: any = React.createRef()

  BEM(): string {
    const classArray = ['tooltip']

    return classArray.join(' ')
  }

  public render(): JSX.Element {
    const Elem = this.props.wrapper || 'div'
    return (
      <ReactTooltip
        {...this.props}
        ref={this.tooltipRef}
        afterShow={() => this.doubleUpdatePosition(this.tooltipRef.current)}
        className={this.BEM()}
      >
        {this.props.header && <h3 className="tooltip__header text-bold">{this.props.header}</h3>}
        <Elem className="tooltip__body">{this.props.body}</Elem>
      </ReactTooltip>
    )
  }
}

export default Tooltip
