import * as React from 'react'
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'

import { getApplicationEnvironment } from './applicationEnvironment'

const injected = injectedModule()
const appEnv = getApplicationEnvironment()
const infuraApiKey =
  appEnv === 'PRODUCTION' ? process.env.REACT_APP_INFURA_API_KEY_PROD : process.env.REACT_APP_INFURA_API_KEY_TEST

const ETH_MAINNET_RPC = `https://mainnet.infura.io/v3/${infuraApiKey}`
const ETH_ROPSTEN_RPC = `https://ropsten.infura.io/v3/${infuraApiKey}`

export const BlockNativeOnboard = Onboard({
  wallets: [injected],
  chains: [
    appEnv === 'PRODUCTION'
      ? {
          id: '0x1',
          token: 'ETH',
          label: 'Ethereum Mainnet',
          rpcUrl: ETH_MAINNET_RPC,
        }
      : {
          id: '0x3',
          token: 'tROP',
          label: 'Ethereum Ropsten Testnet',
          rpcUrl: ETH_ROPSTEN_RPC,
        },
  ],
  appMetadata: {
    name: 'StockTreasury',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1" height="1"></svg>`,
    description: 'Digital Stock Ledger',
  },
})
