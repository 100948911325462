import * as React from 'react'

import { BaseStatefulComponent } from 'components/BaseStatefulComponent'
import Button from 'components/ui/button/Button'
import { paths } from 'common/router/routePaths'
import {
  LedgerWithProfilesDto,
  ProfileDto,
  ProfileRole,
  SelectProfile$Request,
  WhoAmI$Response$CurrentProfile,
} from 'services/api.types'

import './SideNav.scss'
import { withTranslation, WithTranslation } from 'react-i18next'

interface IProfileDetails extends ProfileDto {
  ledgerName: string
}

export interface ISideNavDefaultProps {
  /**
   * Is sidenav open
   */
  open: boolean
  /**
   * Toggle navBar callback handler
   */
  toggleNavBar: () => void
  /**
   * User ledgers
   */
  userLedgers: LedgerWithProfilesDto[]
  /**
   *  Select user profile callback handler
   */
  selectProfile: (payload: SelectProfile$Request) => void
  /**
   * Currently selected profile
   */
  currentProfile: WhoAmI$Response$CurrentProfile
  /**
   *  Logout callback handler
   */
  logOut: () => void
  /**
   * Disabled menu items
   */
  disabledItems: string[]
  /**
   * Is onbboarding completed or not
   */
  isOnboardingCompleted: boolean
  /**
   * User's full name to display
   */
  user: string
  /**
   * Company name to display
   */
  company: string
  /**
   * Close function for mobile navigator modal
   */
  closeMobileNav: () => void
}

export type ISideNavProps = ISideNavDefaultProps & WithTranslation

export interface ISideNavState {
  node: any
  profilesOpen: boolean
}

class SideNav extends BaseStatefulComponent<ISideNavProps, ISideNavState> {
  constructor(props: ISideNavProps) {
    super(props)

    this.state = {
      node: null,
      profilesOpen: false,
    }
  }

  componentDidUpdate(prevProps: ISideNavProps) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        document.addEventListener('click', this.handleClick, false)
      } else {
        document.removeEventListener('click', this.handleClick, false)
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false)
  }

  handleClick = (e: any) => {
    if (this.state.node.contains(e.target)) {
      return
    }

    this.props.toggleNavBar()
  }

  BEM(): string {
    const classArray = ['side-nav']

    if (this.props.open) {
      classArray.push('side-nav--open')
    }

    return classArray.join(' ')
  }

  static getRole(role: ProfileRole): string {
    if (role === ProfileRole.LEDGER_ADMIN) {
      return 'Admin'
    } else if (role === ProfileRole.HOLDER) {
      return 'Holder'
    }
    return ''
  }

  renderCurrentProfile() {
    const { t } = this.props
    return (
      <section className="profile">
        <h2>{this.props.company}</h2>
        <p>
          {t('label-signed-in')} <strong>{this.props.user}</strong>
        </p>
        <p>
          {t('label-role')}{' '}
          <strong>
            {this.props.currentProfile.role === ProfileRole.LEDGER_ADMIN
              ? t('profile-role-admin')
              : t('profile-role-holder')}
          </strong>
        </p>
        {this.props.currentProfile.role !== ProfileRole.LEDGER_ADMIN && this.props.currentProfile.holder.legalEntity && (
          <p>
            {t('label-stock-holder')} <strong>{this.props.currentProfile.holder.name}</strong>
          </p>
        )}
      </section>
    )
  }

  render(): JSX.Element {
    const { t, userLedgers } = this.props
    return (
      <div className={this.BEM()} ref={node => this.setState({ node })}>
        <div className="side-nav__inner">
          {this.props.currentProfile && this.renderCurrentProfile()}
          <section className="links">
            <ul>
              {userLedgers && userLedgers.reduce((acc, ledger) => (acc += ledger.profiles.length), 0) > 1 && (
                <li className="links__item">
                  <Button
                    text={t('profile-btn')}
                    link={true}
                    url={paths.profiles}
                    variant="link-accent-inverted"
                    onClick={() => {
                      this.props.toggleNavBar()
                      this.props.closeMobileNav()
                    }}
                    block={false}
                  />
                </li>
              )}
              <li className="links__item">
                <Button
                  text={t('settings-btn')}
                  link={true}
                  url={paths.settings}
                  variant="link-accent-inverted"
                  onClick={() => {
                    this.props.toggleNavBar()
                    this.props.closeMobileNav()
                  }}
                  block={false}
                  disabled={this.props.disabledItems.includes(paths.settings)}
                />
              </li>
              <li className="links__item">
                <Button
                  onClick={() => {
                    this.props.logOut()
                    this.props.toggleNavBar()
                    this.props.closeMobileNav()
                  }}
                  link={true}
                  block={false}
                  variant="link-accent-inverted"
                  text={t('log-out-btn')}
                />
              </li>
            </ul>
          </section>
        </div>
      </div>
    )
  }
}

export default withTranslation('sideNav')(SideNav)
