import * as React from 'react'
import { BaseComponent } from '../../../BaseComponent'
import Icon, { ICONS } from '../../icon/Icon'
import { ITooltipProps } from '../../tooltip/Tooltip'

import './Label.scss'

export interface ILabelProps {
  /**
   * The label text.
   */
  label: string
  /**
   * ID attribute.
   */
  id?: string
  /**
   * Is it required.
   */
  isRequired?: boolean
  /**
   * Additional classes.
   */
  className?: string
  /**
   * size of input. When input size is < 'md', then required = *
   */
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'none'
  /**
   * Should colors be inverted
   */
  invert?: boolean
  /**
   * Tooltip information.
   */
  tooltip?: ITooltipProps
}

class Label extends BaseComponent<ILabelProps> {
  constructor(props: ILabelProps) {
    super(props)
  }

  BEM(): string {
    const classArray = ['label']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.invert) {
      classArray.push('label--inverted')
    }

    return classArray.join(' ')
  }

  render(): JSX.Element {
    return (
      <label className={this.BEM()} htmlFor={this.props.id}>
        <span className="label__text">
          {this.props.label}&nbsp;
          {this.props.isRequired && <span className="label__required">*</span>}
          {this.props.tooltip && (
            <Icon className="label__tooltip" name={ICONS.INFO} size="sm" tooltip={this.props.tooltip} />
          )}
        </span>
      </label>
    )
  }
}

export default Label
