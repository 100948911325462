import { createAction } from 'redux-actions'
import { IDrafts } from './DashboardViewReducer'

const ns = 'dashboard/'

export const GetDashboard = createAction(`${ns}DASHBOARD_DATA`)
export const GetDashboardSuccess = createAction(`${ns}DASHBOARD_DATA_SUCCESS`)
export const GetDashboardFail = createAction(`${ns}DASHBOARD_DATA_FAIL`)

export const GetDashboardDataOnly = createAction(`${ns}DASHBOARD_DATA_ONLY`)
export const GetDashboardDataOnlySuccess = createAction(`${ns}DASHBOARD_DATA_ONLY_SUCCESS`)
export const GetDashboardDataOnlyFail = createAction(`${ns}DASHBOARD_DATA_ONLY_FAIL`)

export const AddDashboardNotification = createAction(`${ns}ADD_NOTIFICATION`)
export const ClearDashboardNotification = createAction(`${ns}CLEAR_NOTIFICATION`)

export const GetDrafts = createAction(`${ns}DASHBOARD_DRAFTS`)
export const GetDraftsSuccess = createAction(`${ns}DASHBOARD_DRAFTS_SUCCESS`, (payload: IDrafts) => payload)
export const GetDraftsFailure = createAction(`${ns}DASHBOARD_DRAFTS_FAILURE`)

export const RolesDisabled = createAction(`${ns}ROLES_DISABLED`)

export const SignLedgerContract = createAction(`${ns}SIGN_LEDGER_CONTRACT`)
export const SignLedgerContractFileStarted = createAction(`${ns}SIGN_LEDGER_CONTRACT_FILE_STARTED`)
export const SignLedgerContractFileFinished = createAction(`${ns}SIGN_LEDGER_CONTRACT_FILE_FINISHED`)

export const GenerateContractFile = createAction(`${ns}GENERATE_CONTRACT_FILE`)
export const GenerateContractFileSuccess = createAction(`${ns}GENERATE_CONTRACT_FILE_SUCCESS`)
export const GenerateContractFileFail = createAction(`${ns}GENERATE_CONTRACT_FILE_FAIL`)

export const ShowRegistrationSuccess = createAction(`${ns}SHOW_REGISTRATION_SUCCESS`)
