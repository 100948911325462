import { Action, handleActions } from 'redux-actions'
import {
  GetStockList,
  GetStockListFail,
  GetStockListSuccess,
  GetUserStockList,
  GetUserStockListFail,
  GetUserStockListSuccess,
} from './stocksAction'
import { MyAccountStockListDto$StockItem, StockListDto } from 'services/api.types'

class State {
  stocks: StockListDto[] = []
  usersStocks: MyAccountStockListDto$StockItem[] = []
  stockListLoaded: boolean = false
  stockListLoading: boolean = false
}

export default handleActions<State, any>(
  {
    [String(GetStockList)]: (state: State): State => {
      return { ...state, stockListLoading: true, stockListLoaded: false }
    },
    [String(GetStockListSuccess)]: (state: State, action: Action<StockListDto[]>): State => {
      return {
        ...state,
        stocks: action.payload,
        stockListLoading: false,
        stockListLoaded: true,
      }
    },
    [String(GetStockListFail)]: (state: State): State => {
      return { ...state, stockListLoading: false, stockListLoaded: true }
    },
    [String(GetUserStockList)]: (state: State): State => {
      return { ...state, stockListLoading: true, stockListLoaded: false }
    },
    [String(GetUserStockListSuccess)]: (state: State, action: Action<MyAccountStockListDto$StockItem[]>): State => {
      return { ...state, usersStocks: action.payload, stockListLoading: false, stockListLoaded: true }
    },
    [String(GetUserStockListFail)]: (state: State): State => {
      return { ...state, stockListLoading: false, stockListLoaded: true }
    },
  },
  new State(),
)

export { State as StocksState }
