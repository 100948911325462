import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { BaseComponent } from 'components/BaseComponent'

import {
  AddCredential$Request,
  ChangePassword$Request,
  CredentialId,
  CredentialType,
  GetUserCredentials$Response,
} from 'services/api.types'
import Credentials from './children/credentials/Credentials'
import ChangePassword from '../change-password/ChangePassword'
import Loader from 'components/ui/loader/Loader'
import { Dispatchable0, Dispatchable1 } from 'redux-dispatchers'
import { formFieldTypes } from 'components/ui/form/textfield/TextField'
import Icon, { ICONS } from 'components/ui/icon/Icon'

export enum CredentialTabs {
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  APP = 'APP',
}

export interface IStateProps {
  userCredentials: GetUserCredentials$Response
  loading: boolean
  loaded: boolean
  selectedTab: CredentialTabs
  style: formFieldTypes
}

export interface IDispatchProps {
  GetUserCredentials: Dispatchable0
  AddCredential: Dispatchable1<AddCredential$Request>
  ReplaceVerifiedApp: Dispatchable1<CredentialId>
  RemoveCredential: Dispatchable1<CredentialId>
  ChangePassword: Dispatchable1<ChangePassword$Request>
  ReTriggerCredentialVerification: Dispatchable1<{ id: CredentialId; type: CredentialType }>
}

type ICredentialsViewProps = IStateProps & IDispatchProps & WithTranslation

class CredentialsView extends BaseComponent<ICredentialsViewProps> {
  componentDidMount() {
    this.props.GetUserCredentials()
  }

  public render() {
    const {
      t,
      loaded,
      loading,
      userCredentials,
      RemoveCredential,
      AddCredential,
      ReTriggerCredentialVerification,
      ReplaceVerifiedApp,
    } = this.props
    const { emails, phones, keys } = userCredentials

    if (!loaded || loading) {
      return <Loader />
    }

    return (
      <>
        <h2 className="credentials-block__heading">{t('tab-email-label')}</h2>
        <Credentials
          credentials={emails}
          removeCredential={(id: string) => RemoveCredential(id)}
          addCredential={value => AddCredential({ value, type: CredentialType.EMAIL })}
          reTriggerVerification={(id: string) => ReTriggerCredentialVerification({ id, type: CredentialType.EMAIL })}
          maxAllowed={2}
          inputLabel={t('common:input-email-label')}
          inputId="email"
          addNewText={t('add-email-text')}
          type={CredentialType.EMAIL}
          reTriggerText={t('email-re-trigger-text')}
          style={this.props.style}
        />

        <h2 className="credentials-block__heading">{t('tab-phone-label')}</h2>
        <Credentials
          credentials={phones}
          removeCredential={(id: string) => RemoveCredential(id)}
          addCredential={value => AddCredential({ value, type: CredentialType.PHONE_NUMBER })}
          reTriggerVerification={(id: string) =>
            ReTriggerCredentialVerification({
              id,
              type: CredentialType.PHONE_NUMBER,
            })
          }
          inputLabel={t('common:input-phone-label')}
          inputId="phone-number"
          addNewText={t('add-phone-text')}
          type={CredentialType.PHONE_NUMBER}
          reTriggerText={t('phone-re-trigger-text')}
          style={this.props.style}
        />

        <h2 className="credentials-block__heading">
          {t('tab-app-label')}
          <Icon
            name={ICONS.INFO}
            size="md"
            tooltip={{
              id: 'credentials-add-app-tooltip',
              body: t('add-app-tooltip-text'),
              place: 'right',
              globalEventOff: 'mouseout',
              event: 'mouseover',
              eventOff: 'mouseout',
            }}
          />
        </h2>
        <Credentials
          credentials={keys}
          addCredential={value => ReplaceVerifiedApp(value)}
          reTriggerVerification={(id: string) =>
            ReTriggerCredentialVerification({
              id,
              type: CredentialType.APP,
            })
          }
          inputLabel={t('common:input-app-label')}
          inputId="app-key"
          addNewText={t('add-app-text')}
          type={CredentialType.APP}
          reTriggerText={t('app-re-trigger-text')}
          style={this.props.style}
        />

        <h2 className="credentials-block__heading">{t('heading-change-password')}</h2>
        <ChangePassword
          className="change-password"
          changePassword={values => {
            this.props.ChangePassword(values)
            window.scrollTo(0, 0)
          }}
        />
      </>
    )
  }
}

export default withTranslation('settingsCredentials')(CredentialsView)
