import { Action, handleActions } from 'redux-actions'
import { addNotification, clearNotification, removeNotification } from './NotificationActions'
import { NotificationType } from 'components/ui/notification/Notification'

export interface INotification {
  id?: number
  description: string
  code?: string
  translationKey?: string
  type: NotificationType
  allowHtml?: boolean
}

class State {
  notifications: INotification[] = []
}

export default handleActions<State, any>(
  {
    [String(addNotification)]: (state: State, action: Action<INotification>): State => {
      const newNotification = {
        ...action.payload,
        id: action.payload.id || new Date().valueOf(),
        allowHtml: action.payload.allowHtml || false,
      }

      return {
        ...state,
        notifications: [newNotification],
      }
    },
    [String(clearNotification)]: (state: State): State => {
      return {
        ...state,
        notifications: [],
      }
    },
    [String(removeNotification)]: (state: State, action: Action<number>): State => {
      return {
        ...state,
        notifications: state.notifications.filter(i => i.id !== action.payload),
      }
    },
  },

  new State(),
)

export { State as NotificationState }
