import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'

import './GroupItems.scss'

export interface IGroupItemProps {
  /**
   * Custom class
   */
  className?: string
  /**
   * Max size of groupItem
   */
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'ultra-xl'
  /**
   * Grow element as much as possible
   */
  flow?: boolean
  /**
   * Align content
   */
  valign?: 'center' | 'baseline' | 'top' | 'bottom'
  /**
   * Justify content
   */
  align?: 'center' | 'space-between' | 'left' | 'right' | 'flow'
}

export default class GroupItem extends BaseComponent<IGroupItemProps> {
  BEM() {
    const classArray = ['group-item']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.align) {
      classArray.push('group-item--' + this.props.align)
    }

    if (this.props.size) {
      classArray.push(`group-item--${this.props.size}`)
    }

    if (this.props.valign) {
      classArray.push('group-item--v-' + this.props.valign)
    }

    if (this.props.flow) {
      classArray.push('group-item--flow')
    }

    return classArray.join(' ')
  }

  render(): JSX.Element {
    return <div className={this.BEM()}>{this.props.children}</div>
  }
}
