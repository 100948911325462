import { Action, handleActions } from 'redux-actions'
import { GetTransferRestrictions$Response, SaveTransferRestrictions$Request } from 'services/api.types'
import {
  GetTransferRestrictions,
  GetTransferRestrictionsFailure,
  GetTransferRestrictionsSuccess,
  SaveTransferRestrictions,
  SaveTransferRestrictionsFailure,
  SaveTransferRestrictionsSuccess,
} from './LedgerPreferences.actions'

class State {
  restrictions: GetTransferRestrictions$Response = {
    holderToHolderTransfersDisabled: true,
  }
  getTransferRestrictionsLoading: boolean = false
  saveTransferRestrictionsLoading: boolean = false
}

export default handleActions<State, any>(
  {
    [String(GetTransferRestrictions)]: (state: State): State => {
      return { ...state, getTransferRestrictionsLoading: true }
    },
    [String(GetTransferRestrictionsSuccess)]: (
      state: State,
      action: Action<GetTransferRestrictions$Response>,
    ): State => {
      return {
        ...state,
        getTransferRestrictionsLoading: false,
        restrictions: {
          ...state.restrictions,
          ...action.payload,
        },
      }
    },
    [String(GetTransferRestrictionsFailure)]: (state: State): State => {
      return {
        ...state,
        getTransferRestrictionsLoading: false,
      }
    },
    [String(SaveTransferRestrictions)]: (state: State): State => {
      return { ...state, saveTransferRestrictionsLoading: true }
    },
    [String(SaveTransferRestrictionsSuccess)]: (
      state: State,
      action: Action<SaveTransferRestrictions$Request>,
    ): State => {
      return {
        ...state,
        saveTransferRestrictionsLoading: false,
        restrictions: {
          ...state.restrictions,
          holderToHolderTransfersDisabled: action.payload.disableHolderToHolderTransfers,
        },
      }
    },
    [String(SaveTransferRestrictionsFailure)]: (state: State): State => {
      return {
        ...state,
        saveTransferRestrictionsLoading: false,
      }
    },
  },
  new State(),
)

export { State as LedgerPreferencesViewState }
