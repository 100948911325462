import { Action, handleActions } from 'redux-actions'
import { INotificationProps } from 'components/ui/notification/Notification'
import {
  ClearVerificationData,
  SetVerificationUrls,
  UpdateVerificationCode,
  VerificationLoading,
} from './VerificationViewActions'

class State {
  successUrl: string = ''
  code?: string = ''
  failUrl?: string = ''
  cancelUrl?: string = ''
  title?: string = ''
  notification?: INotificationProps = null
  loading?: boolean = false

  constructor(props?: State) {
    // override state that comes from the component
    if (props) {
      this.title = props.title || ''
      this.notification = props.notification || null
      this.successUrl = props.successUrl || ''
      this.failUrl = props.failUrl || ''
      this.cancelUrl = props.cancelUrl || ''
    }
  }
}

export default handleActions<State, any>(
  {
    [String(VerificationLoading)]: (state: State): State => {
      return {
        ...state,
        loading: true,
      }
    },
    [String(SetVerificationUrls)]: (state: State, action: Action<State>): State => {
      return new State(action.payload)
    },
    [String(UpdateVerificationCode)]: (state: State, action: Action<string>): State => {
      return {
        ...state,
        code: action.payload,
      }
    },
    [String(ClearVerificationData)]: (state: State): State => {
      return new State()
    },
  },
  new State(),
)

export { State as VerificationViewState }
