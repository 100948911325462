import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'

import './Loader.scss'

export interface ILoaderProps {
  /**
   * Size of the loader.
   */
  size?: string
  /**
   * Border width of the loader.
   */
  borderWidth?: string
  /**
   * className
   */
  className?: string
}

class Loader extends BaseComponent<ILoaderProps> {
  constructor(props: ILoaderProps) {
    super(props)
  }

  BEM(): string {
    const classArray = ['loader']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  getStyles() {
    let styles = {}
    styles = this.props.size ? { width: this.props.size, height: this.props.size } : styles
    styles = this.props.borderWidth ? { ...styles, borderWidth: this.props.borderWidth } : styles
    return styles
  }

  public render() {
    return <div className={this.BEM()} style={this.props.size || this.props.borderWidth ? this.getStyles() : null} />
  }
}

export default Loader
