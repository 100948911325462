import { createAction } from 'redux-actions'
import { IIssuanceDraft } from './IssuanceViewReducer'

const ns = 'issuance/'

export const UpdateStockClass = createAction(`${ns}UPDATE_STOCK_CLASS`)

export const SaveIssuanceDraft = createAction(`${ns}SAVE_ISSUANCE_DRAFT`, (payload: IIssuanceDraft) => payload)
export const SaveIssuanceDraftSuccess = createAction(
  `${ns}SAVE_ISSUANCE_DRAFT_SUCCESS`,
  (payload: IIssuanceDraft) => payload,
)
export const SaveIssuanceDraftFailure = createAction(`${ns}SAVE_ISSUANCE_DRAFT_FAILURE`)

export const ClearIssuanceDraft = createAction(`${ns}CLEAR_ISSUANCE_DRAFT`, (stockIds: string[]) => stockIds)
export const ClearIssuanceDraftSuccess = createAction(`${ns}CLEAR_ISSUANCE_DRAFT_SUCCESS`, (stockId: string) => stockId)
export const ClearIssuanceDraftFailure = createAction(`${ns}CLEAR_ISSUANCE_DRAFT_FAILURE`)

export const GetIssuanceDraft = createAction(`${ns}GET_ISSUANCE_DRAFT`, (stockId: string) => stockId)
export const GetIssuanceDraftSuccess = createAction(
  `${ns}GET_ISSUANCE_DRAFT_SUCCESS`,
  (payload: IIssuanceDraft) => payload,
)
export const GetIssuanceDraftFailure = createAction(`${ns}GET_ISSUANCE_DRAFT_FAILURE`)

export const CreateIssuance = createAction(`${ns}CREATE_ISSUANCE`)
export const CreateIssuanceSuccess = createAction(`${ns}CREATE_ISSUANCE_SUCCESS`)
export const CreateIssuanceFailure = createAction(`${ns}CREATE_ISSUANCE_FAILURE`)

export const CreateStockSeries = createAction(`${ns}CREATE_STOCK_SERIES`)
export const CreateStockSeriesSuccess = createAction(`${ns}CREATE_STOCK_SERIES_SUCCESS`)
export const CreateStockSeriesFailure = createAction(`${ns}CREATE_STOCK_SERIES_FAILURE`)

export const InitCreateStockClass = createAction(`${ns}INIT_CREATE_STOCK_CLASS`)
export const ConfirmCreateStockClass = createAction(`${ns}CONFIRM_CREATE_STOCK_CLASS`)
export const CreateStockClass = createAction(`${ns}CREATE_STOCK_CLASS`)
export const CreateStockClassSuccess = createAction(`${ns}CREATE_STOCK_CLASS_SUCCESS`)
export const CreateStockClassFailure = createAction(`${ns}CREATE_STOCK_CLASS_FAILURE`)

export const ConfirmAmendStockClassDetails = createAction(`${ns}CONFIRM_AMEND_STOCK_CLASS_DETAILS`)
export const ConfirmAmendStockClassDetailsSuccess = createAction(`${ns}CONFIRM_AMEND_STOCK_CLASS_DETAILS_SUCCESS`)
export const ConfirmAmendStockClassDetailsFailure = createAction(`${ns}CONFIRM_AMEND_STOCK_CLASS_DETAILS_FAILURE`)

export const ConfirmAmendStockSeriesDetails = createAction(`${ns}CONFIRM_AMEND_STOCK_SERIES_DETAILS`)
export const ConfirmAmendStockSeriesDetailsSuccess = createAction(`${ns}CONFIRM_AMEND_STOCK_SERIES_DETAILS_SUCCESS`)
export const ConfirmAmendStockSeriesDetailsFailure = createAction(`${ns}CONFIRM_AMEND_STOCK_SERIES_DETAILS_FAILURE`)

export const SetLoading = createAction(`${ns}SET_LOADING`)
export const SetLoaded = createAction(`${ns}SET_LOADED`)

// Called to redirect to NewStockHolder page with state(NewHolder or existing user).
export const AddHolderToIssuance = createAction(`${ns}ADD_HOLDER_TO_ISSUANCE`)

// Called to save Holder to Issuance Draft and redirect to IssueStock page.
export const SaveHolderToIssuance = createAction(`${ns}SAVE_HOLDER_TO_ISSUANCE`)

// Called to route user to stockDetails view from StockDetailsNew to confirm change
export const ConfirmStockDetails = createAction(`${ns}CONFIRM_STOCK_DETAILS`)
export const ConfirmSeriesDetails = createAction(`${ns}CONFIRM_SERIES_DETAILS`)

// Called to route user from stock amendment form to amendment confirmation view
export const AmendStockDetails = createAction(`${ns}AMEND_STOCK_DETAILS`)

// Proforma issuance
export const SaveProformaIssuanceDraft = createAction(`${ns}SAVE_PROFORMA_ISSUANCE_DRAFT`)
export const SaveProformaIssuanceDraftSuccess = createAction(`${ns}SAVE_PROFORMA_ISSUANCE_DRAFT_SUCCESS`)
export const SaveProformaIssuanceDraftFailure = createAction(`${ns}SAVE_PROFORMA_ISSUANCE_DRAFT_FAILURE`)
export const ClearProformaIssuanceDraft = createAction(`${ns}CLEAR_PROFORMA_ISSUANCE_DRAFT`)
export const ClearProformaIssuanceDraftSuccess = createAction(`${ns}CLEAR_PROFORMA_ISSUANCE_DRAFT_SUCCESS`)
export const ClearProformaIssuanceDraftFailure = createAction(`${ns}CLEAR_PROFORMA_ISSUANCE_DRAFT_FAILURE`)
export const GetProformaIssuanceDraft = createAction(`${ns}GET_PROFORMA_ISSUANCE_DRAFT`)
export const GetProformaIssuanceDraftSuccess = createAction(`${ns}GET_PROFORMA_ISSUANCE_DRAFT_SUCCESS`)
export const GetProformaIssuanceDraftFailure = createAction(`${ns}GET_PROFORMA_ISSUANCE_DRAFT_FAILURE`)
export const EditProformaIssuanceHolder = createAction(`${ns}EDIT_PROFORMA_ISSUANCE_HOLDER`)
export const SetActiveProformaIssuanceHolder = createAction(`${ns}SET_ACTIVE_PROFORMA_ISSUANCE_HOLDER`)
export const UnsetActiveProformaIssuanceHolder = createAction(`${ns}UNSERT_ACTIVE_PROFORMA_ISSUANCE_HOLDER`)
export const CreateProformaIssuanceHolder = createAction(
  `${ns}CREATE_PROFORMA_ISSUANCE_HOLDER`,
  (payload: any) => payload,
)
export const CreateProformaIssuanceHolderSuccess = createAction(
  `${ns}CREATE_PROFORMA_ISSUANCE_HOLDER_SUCCESS`,
  (payload: any) => payload,
)
export const CreateProformaIssuanceHolderFailure = createAction(`${ns}CREATE_PROFORMA_ISSUANCE_HOLDER_FAILURE`)
export const UpdateProformaIssuanceHolder = createAction(`${ns}UPDATE_PROFORMA_ISSUANCE_HOLDER`)
export const UpdateProformaIssuanceHolderSuccess = createAction(`${ns}UPDATE_PROFORMA_ISSUANCE_HOLDER_SUCCESS`)
export const UpdateProformaIssuanceHolderFailure = createAction(`${ns}UPDATE_PROFORMA_ISSUANCE_HOLDER_FAILURE`)
export const SubmitProformaIssuanceDraft = createAction(`${ns}SUBMIT_PROFORMA_ISSUANCE_DRAFT`)
export const ConfirmProformaIssuanceDraft = createAction(`${ns}CONFIRM_PROFORMA_ISSUANCE_DRAFT`)
export const ConfirmProformaIssuanceDraftSuccess = createAction(`${ns}CONFIRM_PROFORMA_ISSUANCE_DRAFT_SUCCESS`)
export const ConfirmProformaIssuanceDraftFailure = createAction(`${ns}CONFIRM_PROFORMA_ISSUANCE_DRAFT_FAILURE`)
