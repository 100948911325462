import * as React from 'react'
import * as Yup from 'yup'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Formik } from 'formik'

import { CredentialDto, CredentialId, CredentialType } from 'services/api.types'
import TextField, { formFieldTypes } from 'components/ui/form/textfield/TextField'
import { BaseComponent } from 'components/BaseComponent'
import GroupItems from 'components/ui/group-items/GroupItems'
import GroupItem from 'components/ui/group-items/GroupItem'
import Button from 'components/ui/button/Button'
import VALIDATIONS from 'common/constants/validation-rules'
import Placeholder from 'components/ui/placeholder/Placeholder'
import { ICONS } from 'components/ui/icon/Icon'
import { ITooltipProps } from 'components/ui/tooltip/Tooltip'

import './Credentials.scss'
import PhoneNumberInput from 'components/ui/form/phone-number-input/PhoneNumberInput'
import Helper from 'components/ui/form/helper/Helper'

export interface ICredentialsProps extends WithTranslation {
  /**
   * Added credentials
   */
  credentials: CredentialDto[]
  /**
   * Type of credentials (for validation of new credential)
   */
  type: CredentialType
  /**
   * Re-trigger button text
   */
  reTriggerText?: string
  /**
   * Input label
   */
  inputLabel: string
  /**
   * Add new button text
   */
  addNewText: string
  /**
   * Input id
   */
  inputId: string
  /**
   * Remove credential call handler
   */
  removeCredential?: (id: CredentialId) => void
  /**
   * Add Credential call handler
   */
  addCredential: (value: string) => void
  /**
   * Re-trigger credential verification call handler
   */
  reTriggerVerification?: (id: CredentialId) => void

  maxAllowed?: number
  /**
   * Custom class
   */
  className?: string
  /**
   * form field styles
   */
  style?: formFieldTypes
}

class Credentials extends BaseComponent<ICredentialsProps> {
  // create dynamic validation schema with based on form values
  private validationSchema = Yup.lazy(values => {
    let validationRules

    if (this.props.type === CredentialType.EMAIL) {
      validationRules = {
        value: VALIDATIONS.EMAIL.required(),
      }
    } else if (this.props.type === CredentialType.PHONE_NUMBER) {
      validationRules = {
        value: Yup.string().required(),
      }
    } else if (this.props.type === CredentialType.APP) {
      validationRules = {
        value: VALIDATIONS.APP_KEY.required(),
      }
    }

    return Yup.object().shape(validationRules)
  })

  constructor(props: ICredentialsProps) {
    super(props)
  }

  BEM(): string {
    const classArray = ['credentials']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  onSubmit(value: string, resetForm: any) {
    this.props.addCredential(value)
    resetForm()
  }

  renderRemove(credential: CredentialDto, index: number) {
    const verifiedCredentialsCount = this.props.credentials.filter(c => c.verified).length
    const credentialType = this.props.type
    const t = this.props.t

    const tooltipProps: ITooltipProps = {
      body: credentialType === CredentialType.EMAIL ? t('email-info-tooltip-text') : t('phone-info-tooltip-text'),
      place: 'right',
      event: 'mouseover',
      eventOff: 'mouseout',
      globalEventOff: 'mouseout',
      id: `${credentialType}-${index}`,
    }

    return (
      <td key={`${credentialType}-${index}`}>
        <Button
          text="Remove"
          link={true}
          variant={this.props.style !== formFieldTypes.LIGHT ? 'link' : 'link-inverted'}
          onClick={() => this.props.removeCredential(credential.id)}
          block={false}
          disabled={credential.verified && verifiedCredentialsCount <= 1}
          iconRight={credential.verified && verifiedCredentialsCount <= 1 ? ICONS.INFO : undefined}
          iconTooltip={tooltipProps}
        />
      </td>
    )
  }

  public render() {
    const t = this.props.t
    const maxCredentialsOfType = this.props.maxAllowed && this.props.credentials.length >= this.props.maxAllowed

    const emailAddButtonTooltipProps: ITooltipProps = {
      body: <span style={{ textTransform: 'none' }}>{t('max-credentials-of-type')}</span>,
      place: 'right',
      globalEventOff: 'mouseout',
      event: 'mouseover',
      eventOff: 'mouseout',
      id: 'max-credential-info',
    }

    return (
      <div className={this.BEM()}>
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            {this.props.credentials && this.props.credentials.length ? (
              this.props.credentials.map((credential, index) => (
                <tr key={index}>
                  <td>
                    <span className="credentials__value">
                      {this.props.type === CredentialType.PHONE_NUMBER && credential.value.match(/^\d/) ? '+' : ''}
                      {credential.value}
                    </span>
                  </td>
                  <td>
                    {credential.verified ? (
                      <span className="credentials__verified">{t('credential-verified')}</span>
                    ) : (
                      <span className="credentials__unverified">{t('credential-unverified')}</span>
                    )}
                  </td>
                  {this.props.removeCredential && this.renderRemove(credential, index)}
                  {this.props.reTriggerVerification && !credential.verified && (
                    <td>
                      <Button
                        text={this.props.reTriggerText}
                        onClick={() => this.props.reTriggerVerification(credential.id)}
                        link={true}
                        variant={this.props.style !== formFieldTypes.LIGHT ? 'link' : 'link-inverted'}
                        block={false}
                      />
                    </td>
                  )}
                </tr>
              ))
            ) : this.props.style !== formFieldTypes.NORMAL ? null : (
              <Placeholder icon={ICONS.FOLDER}>No data inserted</Placeholder>
            )}
          </tbody>
        </table>

        {this.props.type === CredentialType.EMAIL && this.props.credentials.length >= 2 && (
          <span>{t('max-emails-allowed')}</span>
        )}
        {((this.props.type === CredentialType.EMAIL && this.props.credentials.length < 2) ||
          this.props.type !== CredentialType.EMAIL) && (
          <Formik
            initialValues={{ value: '' }}
            validateOnBlur={true}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => this.onSubmit(values.value, resetForm)}
            validationSchema={this.validationSchema}
            render={({
              handleSubmit,
              values,
              errors,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              touched,
              submitForm,
            }) => (
              <GroupItems inline={this.props.style === formFieldTypes.LIGHT} valign="top">
                <GroupItem
                  className={
                    this.props.style === formFieldTypes.LIGHT
                      ? 'phone-number-item'
                      : 'credentials--no-padding phone-number-item'
                  }
                >
                  {this.props.type === CredentialType.PHONE_NUMBER ? (
                    <div className="react-phone-number-input">
                      <label className="label">
                        <span className="label__text"> {t('common:input-phone-label')}</span>
                      </label>
                      <PhoneNumberInput
                        id="phone-number"
                        label={this.props.inputLabel}
                        placeholder={'+1 (000) 000-0000'}
                        onChange={value => {
                          setFieldValue('value', value)
                        }}
                        value={values.value || ''}
                        isRequired
                        formFieldStyle={this.props.style}
                        autoFormat
                      />
                      {touched.value && errors.value ? (
                        <Helper type="error" text={t(errors.value)} />
                      ) : (
                        <Helper type="info" text={t('add-phone-area-code')} />
                      )}
                    </div>
                  ) : (
                    <TextField
                      id={this.props.inputId}
                      name={this.props.inputId}
                      label={this.props.inputLabel}
                      onChange={value => setFieldValue('value', value)}
                      onBlur={handleBlur}
                      value={values.value}
                      isInvalid={touched.value && !!errors.value}
                      helper={touched.value && errors.value ? { type: 'error', text: t(errors.value) } : null}
                      type={this.props.type === CredentialType.APP ? 'number' : 'text'}
                      {...(this.props.type === CredentialType.EMAIL && { filterRegexp: /\s/g })}
                      formatting={this.props.type === CredentialType.APP ? { decimalScale: 0 } : null}
                      pattern="[0-9]*"
                      style={this.props.style}
                    />
                  )}
                </GroupItem>
                <GroupItem className={this.props.style === formFieldTypes.LIGHT ? '' : 'credentials--no-padding'}>
                  <Button
                    variant={this.props.style !== formFieldTypes.LIGHT ? 'main' : 'alt'}
                    text={this.props.addNewText}
                    className="credentials__add"
                    disabled={maxCredentialsOfType}
                    onClick={submitForm}
                    iconRight={maxCredentialsOfType ? ICONS.INFO : undefined}
                    iconTooltip={maxCredentialsOfType ? emailAddButtonTooltipProps : undefined}
                  />
                </GroupItem>
              </GroupItems>
            )}
          />
        )}
      </div>
    )
  }
}

export default withTranslation('settingsCredentials')(Credentials)
