import { Action, handleActions } from 'redux-actions'
import { ClearSigningData, SetStatus, SetSigningUrls } from './SigningViewActions'
import {
  PollSignActionStatus$Response,
  PollSignActionStatus$SigningType,
  PollSignActionStatus$Status,
  SignActionId,
} from 'services/api.types'

class State {
  signActionId: SignActionId | null
  isInitial: boolean = false
  signedUrl: string = ''
  signedAction?: any = null
  cancelledUrl: string = ''
  cancelledAction: any = null
  expiredUrl?: string = ''
  expiredAction?: any = null
  deniedUrl?: string = ''
  deniedAction?: any = null
  cleanAction?: any = null // Used to clean LS draft
  title?: string = 'Creating New Blockchain'
  successTitle?: string = 'Blockchain Created'
  text?: string = 'Go to your app and sign to complete the action'
  successText?: string = 'Action verification received from application'
  status: PollSignActionStatus$Status = PollSignActionStatus$Status.WAITING_FOR_RESPONSE
  type: PollSignActionStatus$SigningType = null
  verificationCode: string = ''

  constructor(props?: State) {
    // override state that comes from the component
    if (props) {
      this.title = props.title || 'Creating New Blockchain'
      this.successTitle = props.successTitle || 'Blockchain Created'
      this.text = props.text || 'Go to your app and sign to complete the action'
      this.successText = props.successText || 'Action verification received from application'
      this.signActionId = props.signActionId || null
      this.isInitial = props.isInitial || false
      this.signedUrl = props.signedUrl || ''
      this.signedAction = props.signedAction || null
      this.cancelledUrl = props.cancelledUrl || ''
      this.cancelledAction = props.cancelledAction || null
      this.expiredUrl = props.expiredUrl || ''
      this.expiredAction = props.expiredAction || null
      this.deniedUrl = props.deniedUrl || ''
      this.deniedAction = props.deniedAction || null
      this.cleanAction = props.cleanAction || null
      this.status = PollSignActionStatus$Status.WAITING_FOR_RESPONSE
      this.verificationCode = ''
    }
  }
}

export default handleActions<State, any>(
  {
    [String(SetSigningUrls)]: (state: State, action: Action<State>): State => {
      return new State(action.payload)
    },
    [String(ClearSigningData)]: (): State => {
      return new State()
    },
    [String(SetStatus)]: (state: State, action: Action<PollSignActionStatus$Response>): State => {
      return {
        ...state,
        status: action.payload.status,
        type: action.payload.type,
        verificationCode: action.payload.verificationCode,
      }
    },
  },
  new State(),
)

export { State as SigningViewState }
