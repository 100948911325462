import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as stringify from 'json-stringify-safe'

import {
  GetStockData,
  GetStockDataFail,
  GetStockDataSuccess,
  SaveStockClass,
  ConfirmStockClass,
  GetStockFormPrefaceDataSuccess,
  GetStockFormPrefaceData,
  SaveStockFormPrefaceData,
  SaveStockFormPrefaceDataSuccess,
  GetStockFormPrefaceDataFailure,
  SaveStockFormPrefaceDataFailure,
} from './StockFormActions'
import api from 'services/Api.services'
import { ConfirmStockClass$Request, GetStockList$Response } from 'services/api.types'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { Action } from 'redux-actions'
import { GetDashboard } from 'views/dashboard/DashboardViewActions'
import { IStockFormFields, IStockFormTypes } from './StockFormFieldNames'
import { push } from 'connected-react-router'
import { paths } from 'common/router/routePaths'
import { NotificationType } from 'components/ui/notification/Notification'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { IStockFormPrefaceData } from 'views/settings/children/stock/StockFormReducer'
import DraftStorage from 'common/utils/draftStorage'

function* sagaGetStockData() {
  try {
    const StockData: GetStockList$Response = yield call(api.ledger.getStockList)
    yield put({ type: String(GetStockDataSuccess), payload: StockData })
  } catch (e) {
    yield put({ type: String(GetStockDataFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaSaveStockClass(action: Action<IStockFormTypes>) {
  try {
    const stocks = action.payload
    const mergedStockClasses: any = {
      stockClasses: [
        ...stocks.commonStock.map(stock => {
          return { ...stock }
        }),
        ...stocks.otherStock.map(stock => {
          return { ...stock }
        }),
        ...stocks.preferredStock.map(stock => {
          return { ...stock }
        }),
      ].filter(
        (stock: IStockFormFields) => stock.name && stock.totalNumberOfShares && (stock.noParValue || stock.parValue),
      ),
    }

    yield call(api.ledger.saveStockClass, mergedStockClasses)
    yield put({ type: String(GetDashboard) })

    yield put(push(paths.dashboard.root))
  } catch (e) {
    yield put({ type: String(GetStockDataFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaConfirmStockClass(action: Action<IStockFormTypes>) {
  try {
    const stocks = action.payload
    const mergedStockClasses: ConfirmStockClass$Request = {
      stockClasses: [
        ...stocks.commonStock.map(stock => {
          return { id: stock.id }
        }),
        ...stocks.otherStock.map(stock => {
          return { id: stock.id }
        }),
        ...stocks.preferredStock.map(stock => {
          return { id: stock.id }
        }),
      ],
    }

    yield call(api.ledger.confirmStockClass, mergedStockClasses)
    yield put({ type: String(GetDashboard) })

    yield put(push(paths.dashboard.root))
  } catch (e) {
    yield put({ type: String(GetStockDataFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaGetStockFormPrefaceData() {
  try {
    const data: IStockFormPrefaceData = yield call(DraftStorage.get, DraftStorageEnum.ONBOARDING_STOCK)
    yield put(GetStockFormPrefaceDataSuccess(data))
  } catch (e) {
    yield put(GetStockFormPrefaceDataFailure(e))
  }
}

function* sagaSaveStockFormPrefaceData(action: Action<IStockFormPrefaceData>) {
  try {
    const data = stringify(action.payload)
    yield call(DraftStorage.save, DraftStorageEnum.ONBOARDING_STOCK, data)
    yield put(SaveStockFormPrefaceDataSuccess())
  } catch (e) {
    yield put(SaveStockFormPrefaceDataFailure(e))
  }
}

export default function* StockFormSagas() {
  yield all([
    takeEvery(String(GetStockData), sagaGetStockData),
    takeEvery(String(SaveStockClass), sagaSaveStockClass),
    takeEvery(String(ConfirmStockClass), sagaConfirmStockClass),
    takeEvery(String(GetStockFormPrefaceData), sagaGetStockFormPrefaceData),
    takeEvery(String(SaveStockFormPrefaceData), sagaSaveStockFormPrefaceData),
  ])
}
