import { connect } from 'react-redux'
import { IGlobalState } from '../../../rootReducer'
// import { paths } from 'common/router/routePaths';
import Footer, { IFooterProps } from 'components/ui/footer/Footer'
import { authenticated } from 'common/auth/AuthSelectors'

export interface INavigationLink {
  /**
   * Display label
   */
  label: string
  /**
   * Link url
   */
  url: string
}

const linksArray: INavigationLink[] = [
  /*{
        label: 'Privacy Policy',
        url: paths.dashboard.root,
    },
    {
        label: 'Terms and Conditions',
        url: paths.dashboard.root,
    },
    {
        label: 'Support',
        url: paths.dashboard.root,
    },*/
  // todo :: currently footer content is too abstract to utilise
]

const mapStateToProps = (state: IGlobalState): IFooterProps => {
  return {
    links: linksArray,
    isLoggedIn: authenticated(state),
  }
}

export default connect(mapStateToProps)(Footer)
