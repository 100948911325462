import * as React from 'react'
import * as Loadable from 'react-loadable'
import Container from 'components/ui/container/Container'
import Loader from 'components/ui/loader/Loader'
import GroupItems from 'components/ui/group-items/GroupItems'
import Button from 'components/ui/button/Button'
import Background from 'components/ui/background/Background'
import Icon, { ICONS } from 'components/ui/icon/Icon'
import i18n from 'i18next'

import './RouterLoadable.scss'

const RouteLoading = (props: any) => {
  function refreshPage() {
    window.location.reload(false)
  }

  if (props.error) {
    return (
      <Background type="angle">
        <Container type="login">
          <GroupItems align="center" className="route-loading">
            <Icon name={ICONS.RELOAD} className="route-loading__broken" />
            <h2>{i18n.t('routerLoadable:error-loading')}</h2>
            <Button
              text={i18n.t('routerLoadable:retry-btn')}
              onClick={refreshPage}
              variant="alt"
              block={false}
              className="route-loading__button"
            />
          </GroupItems>
        </Container>
      </Background>
    )
  } else if (props.timedOut) {
    return (
      <Background type="angle">
        <Container type="login">
          <GroupItems align="center" className="route-loading">
            <h2>{i18n.t('routerLoadable:error-timed-out')}</h2>
            <Button
              text={i18n.t('routerLoadable:retry-btn')}
              onClick={refreshPage}
              variant="alt"
              block={false}
              className="route-loading__button"
            />
          </GroupItems>
        </Container>
      </Background>
    )
  } else if (props.pastDelay) {
    return (
      <Background type="angle">
        <Container type="login">
          <Loader size="3rem" borderWidth="7.5px" className="route-loading__loader" />
        </Container>
      </Background>
    )
  } else {
    return null
  }
}

export default function RouterLoadable(opts: any) {
  const delay: number = 300
  const timeout: number = 10000

  return Loadable({ loading: RouteLoading, delay, timeout, ...opts })
}
