import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { BaseStatefulComponent } from '../../../BaseStatefulComponent'
import Icon, { ICONS, IIconProps } from '../../icon/Icon'
import Label from '../label/Label'
import Helper, { IHelperProps } from '../helper/Helper'
import { default as NumberFormat, NumberFormatProps, NumberFormatValues } from 'react-number-format'
import { ITooltipProps } from '../../tooltip/Tooltip'

import './TextField.scss'
import Button, { IButtonProps } from '../../button/Button'

export enum formFieldTypes {
  NORMAL = 'NORMAL',
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export interface ITextFieldProps {
  /**
   * ID attribute.
   */
  id: string
  /**
   * The label value.
   */
  label?: string
  /**
   * The name
   */
  name?: string
  /**
   * Additional classes.
   */
  className?: string
  /**
   * Input autocomplete
   */
  autocomplete?: boolean
  /**
   * Max size of input
   */
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'none'
  /**
   * Pattern for setting the keyboard on mobile
   */
  pattern?: string
  /**
   * There are 3 styles:
   * normal: used in dark backgrounds, with white inputs and inverted labels.
   * light: used in light backgrounds, with white inputs and normal labels.
   * dark: used in dark background, with inverted inputs and labels.
   */
  style?: formFieldTypes
  /**
   * Placeholder.
   */
  placeholder?: string
  /**
   * onChange callback handler.
   */
  onChange?: (value: string) => void
  /**
   * onKeyDown callback handler.
   */
  onKeyDown?: (event: React.KeyboardEvent<any>) => void
  /**
   * onFocus callback handler.
   */
  onFocus?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  /**
   * onBlur callback handler.
   */
  onBlur?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  /**
   * Additional input attributes.
   */
  input?: React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
  /**
   * Regexp to filter out from value
   */
  filterRegexp?: RegExp
  /**
   * Disable text field.
   */
  isDisabled?: boolean
  /**
   * Invalid text field.
   */
  isInvalid?: boolean
  /**
   * Valid text field.
   */
  isValid?: boolean
  /**
   * Has additional info
   */
  hasInfo?: boolean
  /**
   * Show helper text.
   */
  helper?: IHelperProps
  /**
   * ReadOnly text field.
   */
  isReadOnly?: boolean
  /**
   * Required text field.
   */
  isRequired?: boolean
  /**
   * Force the text field to be visually focused.
   */
  isFocused?: boolean
  /**
   * The value of input.
   */
  value?: number | string
  /**
   * Is input textarea.
   */
  isTextarea?: boolean
  /**
   * Additional classes to input.
   */
  inputClass?: string
  /**
   * Input type.
   */
  type?: string
  /**
   * Name of the icon we want to show on the left.
   */
  iconLeft?: ICONS
  /**
   * Name of the icon we want to show on the right.
   */
  iconRight?: ICONS
  /**
   * Tooltip to show on icon.
   */
  iconTooltip?: ITooltipProps
  /**
   *  Is icon Clickable
   */
  isIconClickable?: boolean
  /**
   *  Function called when icon is clicked.
   */
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * Additional custom class for the icon we want to show.
   */
  iconClass?: string

  /**
   * Render additional content inside TextField.
   */
  contentRenderer?: () => JSX.Element
  /**
   * Formatting
   */
  formatting?: INumberFormatProps
  /**
   * Tooltip information.
   */
  tooltip?: ITooltipProps
  /**
   * Tooltip icon
   */
  tooltipIcon?: IIconProps
  /**
   * Tooltip placement
   */
  tooltipPlacement?: 'label' | 'right'
  /**
   * Allow submit on enter key press
   */
  submitOnEnter?: boolean
  /**
   * Button to append after input
   */
  button?: IButtonProps

  /**
   * Additional custom attribute to disable spellcheck
   */
  spellCheck?: boolean
  /**
   * Additional custom attribute to disable autocomplete on some browsers
   */
  list?: string
}

interface INumberFormatProps extends NumberFormatProps {
  returnvalue?: string
}

export interface ITextFieldState {
  /**
   * If input is focused
   */
  isFocused: boolean
  /**
   * Internal props state
   */
  former: {
    isFocused: boolean
  }
}

class TextField extends BaseStatefulComponent<ITextFieldProps, ITextFieldState> {
  static defaultProps: Partial<ITextFieldProps> = {
    id: '0',
    type: 'text',
    value: '',
    size: 'md',
    autocomplete: false,
    style: formFieldTypes.NORMAL,
    submitOnEnter: false,
  }

  input: any = React.createRef()

  constructor(props: ITextFieldProps) {
    super(props)

    this.state = {
      isFocused: props.isFocused,
      former: {
        isFocused: props.isFocused,
      },
    }
  }

  static getDerivedStateFromProps(nextProps: ITextFieldProps, prevState: ITextFieldState) {
    if (nextProps.isFocused !== prevState.former.isFocused) {
      return {
        isFocused: nextProps.isFocused,
        former: {
          isFocused: nextProps.isFocused,
        },
      }
    }

    return null
  }

  BEM(): string {
    const classArray = ['textfield']

    if (this.props.style === formFieldTypes.DARK) {
      classArray.push(`textfield--inverted`)
    }

    if (this.props.value) {
      classArray.push('is-dirty')
    }

    if (this.props.isDisabled) {
      classArray.push('is-disabled')
    }

    if (this.props.isReadOnly) {
      classArray.push('is-readonly')
    }

    if (this.props.isInvalid) {
      classArray.push('is-invalid')
    }

    if (this.props.size) {
      classArray.push(`textfield--${this.props.size}`)
    }

    if (this.props.isValid) {
      classArray.push('is-valid')
    }

    if (this.props.hasInfo) {
      classArray.push('has-info')
    }

    if (this.state.isFocused) {
      classArray.push('is-focused')
    }

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.iconRight) {
      classArray.push('icon-right')
    }

    if (this.props.iconLeft) {
      classArray.push('icon-left')
    }

    return classArray.join(' ')
  }

  inputBEM(): string {
    const classArray = ['textfield__input']

    if (this.props.inputClass) {
      classArray.push(this.props.inputClass)
    }

    return classArray.join(' ')
  }

  iconBEM(location: string): string {
    const classArray = ['textfield__button']

    classArray.push('textfield__button--' + location)

    if (this.props.iconClass) {
      classArray.push(this.props.iconClass)
    }

    if (this.props.isIconClickable) {
      classArray.push('clickable')
    }

    return classArray.join(' ')
  }

  getIcon(location: string): JSX.Element {
    let name: ICONS = null

    if (location === 'left' && this.props.iconLeft) {
      name = this.props.iconLeft
    }

    if (location === 'right' && this.props.iconRight) {
      name = this.props.iconRight
    }

    return (
      <button
        type="button"
        className={this.iconBEM(location)}
        onClick={(e: any) => {
          if (this.props.onIconClick) {
            this.props.onIconClick(e)
          }
          if (this.input.current) {
            this.input.current.focus()
          }
        }}
      >
        <Icon name={name} size="lg" className="textfield__button-icon" tooltip={this.props.iconTooltip} />
      </button>
    )
  }

  onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist()
    let value = (e.target as any).value
    if (this.props.onChange) {
      // remove disallowed characters
      if (this.props.filterRegexp) {
        value = value.replace(this.props.filterRegexp, '')
      }
      this.props.onChange(value)
    }
  }

  onFormattedChange = (values: NumberFormatValues, e: React.FormEvent<HTMLInputElement>): void => {
    e.persist()
    if (this.props.onChange) {
      this.props.formatting.returnvalue
        ? this.props.onChange(values[this.props.formatting.returnvalue])
        : this.props.onChange(values.value)
    }
  }

  onFocus = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist()

    this.setState({
      isFocused: true,
    })
  }

  onBlur = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    e.persist()
    this.setState(
      {
        isFocused: this.props.isFocused,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(e)
        }
      },
    )
  }

  onKeyDown = (e: React.KeyboardEvent) => {
    if (!this.props.submitOnEnter && e && (e.keyCode || e.which) === 13) {
      e.preventDefault()
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
  }

  getContent(): JSX.Element {
    if (this.props.contentRenderer) {
      return this.props.contentRenderer()
    }

    return null
  }

  render(): JSX.Element {
    const {
      isRequired,
      value,
      placeholder,
      isReadOnly,
      name,
      autocomplete,
      id,
      isTextarea,
      isDisabled,
      label,
      size,
      style,
      tooltip,
      tooltipPlacement,
      type,
      formatting,
      pattern,
      input,
      iconLeft,
      iconRight,
      helper,
      tooltipIcon,
      button,
    } = this.props
    const InputElement = this.props.isTextarea ? 'textarea' : 'input'
    const sharedProps = {
      id,
      placeholder,
      value,
      name: autocomplete ? name : uuidv4(),
      readOnly: isReadOnly,
      className: this.inputBEM(),
      autoComplete: 'none',
      autoCorrect: 'off',
      autoCapitalize: 'none',
      list: 'autocompleteOff',
      spellCheck: true,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
      disabled: isDisabled || isReadOnly,
    }

    return (
      <div className={this.BEM()}>
        {label && (
          <Label
            label={label}
            id={id}
            isRequired={!isDisabled && isRequired}
            size={size}
            invert={style !== formFieldTypes.LIGHT}
            tooltip={tooltipPlacement === 'label' ? tooltip : undefined}
          />
        )}
        <div className="textfield__inner">
          {formatting ? (
            <NumberFormat
              allowNegative={false}
              type={type === 'tel' ? type : 'text'}
              {...formatting}
              pattern={pattern ? pattern : null}
              {...sharedProps}
              onValueChange={this.onFormattedChange}
            />
          ) : (
            <InputElement
              {...input}
              {...sharedProps}
              onChange={!formatting ? this.onChange : null}
              type={type}
              ref={this.input}
            />
          )}

          {this.getContent()}
          {iconLeft && this.getIcon('left')}
          {iconRight && this.getIcon('right')}
        </div>
        {helper && <Helper {...helper} />}
        {tooltip && (!tooltipPlacement || tooltipPlacement === 'right') && (
          <div className="textfield__tooltip">
            <Icon name={ICONS.INFO} size="md" tooltip={tooltip} {...tooltipIcon} />
          </div>
        )}
        {button && <Button {...button} />}
      </div>
    )
  }
}

export default TextField
