import { createAction } from 'redux-actions'

const ns = 'settings/credentials/'

/* Get user credentials */
export const GetUserCredentials = createAction(`${ns}GET_USER_CREDENTIALS`)
export const GetUserCredentialsSuccess = createAction(`${ns}GET_USER_CREDENTIALS_SUCCESS`)
export const GetUserCredentialsFail = createAction(`${ns}GET_USER_CREDENTIALS_FAIL`)

/* Add credential */
export const AddCredential = createAction(`${ns}ADD_CREDENTIAL`)

/* Remove credential */
export const RemoveCredential = createAction(`${ns}REMOVE_CREDENTIAL`)

/* Replace Verified App */
export const ReplaceVerifiedApp = createAction(`${ns}REPLACE_VERIFIED_APP`)

/* Change password */
export const ChangePassword = createAction(`${ns}CHANGE_PASSWORD`)

/* Re-trigger Credential Verification */
export const ReTriggerCredentialVerification = createAction(`${ns}RE_TRIGGER_CREDENTIAL_VERIFICATION`)
