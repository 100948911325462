import * as React from 'react'
import { BaseStatefulComponent } from '../../BaseStatefulComponent'
import * as H from 'history'

import Icon, { ICONS } from '../icon/Icon'
import { Link } from 'react-router-dom'
import { ITooltipProps } from '../tooltip/Tooltip'

import './Button.scss'

export interface IButtonProps {
  /**
   * ID attribute.
   */
  id?: string
  /**
   * Visual variant of the button.
   * Main - Main UI kit button
   * Alt - Alt UI kit button
   * Third - Third UI kit button
   * tabs-selected - Reversed colors button used in tabs
   * tabs - Transparent colors button used in tabs
   * Link - Use them as main style for the link.
   * Link-accent - Use them as CTA link for extra attention. For example main “cancel” on the page
   * Link-accent-inverted - Use them as CTA link on light background
   * Link-inverted - Use them as main style for link on a light background
   */
  variant:
    | 'main'
    | 'alt'
    | 'third'
    | 'tabs-selected'
    | 'tabs'
    | 'link'
    | 'link-accent'
    | 'link-accent-inverted'
    | 'link-inverted'
    | 'tabs-alt'
    | 'tabs-selected-alt'
    | 'main-yellow'
    | 'alt-yellow'
    | 'red-border'
  /**
   * Button type
   */
  type?: 'button' | 'submit'
  /**
   * Additional custom class.
   */
  className?: string
  /**
   * Button text.
   */
  text: string
  /**
   * URL the button should link to.
   */
  url?: H.LocationDescriptor
  /**
   * Do a url replace
   */
  urlReplace?: boolean
  /**
   * If the button is disabled.
   */
  disabled?: boolean
  /**
   * onClick handler.
   */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  /**
   * Target attribute.
   */
  target?: string
  /**
   * If the button should look like a link.
   */
  link?: boolean
  /**
   * If the button should be full-width at all times.
   */
  block?: boolean
  /**
   * Name of the icon we want to show on the left.
   */
  iconLeft?: ICONS
  /**
   * Name of the icon we want to show on the right.
   */
  iconRight?: ICONS
  /**
   * Additional custom class for the icon we want to show.
   */
  iconClass?: string
  /**
   * Tooltip to show on icon.
   */
  iconTooltip?: ITooltipProps
  /**
   * Button element reference
   */
  buttonRef?: React.Ref<HTMLButtonElement>
  /**
   * onFocus callback handler.
   */
  onFocus?: (event: React.FormEvent<HTMLButtonElement>) => void
  /**
   * onBlur callback handler.
   */
  onBlur?: (event: React.FormEvent<HTMLButtonElement>) => void
  /**
   * Force the button to be visually focused.
   */
  isFocused?: boolean
  /**
   * Is button height bigger
   */
  bigButton?: boolean
  /**
   * Content alignment
   */
  alignContent?: 'left' | 'right' | 'center'
  /**
   * Async action loading indicator
   */
  loading?: boolean
}

export interface IButtonState {
  /**
   * If button is focused
   */
  isFocused: boolean
  /**
   * Internal props state
   */
  former: {
    isFocused: boolean
  }
}

export default class Button extends BaseStatefulComponent<IButtonProps, IButtonState> {
  static defaultProps: Partial<IButtonProps> = {
    block: true,
    bigButton: false,
    loading: false,
  }

  constructor(props: IButtonProps) {
    super(props)

    this.state = {
      isFocused: props.isFocused,
      former: {
        isFocused: props.isFocused,
      },
    }
  }

  BEM(): string {
    const classArray = ['btn']

    if (this.props.variant) {
      classArray.push('btn--' + this.props.variant)
    }

    if (this.props.link) {
      classArray.push('btn--link')
    }

    if (this.props.block) {
      classArray.push('btn--block')
    }

    if (this.props.bigButton) {
      classArray.push('btn--big')
    }

    if (this.props.alignContent) {
      classArray.push(`btn--content-${this.props.alignContent.toLowerCase()}`)
    }

    // Show hover state if focused (tabbed into button)
    if (this.state.isFocused) {
      classArray.push('is-focused')
    }

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.disabled) {
      classArray.push('btn--disabled')
    }

    if (this.props.loading) {
      classArray.push('btn--loading')
    }

    return classArray.join(' ')
  }

  iconBEM(location: string): string {
    const classArray = ['btn__icon']

    classArray.push('btn__icon--' + location)

    if (this.props.iconClass) {
      classArray.push(this.props.iconClass)
    }

    return classArray.join(' ')
  }

  getIcon(location: string): JSX.Element {
    let name: ICONS = null

    if (location === 'left' && this.props.iconLeft) {
      name = this.props.iconLeft
    }

    if (location === 'right' && this.props.iconRight) {
      name = this.props.iconRight
    }

    return <Icon name={name} className={this.iconBEM(location)} tooltip={this.props.iconTooltip} />
  }

  getTag(): string {
    return this.props.url ? 'Link' : 'button'
  }

  isClickable(): boolean {
    return this.getTag() === 'Link' || this.getTag() === 'button'
  }

  onFocus = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.persist()

    this.setState({
      isFocused: true,
    })
  }

  onBlur = (e: React.FormEvent<HTMLButtonElement>): void => {
    e.persist()
    this.setState(
      {
        isFocused: this.props.isFocused,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(e)
        }
      },
    )
  }

  render(): JSX.Element {
    if (this.props.url && !this.props.disabled) {
      return (
        <Link
          className={this.BEM()}
          to={this.props.url}
          replace={this.props.urlReplace}
          onClick={this.isClickable() ? this.props.onClick : null}
          id={this.props.id}
        >
          <span className="btn__inner">
            {this.props.iconLeft && this.getIcon('left')}
            <span className="btn__text" dangerouslySetInnerHTML={{ __html: this.props.text }} />
            {this.props.iconRight && this.getIcon('right')}
          </span>
        </Link>
      )
    }

    return (
      <button
        className={this.BEM()}
        disabled={this.props.disabled || this.props.loading}
        type={this.props.type || 'button'}
        onClick={this.isClickable() ? this.props.onClick : null}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        id={this.props.id}
        ref={this.props.buttonRef}
      >
        <span className="btn__inner">
          {this.props.iconLeft && this.getIcon('left')}
          <span className="btn__text" dangerouslySetInnerHTML={{ __html: this.props.text }} />
          {this.props.iconRight && this.getIcon('right')}
          {this.props.loading && (
            <div className="loader">
              <span />
            </div>
          )}
        </span>
      </button>
    )
  }
}
