import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import {
  AddCredential,
  ChangePassword,
  GetUserCredentials,
  GetUserCredentialsFail,
  GetUserCredentialsSuccess,
  RemoveCredential,
  ReplaceVerifiedApp,
  ReTriggerCredentialVerification,
} from './CredentialsViewActions'
import {
  AddCredential$Request,
  AddCredential$Response,
  ChangePassword$Request,
  CredentialId,
  CredentialType,
  GetUserCredentials$Response,
  ReplaceVerifiedApp$Response,
  ReTriggerCredentialVerification$Response,
} from 'services/api.types'
import { Action } from 'redux-actions'
import { paths } from 'common/router/routePaths'
import { CreateVerification } from 'views/verification/VerificationViewActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { Sign } from 'views/signing/SigningViewActions'
import { whoAmI } from 'common/auth/AuthActions'
import api from 'services/Api.services'
import { NotificationType } from 'components/ui/notification/Notification'

function getSignAction(id: string) {
  return {
    type: String(Sign),
    payload: {
      signActionId: id,
      title: getI18n().t('credentials-app.title', { ns: 'signingView' }),
      successTitle: getI18n().t('credentials-app.successTitle', { ns: 'signingView' }),
      text: getI18n().t('credentials-app.text', { ns: 'signingView' }),
      signedUrl: paths.settings,
      signedAction: {
        type: String(addNotification),
        payload: {
          type: NotificationType.SUCCESS,
          description: getI18n().t('success.add-credential-app-title', { ns: 'frontendNotifications' }),
        },
      },
    },
  }
}

function* sagaGetUserCredentials() {
  try {
    const userCredentials: GetUserCredentials$Response = yield call(api.user.getUserCredentials)
    yield put({ type: String(GetUserCredentialsSuccess), payload: userCredentials })
  } catch (e) {
    yield put({ type: String(GetUserCredentialsFail) })
    const notification: INotification = {
      ...e?.response?.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaAddCredential(action: Action<AddCredential$Request>) {
  try {
    const response: AddCredential$Response = yield call(api.user.addCredential, action.payload)
    if (action.payload.type === CredentialType.PHONE_NUMBER) {
      yield put({
        type: String(CreateVerification),
        payload: {
          title: getI18n().t('verify-phone-number', { ns: 'settingsCredentials' }),
          successAction: {
            type: String(addNotification),
            payload: {
              description: getI18n().t('success.phone-number-verified', {
                ns: 'frontendNotifications',
              }),
              type: NotificationType.SUCCESS,
            },
          },
        },
      })
    } else if (action.payload.type === CredentialType.EMAIL) {
      yield put({
        type: String(addNotification),
        payload: {
          description: getI18n().t('success.add-credential-email-description', {
            ns: 'frontendNotifications',
            email: action.payload.value,
          }),
          type: NotificationType.SUCCESS,
        },
      })
      window.scrollTo(0, 0)
    } else if (action.payload.type === CredentialType.APP) {
      yield put(getSignAction(response.signActionId))
    }

    // Get new credentials
    yield put({ type: String(GetUserCredentials) })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaReplaceVerifiedApp(action: Action<CredentialId>) {
  try {
    const response: ReplaceVerifiedApp$Response = yield call(api.user.replaceVerifiedApp, {
      value: action.payload,
    })
    yield put(getSignAction(response.signActionId))
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaRemoveCredential(action: Action<CredentialId>) {
  try {
    yield call(api.user.removeCredential, { id: action.payload })
    yield put({
      type: String(addNotification),
      payload: {
        description: getI18n().t('success.remove-credential', { ns: 'frontendNotifications' }),
        type: NotificationType.SUCCESS,
      },
    })

    // Get new credentials
    yield put({ type: String(GetUserCredentials) })
    yield put({ type: String(whoAmI) })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaChangePassword(action: Action<ChangePassword$Request>) {
  try {
    yield call(api.user.changePassword, action.payload)
    yield put({
      type: String(addNotification),
      payload: {
        description: getI18n().t('success.password-changed', { ns: 'frontendNotifications' }),
        type: NotificationType.SUCCESS,
      },
    })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaReTriggerCredentialVerification(action: Action<{ id: CredentialId; type: CredentialType }>) {
  try {
    const response: ReTriggerCredentialVerification$Response = yield call(api.user.reTriggerCredentialVerification, {
      id: action.payload.id,
    })

    if (action.payload.type === CredentialType.PHONE_NUMBER) {
      yield put({
        type: String(CreateVerification),
        payload: {
          title: 'Verify phone number',
          successUrl: paths.settings,
        },
      })
    } else if (action.payload.type === CredentialType.APP) {
      // redirect to signing
      yield put(getSignAction(response.signActionId))
    } else {
      yield put({
        type: String(addNotification),
        payload: {
          description: getI18n().t('success.re-trigger-email', { ns: 'frontendNotifications' }),
          type: NotificationType.SUCCESS,
        },
      })
    }
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* credentialsViewSagas() {
  yield all([
    takeEvery(String(GetUserCredentials), sagaGetUserCredentials),
    takeEvery(String(AddCredential), sagaAddCredential),
    takeEvery(String(ReplaceVerifiedApp), sagaReplaceVerifiedApp),
    takeEvery(String(RemoveCredential), sagaRemoveCredential),
    takeEvery(String(ChangePassword), sagaChangePassword),
    takeEvery(String(ReTriggerCredentialVerification), sagaReTriggerCredentialVerification),
  ])
}
