import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { BaseComponent } from 'components/BaseComponent'
import { ProfileRole } from 'services/api.types'
import MatchRoles from 'common/auth/MatchRoles'

import './Navigation.scss'

export interface INavigationProps {
  /**
   * Links to display in header
   */
  links: INavigationLink[]
  /**
   * Close function for mobile navigator modal
   */
  closeMobileNav: () => void
  /**
   * Additional classes
   */
  className?: string
}

export interface INavigationLink {
  /**
   * Display label
   */
  label: string
  /**
   * Link url
   */
  url: string
  /**
   * Roles to show the link for
   */
  roles?: ProfileRole[]
  /**
   * Is link disabled
   */
  disabled?: boolean
  showWhenNoRoles?: boolean
  /**
   * Additional classes
   */
  className?: string
}

class Navigation extends BaseComponent<INavigationProps> {
  get navLinks(): JSX.Element[] {
    return this.props.links.map((link, index) => {
      return (
        <MatchRoles key={index} matchOne={link.roles} noRole={link.showWhenNoRoles}>
          <li className={this.BEM_NavItem(link)}>
            <NavLink
              to={link.url}
              className={`nav__link ${link.disabled ? 'nav__link--disabled' : ''}`}
              activeClassName="is-active"
              onClick={this.props.closeMobileNav}
            >
              {link.label}
            </NavLink>
          </li>
        </MatchRoles>
      )
    })
  }

  BEM_NavItem(link: INavigationLink): string {
    const classArray = ['nav__item']

    if (link.className) {
      classArray.push(link.className)
    }

    return classArray.join(' ')
  }

  public render(): JSX.Element {
    return (
      <nav className="nav">
        <ul className="nav__list">{this.navLinks}</ul>
      </nav>
    )
  }
}

export default Navigation
