/**
 * Definitions for available draft keys used against generic BE draft endpoints
 * When adding new globally accessible draft key (all admins can use), update DraftStorage.isLedgerAdminDraft method also
 */
export enum DraftStorageEnum {
  PROFORMA_ISSUANCE = 'proforma-issuance',
  ONBOARDING_COMPANY = 'onboarding-company',
  ONBOARDING_STOCK = 'onboarding-stock',
  HISTORIC_LEDGER_IMPORT = 'historic-ledger-import',
  ISSUANCES = 'issuances',
  ISSUANCE_KEY_BASE = 'issuance-stock-',
  NEW_TRANSFER = 'new-transfer',
  BULK_IMPORT = 'bulk-import',
  CANCELLED_TRANSFER = 'cancelled-transfer',
}
