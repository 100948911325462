import axios from 'axios'

export interface IDelawareCompanyDetails {
  address: string
  city: string
  corporationName: string
  incorporationDate: string
  state: string
  zipCode: string
}

export interface IDelawareStockDetails {
  class: string
  numberOfShares: number
  parValue: number
}

export interface IDelawareData {
  companyDetails: IDelawareCompanyDetails
  stockDetails: IDelawareStockDetails[]
}

export default function delawareScrape(registrationNumber: string): Promise<IDelawareData> {
  return axios
    .get('/api-delaware/corporations/' + registrationNumber)
    .then(response => {
      return { ...response.data, hasBeenLoaded: true }
    })
    .catch(err => {
      console.log('delaware scrapeing ERROR: ', err)
      return { hasBeenLoaded: true, companyDetails: {}, stockDetails: {} }
    })
}
