import { Action, handleActions } from 'redux-actions'
import {
  ContractId,
  GenerateContractFile$Response,
  GetDashboard$Response,
  GetDashboard$Response$Ledger,
  GetDashboard$Response$Progress,
  MyAccountStockListDto$StockItem,
  MyPendingTransferListDto,
  StockListDto,
  StockType,
  TransferListDto,
} from 'services/api.types'
import {
  AddDashboardNotification,
  ClearDashboardNotification,
  GetDashboard,
  GetDraftsSuccess,
  GetDashboardFail,
  GetDashboardSuccess,
  RolesDisabled,
  GenerateContractFileSuccess,
  GenerateContractFile,
  ShowRegistrationSuccess,
  SignLedgerContractFileStarted,
  SignLedgerContractFileFinished,
  GetDashboardDataOnly,
  GetDashboardDataOnlySuccess,
  GetDashboardDataOnlyFail,
  GetDraftsFailure,
  GetDrafts,
} from './DashboardViewActions'
import { IDashboardNotificationProps } from './children/notification/DashboardNotification'
import { INewTransferFormFields } from '../transfers/children/new-transfer-form/NewTransferFormFieldNames'
import { IIssuanceDraft } from '../issuance/IssuanceViewReducer'
import { getPreferredStockForm } from 'common/utils/stockUtil'
import { SelectUserProfile } from 'common/auth/AuthActions'
import { ClearIssuanceDraftSuccess } from 'views/issuance/IssuanceViewActions'
import { ClearTransferDraftSuccess } from 'views/transfers/TransfersViewActions'

export enum TodoItemStatus {
  TODO = 'Todo',
  COMPLETED = 'Completed',
}

export interface ITodoItem {
  title: string
  url?: string
  status?: TodoItemStatus
  disabled?: boolean
}

export interface IDrafts {
  transfer: INewTransferFormFields
  issuances: IIssuanceDraft[]
  pendingTransfers: MyPendingTransferListDto[]
  todoItems: ITodoItem[]
}

class State {
  transfers: TransferListDto[] = []
  stocks: StockListDto[] = []
  myStocks: MyAccountStockListDto$StockItem[] = []
  ledger: GetDashboard$Response$Ledger
  progress: GetDashboard$Response$Progress = {
    additionalAdminOnboarding: false,
    companyInfoAdded: false,
    hasCredentials: false,
    hasDisabledProfiles: false,
    hasVerifiedAppId: false,
    hasVerifiedPhoneNumber: false,
    historicImport: { completed: false },
    holderListExportGenerated: false,
    issuanceAdded: false,
    ledgerExists: false,
    legalEntityInfoConfirmed: false,
    legalHolder: false,
    onboardingContractSigned: false,
    stockInfoAdded: false,
    userInfoAdded: false,
  }
  drafts: IDrafts = { transfer: null, issuances: [], pendingTransfers: [], todoItems: [] }
  isLoading: boolean = false
  isLoaded: boolean = false
  error: boolean = false
  notification: IDashboardNotificationProps = null
  areRolesDisabled: boolean = false
  contractId: ContractId = ''
  draftsLoading: boolean = false
  contractSigningLoading: boolean = false
  contractGeneratingLoading: boolean = false
  showRegistrationSuccess: boolean = false
}

const defaultState = new State()

export default handleActions<State, any>(
  {
    [String(SelectUserProfile)]: (state: State): State => ({
      ...state,
      transfers: defaultState.transfers,
      stocks: defaultState.stocks,
      myStocks: defaultState.myStocks,
      drafts: defaultState.drafts,
    }),
    [String(GetDashboardDataOnly)]: (state: State): State => ({
      ...state,
      ledger: defaultState.ledger,
      stocks: defaultState.stocks,
      myStocks: defaultState.myStocks,
      transfers: defaultState.transfers,
      drafts: defaultState.drafts,
      isLoading: true,
      error: false,
    }),
    [String(GetDashboardDataOnlySuccess)]: (state: State, action: Action<GetDashboard$Response>): State => {
      const filteredSeriesFromPreferredStock: any[] = action.payload.stocks
        .filter(stock => stock.type === StockType.PREFERRED && stock.series)
        .map(stock => {
          return stock.series.map(series => {
            return getPreferredStockForm(stock, series)
          })
        })

      const preferredStock: StockListDto[] = []
      filteredSeriesFromPreferredStock.map(stock => stock.map((serie: any) => preferredStock.push(serie)))

      action.payload.stocks = action.payload.stocks.concat(...preferredStock)
      action.payload.stocks.sort((x, y) => {
        return x.type === 'PREFERRED' ? -1 : y.type === 'PREFERRED' ? 1 : 0
      })
      action.payload.stocks.sort((x, y) => {
        return x.type === 'PREFERRED' && x.series.length > 0
          ? -1
          : y.type === 'PREFERRED' && y.series.length > 0
          ? 1
          : 0
      })
      return {
        ...state,
        ...(action.payload && action.payload),
        myStocks: action.payload.myStocks[0] ? action.payload.myStocks[0].stocks : [],
        isLoading: false,
        isLoaded: true,
      }
    },
    [String(GetDashboardDataOnlyFail)]: (state: State): State => {
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    },
    [String(GetDashboard)]: (state: State): State => ({
      ...state,
      ledger: defaultState.ledger,
      stocks: defaultState.stocks,
      myStocks: defaultState.myStocks,
      transfers: defaultState.transfers,
      drafts: defaultState.drafts,
      isLoading: true,
      error: false,
    }),
    [String(GetDashboardSuccess)]: (state: State, action: Action<GetDashboard$Response>): State => {
      const filteredSeriesFromPreferredStock: any[] = action.payload.stocks
        .filter(stock => stock.type === StockType.PREFERRED && stock.series)
        .map(stock => {
          return stock.series.map(series => {
            return getPreferredStockForm(stock, series)
          })
        })

      const preferredStock: StockListDto[] = []
      filteredSeriesFromPreferredStock.map(stock => stock.map((serie: any) => preferredStock.push(serie)))

      action.payload.stocks = action.payload.stocks.concat(...preferredStock)
      action.payload.stocks.sort((x, y) => {
        return x.type === 'PREFERRED' ? -1 : y.type === 'PREFERRED' ? 1 : 0
      })
      action.payload.stocks.sort((x, y) => {
        return x.type === 'PREFERRED' && x.series.length > 0
          ? -1
          : y.type === 'PREFERRED' && y.series.length > 0
          ? 1
          : 0
      })
      return {
        ...state,
        ...(action.payload && action.payload),
        myStocks: action.payload.myStocks[0] ? action.payload.myStocks[0].stocks : [],
        isLoading: false,
        isLoaded: true,
      }
    },
    [String(GetDashboardFail)]: (state: State): State => {
      return {
        ...state,
        isLoading: false,
        error: true,
      }
    },
    [String(GetDrafts)]: (state: State): State => {
      return {
        ...state,
        draftsLoading: true,
      }
    },
    [String(GetDraftsSuccess)]: (state: State, action: Action<IDrafts>): State => {
      return {
        ...state,
        drafts: action.payload,
        draftsLoading: false,
      }
    },
    [String(GetDraftsFailure)]: (state: State): State => {
      return {
        ...state,
        draftsLoading: false,
      }
    },
    [String(AddDashboardNotification)]: (state: State, action: Action<IDashboardNotificationProps>): State => ({
      ...state,
      notification: action.payload,
    }),
    [String(ClearDashboardNotification)]: (state: State): State => {
      return {
        ...state,
        notification: null,
      }
    },
    [String(RolesDisabled)]: (state: State, action: Action<boolean>): State => {
      return {
        ...state,
        areRolesDisabled: action.payload,
      }
    },
    [String(ShowRegistrationSuccess)]: (state: State, action: Action<boolean>): State => {
      return {
        ...state,
        showRegistrationSuccess: action.payload,
      }
    },
    [String(GenerateContractFile)]: (state: State): State => {
      return {
        ...state,
        contractGeneratingLoading: true,
      }
    },
    [String(GenerateContractFileSuccess)]: (state: State, action: Action<GenerateContractFile$Response>): State => {
      return {
        ...state,
        contractGeneratingLoading: false,
        contractId: action.payload.contractId,
      }
    },
    [String(SignLedgerContractFileStarted)]: (state: State, action: Action<GenerateContractFile$Response>): State => {
      return {
        ...state,
        contractSigningLoading: true,
      }
    },
    [String(SignLedgerContractFileFinished)]: (state: State, action: Action<GenerateContractFile$Response>): State => {
      return {
        ...state,
        contractSigningLoading: false,
      }
    },
    [String(ClearTransferDraftSuccess)]: (state: State): State => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          transfer: defaultState.drafts.transfer,
        },
      }
    },
    [String(ClearIssuanceDraftSuccess)]: (state: State, action: Action<string[]>): State => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          issuances: state.drafts.issuances.filter(i => !action.payload.includes(i.stockDetails.id)),
        },
      }
    },
  },
  new State(),
)

export { State as DashboardViewState }
