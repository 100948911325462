import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-dates/initialize'
import * as WebFont from 'webfontloader'
import { I18nextProvider } from 'react-i18next'

import Root from './Root'
import configureStore from './store'
import ErrorBoundaryFallback from 'components/ui/error-boundary-fallback/ErrorBoundaryFallback'

import i18n from './i18n'

import 'react-phone-number-input/style.css'
import 'react-dates/lib/css/_datepicker.css'
import './Root.scss'

import 'vendor/sentry'
import 'vendor/datadog'

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,900'],
  },
})
const rootElement = document.getElementById('root')
const history = createBrowserHistory()

// TODO: This should be used from context
export const storeAndPersistor = configureStore({}, history)

function init() {
  ReactDOM.render(
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={() => {
        storeAndPersistor.persistor
          .purge()
          .catch(err => {
            console.error(`Error purging store state`, {
              error: err,
            })
          })
          .then(() => {
            window.location.reload()
          })
      }}
    >
      <Provider store={storeAndPersistor.store}>
        <I18nextProvider i18n={i18n}>
          <PersistGate persistor={storeAndPersistor.persistor}>
            <Root history={history} />
          </PersistGate>
        </I18nextProvider>
      </Provider>
    </ErrorBoundary>,
    rootElement,
  )
}

;(async function bootstrap() {
  init()
})()
