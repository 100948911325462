import { getFullName } from 'common/utils/nameUtil'
import { IHistoricTransferDraft } from 'views/transfers/children/new-transfer-historic-form/NewTransferHistoricFormFieldNames'
import {
  CreateBulkHolder$Request$Holder,
  CreateBulkHolder$Response$Holder,
  HolderType,
  StockListDto,
} from '../../services/api.types'
import {
  IBulkImportPayload,
  IBulkTransferDraft,
} from '../../views/transfers/children/bulk-import-form/BulkImportFormFieldNames'

interface IHoldersPayload {
  data: CreateBulkHolder$Request$Holder[]
  ids: CreateBulkHolder$Response$Holder[]
}

interface IMatcherProps extends IBulkImportPayload {
  holders: IHoldersPayload
  treasuryStockAccount: string
}

export function matcherEngine(input: IMatcherProps): IHistoricTransferDraft[] {
  const holderDataMapping: Record<string, any> = input.holders.data.reduce((map, holder) => {
    map[holder.externalId] = {
      name: holder.legalEntity?.name || getFullName(holder.naturalPerson),
      email: holder.legalEntity?.email || holder.naturalPerson.email,
      type: holder.legalEntity ? HolderType.LEGAL_ENTITY : HolderType.NATURAL_PERSON,
    }
    return map
  }, {})
  const accountIdMap: Record<string, { accountId: string; holderId: string }> = input.holders.ids.reduce(
    (map, holder) => {
      map[holder.externalId] = { accountId: holder.accountId, holderId: holder.holderId }
      return map
    },
    {},
  )

  const getFromValue = (transfer: IBulkTransferDraft) => {
    if (transfer.type === 'ISSUANCE') {
      return {
        label: 'Original issuance',
        value: input.treasuryStockAccount,
      }
    } else if (transfer.type === 'RETIREMENT') {
      return {
        label: 'Treasury (Corporation)',
        value: input.treasuryStockAccount,
      }
    }

    return {
      label: holderDataMapping[transfer.from.matchingId].name,
      value: accountIdMap[transfer.from.matchingId].accountId,
    }
  }

  const getToValue = (transfer: IBulkTransferDraft) => {
    if (transfer.type === 'BUYBACK') {
      return {
        label: 'Treasury (Corporation)',
        value: input.treasuryStockAccount,
      }
    } else if (transfer.type === 'RETIREMENT') {
      return {
        label: 'Unissued Shares',
        value: 'unissuedShares',
      }
    }

    return {
      label: holderDataMapping[transfer.to.matchingId].name,
      value: accountIdMap[transfer.to.matchingId].accountId,
    }
  }

  const stockMap = input.transfers.reduce<Record<string, StockListDto>>((map, { stockClass, stockType }) => {
    map[`${stockClass}${stockType}`] = input.stocks.find(
      existingStock =>
        existingStock.stockName.toLowerCase() === stockClass.toLowerCase() && existingStock.type === stockType,
    )
    return map
  }, {})

  const findStock = (transfer: IBulkTransferDraft) => {
    if (input.stocks.length === 1 && (transfer.stockClass === '' || (transfer.stockType as string) === '')) {
      return input.stocks[0]
    }

    return stockMap[`${transfer.stockClass}${transfer.stockType}`]
  }

  const timestamp = Date.now()

  const transfers = input.transfers.map<IHistoricTransferDraft>((transfer, index) => {
    const from = getFromValue(transfer)
    const to = getToValue(transfer)
    const stock = findStock(transfer)

    return {
      id: `${timestamp}.${index}`,
      addedHolder: transfer.to?.matchingId
        ? {
            type: holderDataMapping[transfer.to.matchingId].type,
            data: {
              ...transfer.to.data,
              holderId: accountIdMap[transfer.to.matchingId].holderId,
              accountId: accountIdMap[transfer.to.matchingId].accountId,
              name: holderDataMapping[transfer.to.matchingId].name,
              calculatedName: holderDataMapping[transfer.to.matchingId].name,
            },
          }
        : null,
      from,
      to,
      phoneNumber: '',
      name: to.label,
      email: transfer.to?.matchingId ? holderDataMapping[transfer.to.matchingId].email : null,
      holderType: transfer.to?.matchingId ? holderDataMapping[transfer.to.matchingId].type : null,
      dateOfTransfer: transfer.dateOfTransfer,
      comment: `${transfer.comment}${transfer.comment ? '\n\n' : ''}Bulk imported`,
      amountPaid: transfer.amountPaid,
      documents: [],
      order: transfer.numberOfShares,
      type: transfer.type,
      isCertificated: false,
      issuance: transfer.type === 'ISSUANCE',
      certificatedTransfer: '',
      numberOfShares: transfer.numberOfShares,
      stockClassId: stock
        ? {
            label: stock.stockName,
            value: stock.id,
          }
        : null,
    }
  })

  return transfers
}
