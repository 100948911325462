import { applyMiddleware, compose, createStore } from 'redux'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import createSagaMiddleware from 'redux-saga'
import promiseMiddleware from 'redux-loading-promise-middleware'
import rootSaga from './rootSagas'
import rootReducer from './rootReducer'

export default function configureStore(initialState = {}, history: History) {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const persistConfig: PersistConfig = {
    storage,
    key: 'root',
    whitelist: [], // list of store states that we want to persist
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer as any)
  const sagaMiddleware = createSagaMiddleware()

  // create store with all the middleware
  const store = createStore(
    connectRouter(history)(persistedReducer),
    initialState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), promiseMiddleware, sagaMiddleware)),
  )

  // run rootSaga
  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  return { store, persistor }
}
