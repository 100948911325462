export interface IGetFullNameProps {
  givenNames?: string
  lastName?: string
  middleName?: string
  nameSuffix?: string
}

/**
 * Generates person's full name based on name parts
 */
export const getFullName = (props: IGetFullNameProps): string => {
  const { givenNames = '', lastName = '', middleName = '', nameSuffix = '' } = props
  let fullName = ''

  if (givenNames) {
    fullName += `${givenNames}`
  }
  if (middleName) {
    fullName += ` ${middleName}`
  }
  if (lastName) {
    fullName += ` ${lastName}`
  }
  if (nameSuffix) {
    fullName += `${fullName ? ', ' : ''}${nameSuffix}`
  }

  return fullName.trim()
}
