import * as React from 'react'
import { connect } from 'react-redux'
import { History } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import { Dispatchable1 } from 'redux-dispatchers'
import { getI18n } from 'react-i18next'
import * as _ from 'lodash'

import { IGlobalState } from './rootReducer'
import RoutesWrapper from 'common/router/RoutesWrapper'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationCode, NotificationType } from 'components/ui/notification/Notification'
import { BaseStatefulComponent } from 'components/BaseStatefulComponent'
import { authenticated } from 'common/auth/AuthSelectors'
import mixpanel from 'vendor/mixpanel'

export interface IHistoryProps {
  history: History
  authenticated?: boolean
}

export interface IDispatchProps {
  addNotification?: Dispatchable1<INotification>
}

export interface IStateProps {}

export interface IAppState {
  usercomCheckInterval: any
}

export interface IAppProps extends IHistoryProps, IDispatchProps {}

class Root extends BaseStatefulComponent<IAppProps, IAppState> {
  unregisterHistoryListener: (() => void) | null = null

  constructor(props: IAppProps) {
    super(props)

    this.state = {
      usercomCheckInterval: null,
    }
  }

  componentDidMount() {
    this.initWebworker()
    document.addEventListener(
      'scroll',
      _.throttle(this.calculateUsercomWidgetSpacing, 200, { trailing: true, leading: true }),
    )

    const usercomCheckInterval = setInterval(() => {
      this.calculateUsercomWidgetSpacing()
    }, 3000)

    this.setState({ usercomCheckInterval })

    this.unregisterHistoryListener = this.props.history.listen((location, action) => {
      mixpanel.track('Page view', {
        title: document.title,
        route: location.pathname,
      })
    })
  }

  componentDidUpdate() {
    this.calculateUsercomWidgetSpacing()
  }

  componentWillUnmount() {
    this.setState({ usercomCheckInterval: null }, () => clearInterval(this.state.usercomCheckInterval))
    this.unregisterHistoryListener?.()
  }

  calculateUsercomWidgetSpacing = () => {
    // Usercom area margin calc
    const usercomWidget = document.querySelector('#usercom-launcher-dot-frame')

    if (!usercomWidget) {
      return
    }

    if (
      window.innerHeight + window.scrollY >=
        document.body.offsetHeight - document.querySelector('.footer')?.clientHeight ||
      0
    ) {
      usercomWidget.classList.add('mb-8')
    } else {
      usercomWidget.classList.remove('mb-8')
    }
  }

  initWebworker = () => {
    const myWorker = new Worker('/webWorker.js')

    myWorker.onmessage = e => {
      if (e.data === 'DEPLOY_DETECTED') {
        this.props.addNotification({
          type: NotificationType.INFO,
          description: `<a href="javascript:window.location.href=window.location.href">${getI18n().t(
            'common:messages.deployment-in-progress',
          )}</a>`,
          code: NotificationCode.DEPLOYMENT_IN_PROGRESS,
          allowHtml: true,
        })
      }
    }
  }

  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <RoutesWrapper />
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = (state: IGlobalState, ownProps: IAppProps): IStateProps => {
  return {
    ...ownProps,
    authenticated: authenticated(state),
  }
}
const mapDispatchToProps: IDispatchProps = {
  addNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(Root)
