// Poeditor
import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: false,
    // have a translations namespace used around the full app
    defaultNS: 'common',
    fallbackLng: 'en',
    lng: 'en',
    ns: [
      'common',
      'companyForm',
      'backendErrors',
      'dashboard',
      'forms',
      'frontendNotifications',
      'globalNotifications',
      'globalError',
      'holderList',
      'nextSteps',
      'onboarding',
      'routerLoadable',
      'settings',
      'settingsAudit',
      'settingsCredentials',
      'settingsUserManagement',
      'settingsCompany',
      'settingsCompanyHistory',
      'sideNav',
      'signingView',
      'stock',
      'stockForm',
      'transfers',
      'verificationView',
      'votingList',
      'recoverySms',
      'userGuidance',
      'ledgerPreferences',
    ],

    backend: {
      queryStringParams: { _: new Date().getTime() },
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      useSuspense: false,
      wait: true,
    },
  })

export default i18n
