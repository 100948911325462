import { IGlobalState } from '../../rootReducer'

export const getDraftsSelector = (state: IGlobalState) => state.dashboardView.drafts
export const getDraftsLoading = (state: IGlobalState) => state.dashboardView.draftsLoading

export const getDashboardNotification = (state: IGlobalState) => state.dashboardView.notification

export const getUserProgress = (state: IGlobalState) => state.dashboardView.progress

export const getStocks = (state: IGlobalState) => state.dashboardView.stocks
export const getMyStocks = (state: IGlobalState) => state.dashboardView.myStocks

export const getDashboardLoaded = (state: IGlobalState) => state.dashboardView.isLoaded
export const getDashboardLoading = (state: IGlobalState) => state.dashboardView.isLoading

export const getAreRolesDisabled = (state: IGlobalState) => state.dashboardView.areRolesDisabled
export const getContractId = (state: IGlobalState) => state.dashboardView.contractId
export const getContractSigningLoading = (state: IGlobalState) => state.dashboardView.contractSigningLoading

export const getShowRegistrationSuccess = (state: IGlobalState) => state.dashboardView.showRegistrationSuccess

export const getLedger = (state: IGlobalState) => state.dashboardView.ledger

export const getContractGeneratingLoading = (state: IGlobalState) => state.dashboardView.contractGeneratingLoading

export const getSubscriptionExpired = (state: IGlobalState) => state.dashboardView.ledger.subscriptionExpired
export const getHolderToHolderTransfersEnabled = (state: IGlobalState) =>
  state.dashboardView.ledger.holderToHolderTransfersEnabled
