import Routes, { IDispatchProps, IStateProps } from './Routes'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module'

import { IGlobalState } from '../../rootReducer'
import {
  authenticated,
  currentProfile,
  shouldDisableMenu,
  userDetails,
  userLedgers,
  userRole,
  whoAmIFetched,
  isOnboardingCompleted,
  ledgerBasicDetailsFetched,
  whoAmIFetching,
  profileSelectFetched,
  profileSelectFetching,
} from '../auth/AuthSelectors'
import { SelectUserProfile, whoAmI } from '../auth/AuthActions'
import { withRouter } from 'react-router'
import { getCurrentPath } from './RouterSelectors'
import { LogOut } from 'views/login/LoginViewActions'
import { getDashboardLoaded, getDashboardLoading, getUserProgress } from 'views/dashboard/DashboardViewSelectors'
import { getFilteredTransferListSelector } from 'views/transfers/TransfersViewSelectors'
import {
  getEditableLedger,
  getHistoricLedger,
  getIsHistoricImportCompleted,
  getIsHistoricImportMode,
} from 'common/store/common/commonSelector'
import { getStockListSelector, getHasActiveStockClass, getStockListLoaded } from 'common/store/stocks/stockSelector'
import { GetStockList } from 'common/store/stocks/stocksAction'

const tagManagerArgs = {
  gtmId: 'GTM-N72H4PT',
}

TagManager.initialize(tagManagerArgs)

const mapStateToProps = (state: IGlobalState): IStateProps => {
  return {
    authenticated: authenticated(state),
    whoAmIFetched: whoAmIFetched(state),
    whoAmIFetching: whoAmIFetching(state),
    editableLedger: getEditableLedger(state),
    profileSelectFetched: profileSelectFetched(state),
    profileSelectFetching: profileSelectFetching(state),
    currentPath: getCurrentPath(state),
    userRole: userRole(state),
    userLedgers: userLedgers(state),
    userDetails: userDetails(state),
    currentProfile: currentProfile(state),
    shouldDisableMenu: shouldDisableMenu(state),
    isOnboardingCompleted: isOnboardingCompleted(state),
    dashboardFetched: getDashboardLoaded(state),
    dashboardFetching: getDashboardLoading(state),
    ledgerBasicDetailsFetched: ledgerBasicDetailsFetched(state),
    getUserProgress: getUserProgress(state),
    historicLedger: getHistoricLedger(state),
    historicImportMode: getIsHistoricImportMode(state),
    historicImportCompleted: getIsHistoricImportCompleted(state),
    stockList: getStockListSelector(state),
    stockListLoaded: getStockListLoaded(state),
    hasActiveStockClass: getHasActiveStockClass(state),
    transfers: getFilteredTransferListSelector(state),
  }
}

const mapDispatchToProps: IDispatchProps = {
  whoAmI,
  SelectUserProfile,
  LogOut,
  GetStockList,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
