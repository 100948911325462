import { IGlobalState } from '../../rootReducer'
import { IHolderFormFields as IProformaHolderFormFields } from './children/issue-stock-proforma/IssueStockProforma.fieldNames'

export const getIssuanceDraft = (state: IGlobalState) => state.issuanceView.issuanceDraft

export const getIssuanceDraftLoading = (state: IGlobalState): boolean => state.issuanceView.draftLoading.getDraft

export const getLoading = (state: IGlobalState) => state.issuanceView.loading

export const getProformaIssuanceDraft = (state: IGlobalState) => state?.issuanceView?.proformaIssuance.draft

export const getProformaIssuanceHolder = (state: IGlobalState): IProformaHolderFormFields | null => {
  if (!state?.issuanceView?.proformaIssuance?.draft?.data?.length) {
    return null
  }

  for (const stock of state.issuanceView.proformaIssuance.draft.data) {
    for (const holder of stock.holders) {
      if (holder.id === state.issuanceView.proformaIssuance.selectedHolderTempId) {
        return holder
      }
    }
  }
  return null
}

export const getProformaIssuanceLoading = (state: IGlobalState) => state?.issuanceView?.proformaIssuance.loading
