import { IGlobalState } from '../../rootReducer'

export const getFilters = (state: IGlobalState) => state.transfersView.filters

export const getTransferDetailsLoading = (state: IGlobalState) => state.transfersView.transferDetailsLoading
export const getTransferDetails = (state: IGlobalState) => state.transfersView.transferDetails
export const getCreateBulkTransferLoading = (state: IGlobalState) => state.transfersView.createBulkTrasferLoading
export const getStockTypeOptions = (state: IGlobalState) => state.transfersView.stockTypeOptions

export const getHistoricLedgerImportDraft = (state: IGlobalState) => state.transfersView.historicTransfersDraft

export const getHistoricTransferDetails = (state: IGlobalState) => state.transfersView.historicTransferDetails
export const getHistoricTransferDetailsLoading = (state: IGlobalState) =>
  state.transfersView.historicTransferDetailsLoading
export const saveHistoricTransferLoading = (state: IGlobalState) => state.transfersView.saveHistoricTransferLoading

export const getNewTransferDraftSelector = (state: IGlobalState) => state.transfersView.newTransferDraft
export const getTransferDraftLoading = (state: IGlobalState) => state.transfersView.transferDraftLoading
export const getTransferDraftLoaded = (state: IGlobalState) => state.transfersView.transferDraftLoaded

export const getTreasuryStockAccount = (state: IGlobalState) => state.common.ledgerBasicDetails.treasuryStockAccount

export const getTreasury = (state: IGlobalState) => state.common.ledgerBasicDetails.treasury

export const getBulkImportDraftLoading = (state: IGlobalState) => state.transfersView.bulkImportDraftLoading
export const getBulkImportDraftSelector = (state: IGlobalState) => state.transfersView.bulkImportDraft
export const getBulkImportDraftExists = (state: IGlobalState) =>
  state.transfersView.bulkImportDraft.holders.length > 0 && state.transfersView.bulkImportDraft.transfers.length > 0

export const getCancelledTransferDraftLoading = (state: IGlobalState) =>
  state.transfersView.cancelledTransferDraftLoading
export const getCancelledTransferDraftSelector = (state: IGlobalState) => state.transfersView.cancelledTransferDraft

export const getCreateTransferLoading = (state: IGlobalState) => state.transfersView.loading

export const getTransferListLoading = (state: IGlobalState) => state.transfersView.transfersLoading
export const getFilteredTransferListSelector = (state: IGlobalState) => {
  let filteredTransferList = state.transfersView.transfers
  const searchTerm = state.transfersView.filters.searchTerm.toLowerCase()
  const stockType = state.transfersView.filters.stockType.toLowerCase()

  if (searchTerm) {
    filteredTransferList = filteredTransferList.filter(transfer => {
      const fromName = transfer.fromAccountName.toLowerCase()
      const toName = transfer.toAccountName.toLowerCase()
      const fromEmails = transfer.from?.emails?.map(email => email.toLowerCase()) || []
      const fromPhoneNumbers = transfer.from?.phoneNumbers?.map(phoneNumer => phoneNumer.toLowerCase()) || []
      const toEmails = transfer.to?.emails?.map(email => email.toLowerCase()) || []
      const toPhoneNumbers = transfer.to?.phoneNumbers?.map(phoneNumer => phoneNumer.toLowerCase()) || []

      return (
        fromName.indexOf(searchTerm) !== -1 ||
        toName.indexOf(searchTerm) !== -1 ||
        fromEmails.find(email => email.indexOf(searchTerm) !== -1)?.length ||
        fromPhoneNumbers.find(phoneNumber => phoneNumber.indexOf(searchTerm) !== -1)?.length ||
        toEmails.find(email => email.indexOf(searchTerm) !== -1)?.length ||
        toPhoneNumbers.find(phoneNumber => phoneNumber.indexOf(searchTerm) !== -1)?.length
      )
    })
  }

  if (stockType) {
    filteredTransferList = filteredTransferList.filter(transfer => {
      const stockClassName = transfer.stockClassName.toLowerCase()
      return stockClassName.indexOf(stockType) !== -1
    })
  }

  return filteredTransferList
}

export const getTransferorStockList = (state: IGlobalState) => state.transfersView.transferorStockList
export const getTransferorStockListLoading = (state: IGlobalState) => state.transfersView.transferorStockListLoading
