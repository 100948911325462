import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { BaseComponent } from 'components/BaseComponent'
import { paths } from './routePaths'

export interface IRouteGuard {
  /**
   * If the condition is not met then either redirect to onFail or don't render the route
   */
  failCondition: boolean
  /**
   * If request is still in progress we don't want to call onFail yet
   */
  requestDone: boolean
  /**
   * URL where to redirect to, when condition is not met
   */
  onFail?: string | null
}

interface IPrivateRouteProps extends RouteProps {
  guards: IRouteGuard[]
}

class PrivateRoute extends BaseComponent<IPrivateRouteProps> {
  /*
        Decide what to render into the route
     */
  getRenderer = (guards: IRouteGuard[], Component: any, props: any) => {
    for (const guard of guards) {
      if (!guard.requestDone) {
        // if guard request isn't done then render nothing and wait for requestDone to change
        return null
      } else if (props.history.action === 'POP' && props.location.pathname === paths.signing) {
        // If user is redirecting directly to paths.signing redirect to fail.
        // system uses props.history.action 'PUSH'
        return guard.onFail ? <Redirect to={guard.onFail} /> : null
      } else if (guard.failCondition) {
        // if guard request is done then check if failCondition matches
        // and if it does then either redirect to onFail or display nothing
        return guard.onFail ? <Redirect to={guard.onFail} /> : null
      }
    }

    return <Component {...props} />
  }

  render(): JSX.Element {
    const { component, guards, ...rest } = this.props
    return <Route {...rest} render={props => this.getRenderer(guards, component, props)} />
  }
}

export default PrivateRoute
