import * as React from 'react'
import PhoneInput from 'react-phone-number-input'

import { formFieldTypes } from '../textfield/TextField'

import './PhoneNumberInput.scss'
import 'react-phone-number-input/style.css'

export interface IPhoneNumberInput {
  id?: string
  defaultCountry?: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
  label?: string
  disabled?: boolean
  isInvalid?: boolean
  fullInvalid?: boolean
  isRequired?: boolean
  autoFormat?: boolean
  formFieldStyle?: formFieldTypes
  onKeyDown?: (e: React.KeyboardEvent) => void
}

const PhoneNumberInput: React.FC<IPhoneNumberInput> = props => {
  const { defaultCountry, autoFormat, value, id, placeholder, onChange, disabled, onKeyDown, isInvalid, fullInvalid } =
    props

  return (
    <PhoneInput
      className={`ReactPhoneNumberInput ${isInvalid ? 'is-invalid' : ''} ${fullInvalid ? 'full-invalid' : ''}`}
      defaultCountry={defaultCountry || 'US'}
      international={autoFormat}
      value={value}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      onKeyDown={onKeyDown}
      limitMaxLength
    />
  )
}
export default PhoneNumberInput
