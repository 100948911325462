import * as React from 'react'
import Icon, { ICONS } from '../icon/Icon'
import { BaseComponent } from '../../BaseComponent'
import './Placeholder.scss'

export interface IPlaceholderProps {
  /**
   * The icon name.
   */
  icon: ICONS
  /**
   * Custom class
   */
  className?: string
  /**
   *  On inverted background
   */
  invert?: boolean
}

export default class Placeholder extends BaseComponent<IPlaceholderProps> {
  BEM(): string {
    const classArray = ['placeholder']

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    if (this.props.invert) {
      classArray.push('placeholder-invert')
    }

    return classArray.join(' ')
  }

  render(): JSX.Element {
    return (
      <div className={this.BEM()}>
        <Icon name={this.props.icon} className="placeholder__icon" />
        <p className="placeholder__text text-informative">{this.props.children}</p>
      </div>
    )
  }
}
