import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GetLedgerStockBalance, GetLedgerStockBalanceSuccess } from './VotingListViewActions'
import api from 'services/Api.services'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { Action } from 'redux-actions'
import { GetLedgerStockBalance$Request, GetLedgerStockBalance$Response } from 'services/api.types'

function* sagaGetVotingList(action: Action<GetLedgerStockBalance$Request>) {
  try {
    const result: GetLedgerStockBalance$Response = yield call(api.ledger.getLedgerStockBalance, {
      stateAt: action.payload.stateAt,
      voting: null,
    })
    yield put({ type: String(GetLedgerStockBalanceSuccess), payload: result.balance })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* votingListViewSagas() {
  yield all([takeEvery(String(GetLedgerStockBalance), sagaGetVotingList)])
}
