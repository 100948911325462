import {
  StockType,
  StockListDto,
  StockClassId,
  StockSeriesListDto,
  MyAccountStockListDto$StockItem,
} from 'services/api.types'
import i18n from 'i18next'
import { cloneDeep, get } from 'lodash'
import { strongPick } from 'common/utils/utilityTypes'
import { IOption } from 'components/ui/form/selectbox/SelectBox'

export interface IParentId {
  parentId?: string
}

export interface IStockListWithParentId extends StockListDto, IParentId {}

interface IStockListWihSeries extends StockListDto, StockSeriesListDto {}

export function getStockClassNameWithType(stockName: string, type: StockType, onlyStock: boolean = false): string {
  // todo :: refactoring ideas - can be simplified. Usage of i18n should be improved
  if (onlyStock) {
    return i18n.t('common:common-stock')
  }
  if (stockName && (stockName.toUpperCase() === 'COMMON STOCK' || stockName.toUpperCase() === 'PREFERRED STOCK')) {
    return stockName
  }

  if (stockName && (stockName.toUpperCase() === 'COMMON' || stockName.toUpperCase() === 'PREFERRED')) {
    return stockName + ' STOCK'
  }

  if (type === StockType.COMMON) {
    return i18n.t('common:common-stock-of', { name: stockName })
  }
  if (type === StockType.PREFERRED) {
    return i18n.t('common:preferred-stock-of', { name: stockName })
  }
  if (type === StockType.OTHER) {
    return i18n.t('common:other-stock-of', { name: stockName })
  }
  return stockName
}

export function getStockDetailsFromListMatchingID(stockList: StockListDto[], id: StockClassId): StockListDto {
  const result = stockList.find(stock => {
    return stock.id === id
  })
  return result
}

export function getPreferredStockForm(stock: StockListDto, series: StockSeriesListDto): IStockListWihSeries {
  const stockData = strongPick(stock, ['active', 'createdBy', 'firstInOrder', 'type'])
  return {
    ...stockData,
    ...series,
    series: [],
  }
}

export function sortStockListByType(
  stocks: Array<IStockListWithParentId | MyAccountStockListDto$StockItem | StockListDto>,
) {
  const sortingOrder = {
    [StockType.COMMON]: 1,
    [StockType.PREFERRED]: 2,
    [StockType.OTHER]: 3,
    [StockType.CAPITAL]: 4,
    default: 10,
  }
  const sortingFunction = (a: StockType, b: StockType) => {
    return (sortingOrder[a] || sortingOrder.default) - (sortingOrder[b] || sortingOrder.default)
  }
  const clonedStocks = cloneDeep(stocks)
  return clonedStocks.sort((a, b) => sortingFunction(a.type, b.type))
}

/**
 * Maps only eligible stocks and series to select options
 */
export function getStockListOptions(stockList: StockListDto[]): IOption[] {
  let parsedList: IOption[] = []

  if (stockList) {
    parsedList = stockList
      .filter(
        s =>
          s.active && (s.type !== StockType.PREFERRED || (s.type === StockType.PREFERRED && get(s, 'parentId', false))),
      )
      .map(s => ({ label: s.stockName, value: s.id }))
  }

  return parsedList
}

/**
 * Parse raw stocklist response to flat class list (stock series from special stock)
 */
export function getParsedStockList(stockList: StockListDto[] = []): StockListDto[] {
  const parsedStockList = cloneDeep(stockList)
  // Include series also
  stockList.forEach(s => {
    if (s?.series?.length) {
      s.series.forEach(ss => {
        parsedStockList.push({ ...s, ...ss })
      })
    }
  })
  return parsedStockList
}
