import { Action, handleActions } from 'redux-actions'
import { GetUserCredentials$Response } from 'services/api.types'
import { GetUserCredentials, GetUserCredentialsFail, GetUserCredentialsSuccess } from './CredentialsViewActions'

class State {
  error: boolean = false
  credentials: GetUserCredentials$Response = {
    emails: [],
    phones: [],
    keys: [],
  }
  credentialsIsLoading: boolean = false
  credentialsLoaded: boolean = false
}

export default handleActions<State, any>(
  {
    /* Get User Credentials */
    [String(GetUserCredentials)]: (state: State): State => {
      return {
        ...state,
        credentialsIsLoading: true,
      }
    },
    [String(GetUserCredentialsSuccess)]: (state: State, action: Action<GetUserCredentials$Response>): State => {
      return {
        ...state,
        credentialsIsLoading: false,
        credentialsLoaded: true,
        credentials: action.payload,
      }
    },
    [String(GetUserCredentialsFail)]: (state: State): State => {
      return {
        ...state,
        credentialsIsLoading: false,
      }
    },
  },
  new State(),
)

export { State as CredentialsViewState }
