import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'
import { default as Icon, ICONS } from '../icon/Icon'

import './Notification.scss'
import { getI18n } from 'react-i18next'

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum NotificationCode {
  APP_ERROR = 'APP_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  RATE_LIMIT_ERROR = 'TOO_MANY_REQUESTS_ERROR',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  DEPLOYMENT_IN_PROGRESS = 'DEPLOYMENT_IN_PROGRESS',
  AUTHENTICATION_EXPIRED = 'auth-expired',
}

export interface INotificationProps {
  /**
   * Id of notification
   */
  id?: number
  /**
   * Notification type
   */
  type: NotificationType

  translationKey?: string
  /**
   * Notification text
   */
  text?: string
  /**
   * Additional classes.
   */
  className?: string
  /**
   * Message icon
   */
  icon?: ICONS
  /**
   * Allow html in text
   */
  allowHtml?: boolean
  /**
   * Callback function for removeNotification
   */
  removeNotification?: (id: number) => void
  children?: any
}

class Notification extends BaseComponent<INotificationProps> {
  BEM(): string {
    const classArray = ['notification']

    if (this.props.type) {
      classArray.push(`notification--${this.props.type.toLowerCase()}`)
    }
    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  render(): JSX.Element {
    const { allowHtml, children } = this.props
    const text = this.props.translationKey
      ? getI18n().exists(`backendErrors:${this.props.translationKey}`)
        ? getI18n().t(this.props.translationKey, { ns: 'backendErrors' })
        : this.props.text
      : this.props.text

    return (
      <div className={this.BEM()}>
        {this.props.icon && (
          <div className="notification__icon">
            <Icon name={this.props.icon} />
          </div>
        )}
        <div className="notification__content">
          {text && allowHtml && <p className="notification__text" dangerouslySetInnerHTML={{ __html: text }} />}
          {text && !allowHtml && <p className="notification__text">{text}</p>}
          {children}
        </div>
        {!!this.props.id && (
          <Icon
            className="notification__remove"
            name={ICONS.CROSS}
            onClick={() => this.props.removeNotification(this.props.id)}
          />
        )}
      </div>
    )
  }
}

export default Notification
