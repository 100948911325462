import { Action, handleActions } from 'redux-actions'
import {
  GetLedgerStockBalance,
  GetLedgerStockBalanceFailure,
  GetLedgerStockBalanceSuccess,
  SetFilters,
} from './VotingListViewActions'
import { LedgerBalanceDto } from 'services/api.types'
import { IOption } from 'components/ui/form/selectbox/SelectBox'
import { IVotingListDto } from './VotingListView'
import moment from 'moment'
import { ITime } from 'components/ui/form/time/Time'
import { SelectUserProfile } from 'common/auth/AuthActions'

export enum VotingPower {
  ALL = 'ALL',
  VOTING = 'VOTING',
  NON_VOTING = 'NON_VOTING',
}

export interface IFilters {
  stockType: IOption
  votingPower: VotingPower
  datePickerFocused: boolean
  date: moment.Moment
  time: ITime
}

export type partialFilters = Partial<IFilters>

class State {
  votingList: IVotingListDto[] = []
  filters: IFilters = {
    stockType: { label: 'All', value: '' },
    votingPower: VotingPower.ALL,
    datePickerFocused: false,
    date: null,
    time: {
      time: '',
      meridiem: '',
    },
  }
  listLoading: boolean
  listLoaded: boolean
}

const defaultState = new State()

// TODO: handle data transforms on saga level
function transformResult(payload: LedgerBalanceDto): IVotingListDto[] {
  const stockClasses = payload.stockClasses
  const votingList: IVotingListDto[] = []
  payload.balances.forEach(row => {
    const account = row.account
    const accountName = account.calculatedName
    const address = row.account.holders[0].address
    row.accountStockBalances.forEach(subRow => {
      const numberOfShares = subRow.shares
      const stockClass = stockClasses.filter(stock => {
        return stock.id === subRow.stockClassId
      })[0]
      const numberOfVotes = numberOfShares * stockClass.votingRights
      votingList.push({
        account,
        accountName,
        address,
        numberOfShares,
        numberOfVotes,
        stockClass,
      })
    })
  })
  return votingList
}

export default handleActions<State, any>(
  {
    [String(SelectUserProfile)]: (state: State): State => ({
      ...state,
      votingList: defaultState.votingList,
      filters: defaultState.filters,
    }),
    [String(GetLedgerStockBalance)]: (state: State): State => {
      return {
        ...state,
        listLoading: true,
        listLoaded: false,
      }
    },
    [String(GetLedgerStockBalanceSuccess)]: (state: State, action: Action<LedgerBalanceDto>): State => {
      return {
        ...state,
        listLoading: false,
        listLoaded: true,
        votingList: action.payload ? transformResult(action.payload) : [],
      }
    },
    [String(GetLedgerStockBalanceFailure)]: (state: State): State => {
      return {
        ...state,
        listLoading: false,
        listLoaded: true,
      }
    },
    [String(SetFilters)]: (state: State, action: Action<partialFilters>): State => {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    },
  },

  new State(),
)

export { State as VotingListViewState }
