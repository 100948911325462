import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

datadogLogs.init({
  clientToken: 'pub17faa474761d5ec1cb03ac9a2323241d',
  site: 'datadoghq.com',
  service: 'browser',
  forwardConsoleLogs: ['error', 'warn', 'log'],
  forwardErrorsToLogs: true,
  sampleRate: 100,
  telemetrySampleRate: 0,
})

datadogRum.init({
  applicationId: '0eb219b9-40eb-4df7-aafe-356623668c49',
  clientToken: 'pub0a5f317704af06b9ca67aaa79b673215',
  site: 'datadoghq.com',
  service: 'stocktreasury-frontend-legacy',
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: false,
  defaultPrivacyLevel: 'mask-user-input',
})

export { datadogLogs, datadogRum }
