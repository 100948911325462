import * as React from 'react'
import { BaseComponent } from '../../../BaseComponent'

import './Helper.scss'

export interface IHelperProps {
  /**
   * The text of message.
   */
  text: string
  /**
   * Type of the message.
   */
  type: 'error' | 'success' | 'info' | 'warning'
  /**
   * className.
   */
  className?: string
}

class Helper extends BaseComponent<IHelperProps> {
  constructor(props: IHelperProps) {
    super(props)
  }

  BEM(): string {
    const classArray = ['helper']

    if (this.props.type) {
      classArray.push('helper--' + this.props.type)
    }

    if (this.props.className) {
      classArray.push(this.props.className)
    }

    return classArray.join(' ')
  }

  render(): JSX.Element {
    return <div className={this.BEM()}>{this.props.text}</div>
  }
}

export default Helper
