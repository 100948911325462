import axios from 'axios'
import { stringify } from 'qs'
import { storeAndPersistor } from '../index'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { apiInvoked } from 'common/auth/AuthActions'

import logger from 'services/logger'
import mixpanel from 'vendor/mixpanel'

const API_NAMES_EXCLUDE_DATA_LOGGING = ['login', 'changePassword', 'createPassword', 'resetPassword']

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api/methods',
  timeout: 100000,
  paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
})

ApiClient.interceptors.request.use(
  config => {
    config.params = {
      ...config.params,
      _: new Date().getTime(),
    }

    let data = config.data

    try {
      data = JSON.parse(data)
    } catch (_) {
      // No op
    }

    if (API_NAMES_EXCLUDE_DATA_LOGGING.includes(config.url)) {
      data = '[CENSORED]'
    }

    // Skip dry-run requests for POST when there's no data
    if (config.method?.toUpperCase() !== 'POST' || data != null) {
      logger.info(`HTTP request ${config.method?.toUpperCase()} ${config.url}`, {
        params: config.params ?? {},
        data,
      })
      mixpanel.track('RPC Call', {
        method: config.url,
      })
    }

    if (config && !config.url.toLowerCase().includes('logout')) {
      storeAndPersistor.store.dispatch({
        type: String(apiInvoked),
      })
    }

    return config
  },
  error => {
    if ('config' in error) {
      // ^ Axios HTTP error
      logger.error('HTTP Response Axios error', {
        error: { name: error.name, message: error.message },
        requestURL: error.config?.url,
      })
    } else {
      logger.error('HTTP Response error', { error })
    }
    return Promise.reject(error)
  },
)

ApiClient.interceptors.response.use(
  response => {
    logger.info(`HTTP response ${response.config.method?.toUpperCase()} ${response.config.url}`, {
      status: response.status ?? null,
      data: response.data ?? {},
      params: response.config.params ?? {},
    })
    /**
     * If response header has 'content-disposition' return full response with headers.
     * 'content-disposition' is only returned on file download.
     */
    const contentDisposition = response.headers && response.headers['content-disposition']
    if (contentDisposition) {
      return response
    }
    return response.data
  },
  error => {
    if ('config' in error) {
      // ^ Axios HTTP error
      logger.error('HTTP Response Axios error', {
        error: { name: error.name, message: error.message },
        response: error.response?.data ?? {},
        requestURL: error.config?.url,
      })
    } else {
      logger.error('HTTP Response error', { error })
    }

    if (error.response.data.message != null) {
      // This is temporary to see if the previous key being used for message
      // is returned by some API endpoints at all
      logger.warning('Found HTTP response under alternative key', {
        key: 'error.response.data.message',
        expectedKey: 'error.response.data.description',
      })
    }

    const code = error.response.data.code
    const message = error.response.data.description
    const translationKey = error.response.data.translationKey
    if (translationKey === 'error-ledger-subscription-expired') {
      const notification: INotification = {
        ...error.response.data,
        type: NotificationType.INFO,
      }
      storeAndPersistor.store.dispatch({
        type: String(addNotification),
        payload: notification,
      })
    }

    return Promise.reject(error)
  },
)

export default ApiClient
