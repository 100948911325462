import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import mixpanel from 'vendor/mixpanel'

import {
  apiInvoked,
  getUserAccounts,
  getUserAccountsFailure,
  getUserAccountsSuccess,
  GetUserLedgers,
  GetUserLedgersFail,
  GetUserLedgersSuccess,
  PollRecoveryBySmsStatus,
  PollRecoveryBySmsStatusSuccess,
  SelectUserProfile,
  SelectUserProfileFailure,
  SelectUserProfileSuccess,
  sessionExpired,
  whoAmI,
  whoAmIFailure,
  whoAmISuccess,
} from './AuthActions'
import {
  GetAvailableProfiles$Response,
  PollRecoveryBySmsStatus$Response,
  ProfileRole,
  SelectProfile$Request,
  WhoAmI$Response,
  CredentialDto,
  CredentialType,
  GetMyAccounts$Response,
  GetUserCredentials$Response,
} from 'services/api.types'
import RoleUtil from 'common/utils/roleUtil'
import { Action } from 'redux-actions'
import { push } from 'connected-react-router'
import { paths } from '../router/routePaths'
import {
  AddDashboardNotification,
  GetDashboard,
  GetDashboardSuccess,
  GetDrafts,
  GetDraftsSuccess,
} from 'views/dashboard/DashboardViewActions'
import { getCurrentPath } from '../router/RouterSelectors'
import api from 'services/Api.services'
import LS from 'common/utils/localStorage'
import { authenticated, userDetails } from './AuthSelectors'
import { USER_CHANGE } from '../constants/localstorage-keys'
import SessionHelper from './SessionHelper'
import { LogOut } from 'views/login/LoginViewActions'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { GetUserCredentialsSuccess } from 'views/settings/children/credentials/CredentialsViewActions'
import { getUserCredentials } from 'views/settings/children/credentials/CredentialsViewSelectors'
import { getFullName } from 'common/utils/nameUtil'
import {
  GetLedgerBasicDetails,
  GetLedgerBasicDetailsSuccess,
  GetLedgerDetails,
  GetLedgerDetailsSuccess,
} from 'common/store/common/commonAction'
import { GetStockList, GetStockListSuccess } from 'common/store/stocks/stocksAction'
import { getIsHistoricImportMode } from 'common/store/common/commonSelector'
import { setContext } from 'services/logger'

declare const window: any

/*
 * Fetch whoAmI data from API
 */
export function* sagaWhoAmI() {
  const oldWhoAmIState: WhoAmI$Response = yield select(userDetails)
  let whoAmIState: WhoAmI$Response
  try {
    whoAmIState = yield call(api.auth.whoAmI)

    if (whoAmIState.authenticated && whoAmIState.userId != null) {
      const { userId } = whoAmIState
      if (userId.length > 0) {
        mixpanel.identify(userId)
        setContext({ userId })
      } else {
        mixpanel.reset()
        setContext({ userId: null })
      }
    }

    if (whoAmIState.authenticated) {
      yield put({ type: String(GetLedgerBasicDetails) })
      yield put({ type: String(GetDashboard) })
      yield put({ type: String(GetUserLedgers) })
      yield take([GetLedgerBasicDetailsSuccess, GetDashboardSuccess, GetUserLedgersSuccess])

      if (
        whoAmIState.currentProfile &&
        RoleUtil.matchIdentical([ProfileRole.LEDGER_ADMIN], [whoAmIState.currentProfile.role])
      ) {
        yield put({ type: String(GetLedgerDetails) })
        yield take(GetLedgerDetailsSuccess)
      }

      if (
        whoAmIState.currentProfile &&
        RoleUtil.matchIdentical([ProfileRole.HOLDER], [whoAmIState.currentProfile.role])
      ) {
        yield put({ type: String(getUserAccounts) })
        yield take(getUserAccountsSuccess)
      }

      const historicImportMode: boolean = yield select(getIsHistoricImportMode)
      if (whoAmIState.currentProfile && historicImportMode) {
        yield put({ type: String(GetStockList) })
        yield take(GetStockListSuccess)
      }
    }

    yield put({ type: String(whoAmISuccess), payload: whoAmIState })
  } catch (e) {
    yield put({ type: String(whoAmIFailure), payload: e.message })
  } finally {
    // Generate user change event if user/profile changes
    const oldValueUserId: string = oldWhoAmIState && oldWhoAmIState.userId ? oldWhoAmIState.userId : ''
    const oldValueProfileId: string =
      oldWhoAmIState && oldWhoAmIState.currentProfile && oldWhoAmIState.currentProfile.id
        ? oldWhoAmIState.currentProfile.id
        : ''
    const oldValue: string = oldValueUserId + oldValueProfileId
    const newValueUserId: string = whoAmIState && whoAmIState.userId ? whoAmIState.userId : ''
    const newValueProfileId: string =
      whoAmIState && whoAmIState.currentProfile && whoAmIState.currentProfile.id ? whoAmIState.currentProfile.id : ''
    const newValue: string = newValueUserId + newValueProfileId

    if (!newValue || oldValue !== newValue) {
      LS.put(USER_CHANGE, newValue)
    }
  }
}

export function* sagaGetUserAccounts() {
  try {
    const userAccounts: GetMyAccounts$Response = yield call(api.ledger.getMyAccounts)
    yield put({ type: String(getUserAccountsSuccess), payload: userAccounts })
  } catch (e) {
    yield put({ type: String(getUserAccountsFailure), payload: e.message })
  }
}

function* sagaGetUserLedgers() {
  try {
    const userLedgers: GetAvailableProfiles$Response = yield call(api.user.getAvailableProfiles)
    yield put({ type: String(GetUserLedgersSuccess), payload: userLedgers.ledgers })
  } catch (e) {
    console.log('sagaGetUserLedgers failed:', e)
    yield put({ type: String(GetUserLedgersFail) })
  }
}

function* sagaSelectUserProfile(action: Action<SelectProfile$Request>) {
  try {
    LS.clear()
    yield call(api.user.selectProfile, action.payload)
    yield put({ type: String(whoAmI) })
    yield take(whoAmISuccess)
    const whoAmIState: WhoAmI$Response = yield select(userDetails)

    if (
      whoAmIState.currentProfile &&
      RoleUtil.matchIdentical([ProfileRole.LEDGER_ADMIN], [whoAmIState.currentProfile.role])
    ) {
      yield put({ type: String(GetLedgerDetails) })
      yield take(GetLedgerDetailsSuccess)
    }

    if (whoAmIState.currentProfile) {
      yield put({ type: String(GetDrafts) })
      yield take(GetDraftsSuccess)
    }

    yield put({
      type: String(AddDashboardNotification),
      payload: {
        title: getI18n().t(
          whoAmIState.currentProfile.role === ProfileRole.LEDGER_ADMIN
            ? 'success.profile-changed-to-admin'
            : 'success.profile-changed-to-holder',
          {
            ns: 'frontendNotifications',
            profileName: whoAmIState.currentProfile.name,
          },
        ),
      },
    })

    const currentPath: string = yield select(getCurrentPath)

    if (currentPath !== paths.dashboard.root) {
      yield put(push(paths.dashboard.root))
    }
    yield put({ type: String(SelectUserProfileSuccess) })
  } catch (e) {
    yield put({ type: String(SelectUserProfileFailure) })
  }
}

function* sagaPollRecoveryBySmsStatus() {
  const result: PollRecoveryBySmsStatus$Response = yield call(api.auth.pollRecoveryBySmsStatus)
  yield put({ type: String(PollRecoveryBySmsStatusSuccess), payload: result })
}

function* sagaApiInvoked() {
  try {
    const loggedIn: boolean = yield select(authenticated)
    if (loggedIn) {
      SessionHelper.setExpiry()
      SessionHelper.resetExpiryTimeout()
    }
  } catch (e) {}
}

function* sagaSessionExpired() {
  try {
    const loggedIn: boolean = yield select(authenticated)
    if (loggedIn) {
      const payload: INotification = {
        description: getI18n().t('common:messages.session-expired'),
        type: NotificationType.INFO,
      }
      yield put({ type: String(LogOut), payload })
    }
  } catch (e) {
    console.log('sagaSessionExpired failed: ', e)
  }
}

function* sagaUserComConfigure() {
  while (true) {
    yield take(whoAmISuccess)
    const loggedIn: boolean = yield select(authenticated)

    if (!window.usercomConfig) {
      window.usercomConfig = { apiKey: 'qBaOT8' }
    }

    if (loggedIn) {
      yield take(GetUserCredentialsSuccess)
      const userCredentials: GetUserCredentials$Response = yield select(getUserCredentials)
      const userInfo: WhoAmI$Response = yield select(userDetails)
      const emailCredential = userCredentials.emails.find(
        (credential: CredentialDto) => credential.verified && credential.type === CredentialType.EMAIL,
      )

      window.usercomConfig.user_id = userInfo.userId
      window.usercomConfig.email = emailCredential && emailCredential.value ? emailCredential.value : ''
      window.usercomConfig.name = getFullName({
        givenNames: userInfo.givenNames,
        lastName: userInfo.lastName,
        middleName: userInfo.middleName,
        nameSuffix: userInfo.nameSuffix,
      })
      window.usercomConfig.subscriber = true
      window.civchat = window.usercomConfig
    } else {
      window.usercomConfig.subscriber = false
    }

    try {
      window.UE.resetAuth(window.usercomConfig)
    } catch (e) {}
  }
}

export default function* authSagas() {
  yield all([
    sagaUserComConfigure(),
    takeLatest(String(whoAmI), sagaWhoAmI),
    takeLatest(String(getUserAccounts), sagaGetUserAccounts),
    takeLatest(String(GetUserLedgers), sagaGetUserLedgers),
    takeLatest(String(SelectUserProfile), sagaSelectUserProfile),
    takeLatest(String(PollRecoveryBySmsStatus), sagaPollRecoveryBySmsStatus),
    takeLatest(String(apiInvoked), sagaApiInvoked),
    takeLatest(String(sessionExpired), sagaSessionExpired),
  ])
}
