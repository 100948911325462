import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'

import './GlobalNotification.scss'
import * as classNames from 'classnames'
import Container from 'components/ui/container/Container'
import Icon, { ICONS } from 'components/ui/icon/Icon'

export enum GlobalNotificationIntent {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
}

export interface IGlobalNotificationProps {
  intent: GlobalNotificationIntent
  text: string | React.ReactNode
  icon?: ICONS | null
}

class GlobalNotification extends BaseComponent<IGlobalNotificationProps> {
  constructor(props: IGlobalNotificationProps) {
    super(props)
  }

  renderIcon() {
    const { icon, intent } = this.props
    if (icon === null) {
      return null
    }

    return (
      <Icon
        name={icon === undefined ? (intent === GlobalNotificationIntent.SUCCESS ? ICONS.CHECK : ICONS.INFO) : icon}
      />
    )
  }

  public render() {
    const { intent, text } = this.props
    return (
      <div className={classNames('global-notification', `global-notification--intent-${intent}`)}>
        <Container>
          {this.renderIcon()}
          <span>{text}</span>
        </Container>
      </div>
    )
  }
}

export default GlobalNotification
