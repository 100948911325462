import { all, call, put, select, take, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import { delay } from 'redux-saga'
import { push } from 'connected-react-router'
import { Action } from 'redux-actions'
import { paths } from 'common/router/routePaths'
import {
  CancelSigning,
  ClearSigningData,
  CompleteSigning,
  GetSigningStatus,
  InitRecoverBySms,
  SetSigningUrls,
  SetStatus,
  Sign,
} from './SigningViewActions'
import {
  getActions,
  getIsInitial,
  getSignActionId,
  getUrls,
  ISigningViewActions,
  ISigningViewUrls,
} from './SigningViewSelectors'
import { SigningViewState } from '../signing/SigningViewReducer'
import {
  PollSignActionStatus$Request,
  PollSignActionStatus$Response,
  PollSignActionStatus$Status,
  SignActionId,
} from 'services/api.types'
import { AddDashboardNotification, GetDashboard } from '../dashboard/DashboardViewActions'
import { PollRecoveryBySmsStatus, PollRecoveryBySmsStatusSuccess, whoAmI, whoAmISuccess } from 'common/auth/AuthActions'
import api from 'services/Api.services'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { DashboardNotificationType } from '../dashboard/children/notification/DashboardNotification'
import { StartRecoverBySms } from '../recovery-sms/RecoverySmsViewActions'

function* sagaSign(action: Action<SigningViewState>) {
  window.scrollTo(0, 0)
  // if no urls/actions are given then set fallback options
  const defaultDeniedAction = {
    type: String(AddDashboardNotification),
    payload: {
      title: i18n.t('signingView:default.denied.title'),
      text: i18n.t('signingView:default.denied.text'),
      type: DashboardNotificationType.ERROR,
    },
  }
  const defaultExpiredAction = {
    type: String(AddDashboardNotification),
    payload: {
      title: i18n.t('signingView:default.expired.title'),
      text: i18n.t('signingView:default.expired.text'),
      type: DashboardNotificationType.ERROR,
    },
  }
  const defaultSuccessAction = {
    type: String(AddDashboardNotification),
    payload: {
      title: i18n.t('signingView:default.success.title'),
      text: i18n.t('signingView:default.success.text'),
    },
  }
  const defaultCancelledAction = {
    type: String(AddDashboardNotification),
    payload: {
      title: i18n.t('signingView:default.cancel.title'),
      text: i18n.t('signingView:default.cancel.text'),
      type: DashboardNotificationType.ERROR,
    },
  }
  action.payload.signedUrl = action.payload.signedUrl || paths.dashboard.root
  action.payload.cancelledUrl = action.payload.cancelledUrl || paths.dashboard.root
  action.payload.expiredUrl = action.payload.expiredUrl || paths.dashboard.root
  action.payload.deniedUrl = action.payload.deniedUrl || paths.dashboard.root
  action.payload.expiredAction = action.payload.expiredAction || defaultExpiredAction
  action.payload.deniedAction = action.payload.deniedAction || defaultDeniedAction
  action.payload.signedAction = action.payload.signedAction || defaultSuccessAction
  action.payload.cancelledAction = action.payload.cancelledAction || defaultCancelledAction
  action.payload.isInitial = action.payload.isInitial || false
  // Set urls to store
  yield put({
    type: String(SetSigningUrls),
    payload: action.payload,
  })

  // Change route to signing view.
  yield put(push(paths.signing))

  // Get status of signing.
  yield put({ type: String(GetSigningStatus), payload: { id: action.payload.signActionId } })
}

function* sagaGetSigningStatus(action: Action<PollSignActionStatus$Request>) {
  const actions: ISigningViewActions = yield select(getActions)
  const urls: ISigningViewUrls = yield select(getUrls)
  const isInititialOnboarding: boolean = yield select(getIsInitial)

  // Polling till get one of the statuses back (SIGNED/DENIED/EXPIRED/CANCELLED)
  try {
    let i = 0
    // Arbitrary limit to avoid infinite loop.
    // 50 because in BE the signing request will expire in 90s
    while (i < 50) {
      const signActionId: string = yield select(getSignActionId)

      if (signActionId !== action.payload.id) {
        // If there is new signActionId then stop polling
        break
      }

      const response: PollSignActionStatus$Response = yield call(api.auth.pollSignActionStatus, action.payload)
      yield put({ type: String(SetStatus), payload: response })
      yield call(delay, 2000)
      if (response.status === PollSignActionStatus$Status.SIGNED) {
        yield put({
          type: String(CompleteSigning),
          payload: {
            act: actions.signedAction,
            url: urls.signedUrl,
            state: { onboardingStep: isInititialOnboarding ? 'INITIAL-COMPLETED' : undefined },
          },
        })

        // If cleanAction is set, call it.
        if (actions.cleanAction) {
          yield put(actions.cleanAction)
        }
        return
      } else if (response.status === PollSignActionStatus$Status.DENIED) {
        yield put({
          type: String(CompleteSigning),
          payload: { act: actions.deniedAction, url: urls.deniedUrl },
        })
        return
      } else if (response.status === PollSignActionStatus$Status.EXPIRED) {
        yield put({
          type: String(CompleteSigning),
          payload: { act: actions.expiredAction, url: urls.expiredUrl },
        })
        return
      } else if (response.status === PollSignActionStatus$Status.CANCELLED) {
        // when signing was cancelled then we can just exit from polling
        // because we need to change route and show message instantaneously, we can't call complete signing in the poll
        return
      }
      i++
    }
    return
  } catch (e) {
    yield put({ type: String(SetStatus), payload: PollSignActionStatus$Status.DENIED })
    yield call(delay, 2000)
    yield put(push(urls.deniedUrl))
    yield put(actions.deniedAction)
    // Clear signing view from data.
    yield put({ type: String(ClearSigningData) })
  }
}

function* sagaCompleteSigning(action: Action<{ url: string; act: any; state?: any }>) {
  const { url, act, state } = action.payload
  if (url !== paths.login && url !== paths.recoverySms) {
    yield put({ type: String(GetDashboard) })
    yield put({ type: String(whoAmI) })
    // Wait whoAmI to success
    yield take(String(whoAmISuccess))
  }

  if (act.type === String(addNotification)) {
    yield put(push(url, { notifications: [act.payload], ...state }))
  } else {
    yield put(push(url, { ...state }))
    yield put(act)
  }

  yield put({ type: String(ClearSigningData) })
}

function* sagaCancelSigning() {
  const id: SignActionId = yield select(getSignActionId)
  const actions: ISigningViewActions = yield select(getActions)
  const urls: ISigningViewUrls = yield select(getUrls)
  if (id) {
    try {
      yield call(api.auth.cancelSignAction, { id })
      yield put({
        type: String(CompleteSigning),
        payload: { act: actions.cancelledAction, url: urls.cancelledUrl },
      })
    } catch (e) {
      yield put({ type: String(SetStatus), payload: PollSignActionStatus$Status.DENIED })
      yield put({ type: String(ClearSigningData) })
    }
  }
}

function* sagaStartRecoverBySms() {
  const id: SignActionId = yield select(getSignActionId)
  yield call(api.auth.cancelSignAction, { id })
  yield call(api.auth.startRecoverBySms)

  yield put({ type: String(PollRecoveryBySmsStatus) })
  yield take(String(PollRecoveryBySmsStatusSuccess))

  yield put({
    type: String(StartRecoverBySms),
    payload: {
      signActionId: id,
      errorUrl: paths.login,
      successUrl: paths.login,
    },
  })
  yield put({ type: String(ClearSigningData) })
}

export default function* verificationViewSagas() {
  yield all([
    takeEvery(String(Sign), sagaSign),
    takeEvery(String(CancelSigning), sagaCancelSigning),
    takeEvery(String(InitRecoverBySms), sagaStartRecoverBySms),
    takeEvery(String(GetSigningStatus), sagaGetSigningStatus),
    takeEvery(String(CompleteSigning), sagaCompleteSigning),
  ])
}
