import { createAction } from 'redux-actions'

const ns = 'settings/'

/* Change settings view */
export const ChangeView = createAction(`${ns}CHANGE_VIEW`)

export const GetLedgerUserList = createAction(`${ns}GET_LEDGER_USER_LIST`)
export const GetLedgerUserListSuccess = createAction(`${ns}GET_LEDGER_USER_LIST_SUCCESS`)
export const GetLedgerUserListFail = createAction(`${ns}GET_LEDGER_USER_LIST_FAIL`)

export const GetPendingLedgerUserList = createAction(`${ns}GET_PENDING_LEDGER_USER_LIST`)
export const GetPendingLedgerUserListSuccess = createAction(`${ns}GET_PENDING_LEDGER_USER_LIST_SUCCESS`)
export const GetPendingLedgerUserListFail = createAction(`${ns}GET_PENDING_LEDGER_USER_LIST_FAIL`)

export const RevokePendingLedgerAdmin = createAction(`${ns}REVOKE_PENDING_LEDGER_ADMIN`)

export const DeactivateProfile = createAction(`${ns}DEACTIVATE_PROFILE`)

export const ReactivateProfile = createAction(`${ns}REACTIVATE_PROFILE`)

export const AddLedgerAdmin = createAction(`${ns}ADD_LEDGER_ADMIN`)

export const GetContractList = createAction(`${ns}GET_CONTRACT_LIST`)
export const GetContractListSuccess = createAction(`${ns}GET_CONTRACT_LIST_SUCCESS`)
