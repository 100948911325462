import React from 'react'
import Container from 'components/ui/container/Container'
import Button from 'components/ui/button/Button'
import GroupItem from 'components/ui/group-items/GroupItem'
import GroupItems from 'components/ui/group-items/GroupItems'
import Icon, { ICONS } from 'components/ui/icon/Icon'

import Sentry from 'vendor/sentry'
import logger from 'services/logger'
import { storeAndPersistor } from '../../../index'

import './ErrorBoundaryFallback.scss'

const TITLE = 'Whoops'
const DESCRIPTION = `Something went wrong. We have already been notified and will get to work on fixing this. Try checking back again later.`

export default function ErrorBoundaryFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: () => void
}) {
  const [showError, setShowError] = React.useState(false)
  const handleButtonClick = React.useCallback(() => {
    setShowError(value => !value)
  }, [])

  React.useEffect(() => {
    logger.error('Fatal Error on Frontend', {
      location: window.location.href,
      error: {
        message: error?.message,
        name: error?.name,
        stack: error?.stack,
      },
      state: storeAndPersistor.store.getState(),
    })
    Sentry.captureException(error)
  }, [error])

  return (
    <div className="error-boundary-fallback-wrapper">
      <div className="error-boundary-fallback">
        <Container>
          <Icon name={ICONS.GLOBAL_ERROR} />
          <h2>{TITLE}</h2>
          <p>{DESCRIPTION}</p>
          <GroupItems className="mt-4" align="center" inline={true}>
            <GroupItem>
              <Button
                variant="main"
                text={showError ? 'Hide more information' : 'Show more information'}
                onClick={handleButtonClick}
              />
            </GroupItem>
            <GroupItem>
              <Button variant="red-border" text="Reset Webpage" onClick={resetErrorBoundary} />
            </GroupItem>
          </GroupItems>
          {showError ? <p>{error != null && error.message != null ? `Error: ${error.message}` : `${error}`}</p> : null}
        </Container>
      </div>
    </div>
  )
}
