import { createAction } from 'redux-actions'

const ns = 'common/'

export const GetLedgerBasicDetails = createAction(`${ns}GET_LEDGER_BASIC_DETAILS`)
export const GetLedgerBasicDetailsSuccess = createAction(`${ns}GET_LEDGER_BASIC_DETAILS_SUCCESS`)
export const GetLedgerBasicDetailsFail = createAction(`${ns}GET_LEDGER_BASIC_DETAILS_FAILURE`)

export const GetLedgerDetails = createAction(`${ns}GET_LEDGER_DETAILS`)
export const GetLedgerDetailsSuccess = createAction(`${ns}GET_LEDGER_DETAILS_SUCCESS`)
export const GetLedgerDetailsFail = createAction(`${ns}GET_LEDGER_DETAILS_FAILURE`)

export const SearchAccounts = createAction(`${ns}SEARCH_HOLDER`, (searchText: string) => searchText)
export const SearchAccountsSuccess = createAction(`${ns}SEARCH_HOLDER_SUCCESS`)
export const SearchAccountsFail = createAction(`${ns}SEARCH_HOLDER_FAIL`)

export const SearchDraftAccounts = createAction(`${ns}SEARCH_DRAFT_HOLDER`, (searchText: string) => searchText)
export const SearchDraftAccountsSuccess = createAction(`${ns}SEARCH_DRAFT_HOLDER_SUCCESS`)
export const SearchDraftAccountsFail = createAction(`${ns}SEARCH_DRAFT_HOLDER_FAIL`)

export const SearchAccountsWithStock = createAction(`${ns}SEARCH_HOLDER_WITH_STOCK`, (searchText: string) => searchText)
export const SearchAccountsWithStockSuccess = createAction(
  `${ns}SEARCH_HOLDER_WITH_STOCK_SUCCESS`,
  (searchText: string) => searchText,
)
export const SearchAccountsWithStockFail = createAction(
  `${ns}SEARCH_HOLDER_WITH_STOCK_FAIL`,
  (searchText: string) => searchText,
)
