import { createAction } from 'redux-actions'

const ns = 'votingList/'

/* Get Stock Holders / Holder List */
export const GetLedgerStockBalance = createAction(`${ns}GET_LEDGER_STOCK_BALANCE`)
export const GetLedgerStockBalanceSuccess = createAction(`${ns}GET_LEDGER_STOCK_BALANCE_SUCCESS`)
export const GetLedgerStockBalanceFailure = createAction(`${ns}GET_LEDGER_STOCK_BALANCE_FAILURE`)

export const SetFilters = createAction(`${ns}SET_FILTERS`)
