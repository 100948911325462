import { createAction } from 'redux-actions'

const ns = 'auth/'

export const whoAmI = createAction(`${ns}WHO_AM_I`)
export const whoAmISuccess = createAction(`${ns}WHO_AM_I_SUCCESS`)
export const whoAmIFailure = createAction(`${ns}WHO_AM_I_FAILURE`)

export const getUserAccounts = createAction(`${ns}GET_USER_ACCOUNTS`)
export const getUserAccountsSuccess = createAction(`${ns}GET_USER_ACCOUNTS_SUCCESS`)
export const getUserAccountsFailure = createAction(`${ns}GET_USER_ACCOUNTS_FAILURE`)

export const GetUserLedgers = createAction(`${ns}GET_USER_LEDGERS`)
export const GetUserLedgersFail = createAction(`${ns}GET_USER_LEDGERS_FAIL`)
export const GetUserLedgersSuccess = createAction(`${ns}GET_USER_LEDGERS_SUCCESS`)

export const SelectUserProfile = createAction(`${ns}SELECT_USER_PROFILE`)
export const SelectUserProfileSuccess = createAction(`${ns}SELECT_USER_PROFILE_SUCCESS`)
export const SelectUserProfileFailure = createAction(`${ns}SELECT_USER_PROFILE_FAILURE`)

export const PollRecoveryBySmsStatus = createAction(`${ns}POLL_REC_BY_SMS`)

export const StartRecoveryBySmsStatusSuccess = createAction(`${ns}START_REC_BY_SMS_SUCCESS`)
export const PollRecoveryBySmsStatusSuccess = createAction(`${ns}POLL_REC_BY_SMS_SUCCESS`)

export const apiInvoked = createAction(`${ns}API_INVOKED`)
export const sessionExpired = createAction(`${ns}SESSION_EXPIRED`)
