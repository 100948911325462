import { Action, handleActions } from 'redux-actions'
import {
  getUserAccountsSuccess,
  GetUserLedgersSuccess,
  PollRecoveryBySmsStatus,
  PollRecoveryBySmsStatusSuccess,
  SelectUserProfile,
  SelectUserProfileFailure,
  SelectUserProfileSuccess,
  StartRecoveryBySmsStatusSuccess,
  whoAmI,
  whoAmIFailure,
  whoAmISuccess,
} from './AuthActions'
import {
  GetMyAccounts$Account,
  GetMyAccounts$Response,
  LedgerWithProfilesDto,
  PollRecoveryBySmsStatus$Response,
  WhoAmI$Response,
} from 'services/api.types'

class State {
  whoAmI: WhoAmI$Response = {
    authenticated: false,
    hasProfiles: false,
    ledgerCreation: false,
    currentProfile: null,
    givenNames: '',
    middleName: '',
    lastName: '',
    nameSuffix: '',
    userId: null,
    lastLoginAt: null,
  }
  recoveryBySmsStatus: PollRecoveryBySmsStatus$Response = {
    status: null,
  }
  accounts: GetMyAccounts$Account[] = []
  userLedgers: LedgerWithProfilesDto[]
  loading: boolean = false
  loaded: boolean = false
  profileSelectLoading: boolean = false
  profileSelectLoaded: boolean = false
  error: boolean = false
}

export default handleActions<State, any>(
  {
    [String(whoAmI)]: (state: State): State => {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    },
    [String(whoAmISuccess)]: (state: State, action: Action<WhoAmI$Response>): State => {
      return {
        ...state,
        whoAmI: action.payload,
        loading: false,
        loaded: true,
        error: false,
      }
    },
    [String(whoAmIFailure)]: (state: State): State => {
      return {
        ...state,
        loading: false,
        error: true,
      }
    },
    [String(SelectUserProfile)]: (state: State): State => {
      return {
        ...state,
        profileSelectLoading: true,
        profileSelectLoaded: false,
        error: false,
      }
    },
    [String(SelectUserProfileSuccess)]: (state: State): State => {
      return {
        ...state,
        profileSelectLoading: false,
        profileSelectLoaded: true,
        error: false,
      }
    },
    [String(SelectUserProfileFailure)]: (state: State): State => {
      return {
        ...state,
        profileSelectLoading: false,
        error: true,
      }
    },
    [String(getUserAccountsSuccess)]: (state: State, action: Action<GetMyAccounts$Response>): State => {
      return {
        ...state,
        accounts: action.payload.accounts,
      }
    },
    [String(GetUserLedgersSuccess)]: (state: State, action: Action<LedgerWithProfilesDto[]>): State => {
      return {
        ...state,
        userLedgers: action.payload,
      }
    },
    [String(PollRecoveryBySmsStatus)]: (state: State): State => {
      return {
        ...state,
      }
    },
    [String(StartRecoveryBySmsStatusSuccess)]: (state: State): State => {
      return {
        ...state,
      }
    },
    [String(PollRecoveryBySmsStatusSuccess)]: (
      state: State,
      action: Action<PollRecoveryBySmsStatus$Response>,
    ): State => {
      return {
        ...state,
        recoveryBySmsStatus: action.payload,
      }
    },
  },
  new State(),
)

export { State as AuthState }
