import { all, takeLatest } from 'redux-saga/effects'
import { addNotification } from './NotificationActions'

export function* sagaAddNotification() {
  yield window.scrollTo({ top: 0, behavior: 'smooth' })
}

export default function* commonSaga() {
  yield all([takeLatest(String(addNotification), sagaAddNotification)])
}
