import { all, call, put, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import api from 'services/Api.services'
import {
  AddLegalEntityCompanyDetails$Request,
  GetLegalEntityCompanyDetails$Response,
  UpdateLegalEntityCompanyDetails$Request,
} from 'services/api.types'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification } from 'common/store/notifications/NotificationActions'
import {
  GetLegalEntityDetails,
  GetLegalEntityDetailsSuccess,
  GetLegalEntityDetailsFail,
  AddLegalEntityDetails,
  UpdateLegalEntityDetails,
} from './LegalEntityFormActions'
import { AddDashboardNotification, GetDashboard } from 'views/dashboard/DashboardViewActions'
import { whoAmI } from 'common/auth/AuthActions'
import { Action } from 'redux-actions'
import { ILegalHolderFields } from 'components/functional/new-holder/NewHolderFieldNames'
import { push } from 'connected-react-router'
import { paths } from 'common/router/routePaths'
import { NotificationType } from 'components/ui/notification/Notification'

function* sagaGetLegalEntityDetails() {
  try {
    const legalEntityCompanyDetails: GetLegalEntityCompanyDetails$Response = yield call(
      api.ledger.getLegalEntityCompanyDetails,
    )

    const payload: ILegalHolderFields = {
      address: legalEntityCompanyDetails.officeAddress,
      name: legalEntityCompanyDetails.name,
      email: '',
      tin: legalEntityCompanyDetails.tin,
      holderId: legalEntityCompanyDetails.holderId,
      accountId: null,
    }

    yield put({ type: String(GetLegalEntityDetailsSuccess), payload })
  } catch (e) {
    yield put({ type: String(GetLegalEntityDetailsFail) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaUpdateLegalEntityDetails(action: Action<ILegalHolderFields>) {
  try {
    const payload: UpdateLegalEntityCompanyDetails$Request = {
      address: { ...action.payload.address, notEmpty: false },
      name: action.payload.name,
      apartment: '',
      streetAddress: '',
      tin: action.payload.tin,
    }

    yield call(api.ledger.updateLegalEntityCompanyDetails, payload)
    yield put({ type: String(whoAmI) })
    yield put({ type: String(GetDashboard) })

    yield put({
      type: String(AddDashboardNotification),
      payload: {
        title: i18n.t('frontendNotifications:success.update-legal-entity-details'),
      },
    })
    yield put(push(paths.dashboard.root))
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaAddLegalEntityDetails(action: Action<ILegalHolderFields>) {
  try {
    const payload: AddLegalEntityCompanyDetails$Request = {
      address: { ...action.payload.address, notEmpty: false },
      streetAddress: '',
      apartment: '',
      name: action.payload.name,
      tin: action.payload.tin,
    }

    yield call(api.ledger.addLegalEntityCompanyDetails, payload)

    yield put({ type: String(whoAmI) })
    yield put({ type: String(GetDashboard) })
    // set dashboard success message
    yield put({
      type: String(AddDashboardNotification),
      payload: {
        title: i18n.t('frontendNotifications:success.add-legal-entity-details'),
      },
    })
    yield put(push(paths.dashboard.root))
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* LegalEntityCompanySagas() {
  yield all([
    takeEvery(String(GetLegalEntityDetails), sagaGetLegalEntityDetails),
    takeEvery(String(UpdateLegalEntityDetails), sagaUpdateLegalEntityDetails),
    takeEvery(String(AddLegalEntityDetails), sagaAddLegalEntityDetails),
  ])
}
