import { IGlobalState } from '../../rootReducer'
import { createSelector } from 'reselect'
import { ProfileRole } from 'services/api.types'
import { getDashboardLoaded, getUserProgress } from 'views/dashboard/DashboardViewSelectors'
import { getAreRolesDisabled } from 'views/profiles/ProfilesViewSelectors'

export const authenticated = (state: IGlobalState) => state.auth.whoAmI.authenticated
export const currentProfile = (state: IGlobalState) => state.auth.whoAmI.currentProfile
export const userDetails = (state: IGlobalState) => state.auth.whoAmI
export const userAccounts = (state: IGlobalState) => state.auth.accounts
export const userLedgers = (state: IGlobalState) => state.auth.userLedgers
export const userInfoAdded = (state: IGlobalState) => state.dashboardView.progress.userInfoAdded

export const userRole = (state: IGlobalState) =>
  state.auth.whoAmI.currentProfile && state.auth.whoAmI.currentProfile.role

export const whoAmIFetched = (state: IGlobalState) => state.auth.loaded
export const whoAmIFetching = (state: IGlobalState) => state.auth.loading

export const profileSelectFetched = (state: IGlobalState) => state.auth.profileSelectLoaded
export const profileSelectFetching = (state: IGlobalState) => state.auth.profileSelectLoading

export const ledgerBasicDetailsFetched = (state: IGlobalState) => state.common.ledgerBasicDetailsLoaded

export const isRegistrationCompleted = createSelector(userDetails, getUserProgress, (whoAmI, userProgress) => {
  // return userProgress && whoAmI.hasProfiles;
  return userProgress.hasCredentials
})

// Is user completed all onboarding steps (To show progressbar)
export const isOnboardingCompleted = createSelector(
  userDetails,
  currentProfile,
  getUserProgress,
  getAreRolesDisabled,
  (whoAmI, profile, userProgress, areRolesDisabled) => {
    // GetDashboard request isn't finished yet. Can't say if onboarding is finished or not.
    if (!userProgress) {
      return whoAmI.hasProfiles
    }

    // If user is admin.
    if (profile?.role === ProfileRole.LEDGER_ADMIN) {
      // Is stock info added, last step of onboarding.
      // and is user info and credentials present for new admin.
      return userProgress.onboardingContractSigned && userProgress.hasCredentials && userProgress.userInfoAdded
    }

    // If user is legal Entity Holder
    if (profile?.role === ProfileRole.HOLDER && userProgress.legalHolder) {
      // Is user confirmed legal entity info and added his own.
      return (
        userProgress.onboardingContractSigned &&
        userProgress.legalEntityInfoConfirmed &&
        userProgress.userInfoAdded &&
        userProgress.hasVerifiedAppId
      )
    }

    // If user is Natural Person holder
    if (profile?.role === ProfileRole.HOLDER) {
      // Is user confirmed his information.
      return userProgress.onboardingContractSigned && userProgress.userInfoAdded && userProgress.hasVerifiedAppId
    }

    if (areRolesDisabled) {
      return true
    }

    /**
     * User doesn't have role.
     * 1. Because he hasn't selected profile. --> Onboarding completed
     * 2. Because he hasn't created ledger or additional admin onboarding is not completed --> Onboarding not completed
     */
    return whoAmI.hasProfiles && !whoAmI.ledgerCreation && !userProgress.additionalAdminOnboarding
  },
)

// Is user completed all onboarding steps (To show progressbar)
export const shouldDisableMenu = createSelector(
  userDetails,
  currentProfile,
  getUserProgress,
  ledgerBasicDetailsFetched,
  getDashboardLoaded,
  (whoAmI, profile, userProgress) => {
    // GetDashboard request isn't finished yet. Can't say if should disable menu or not.

    if (!userProgress) {
      return whoAmI.hasProfiles
    }

    // If user is admin.
    if (profile && profile.role === ProfileRole.LEDGER_ADMIN) {
      // If one of those steps is undone, user should not see menu links
      return (
        !userProgress.hasCredentials ||
        !userProgress.userInfoAdded ||
        !userProgress.stockInfoAdded ||
        !userProgress.hasVerifiedAppId ||
        !userProgress.onboardingContractSigned ||
        userProgress.additionalAdminOnboarding
      )
    }

    // If user is legal Entity Holder
    if (profile && profile.role === ProfileRole.HOLDER && userProgress.legalHolder) {
      // If one of those steps is undone, user should not see menu links
      return (
        !userProgress.legalEntityInfoConfirmed ||
        !userProgress.userInfoAdded ||
        !userProgress.hasVerifiedAppId ||
        !userProgress.onboardingContractSigned
      )
    }

    // If user is Natural Person holder
    if (profile && profile.role === ProfileRole.HOLDER) {
      // If one of those steps is undone, user should not see menu links
      return !userProgress.userInfoAdded || !userProgress.hasVerifiedAppId || !userProgress.onboardingContractSigned
    }

    /**
     * User doesn't have role.
     * 1. Because he hasn't selected profile. --> Disable menu
     * 2. Because he hasn't created ledger. --> Disable menu
     */
    return true
  },
)
