import { createAction } from 'redux-actions'

const ns = 'settings/company/'

/* Get ledger data */
export const GetCompanyData = createAction(`${ns}GET_COMPANY_DATA`)
export const GetCompanyDataFail = createAction(`${ns}GET_COMPANY_DATA_FAIL`)
export const GetCompanyDataSuccess = createAction(`${ns}GET_COMPANY_DATA_SUCCESS`)

/* Create Ledger */
export const SaveLedgerDetails = createAction(`${ns}SAVE_LEDGER_DETAILS`)

/* Update Ledger data */
export const UpdateLedger = createAction(`${ns}UPDATE_LEDGER_DATA`)

export const SetLoading = createAction(`${ns}SET_LOADING`)
export const SetLoaded = createAction(`${ns}SET_LOADED`)

/* Preface data */
export const GetCompanyFormPrefaceData = createAction(`${ns}GET_COMPANY_FORM_PREFACE_DATA`)
export const GetCompanyFormPrefaceDataSuccess = createAction(`${ns}GET_COMPANY_FORM_PREFACE_DATA_SUCCESS`)
export const GetCompanyFormPrefaceDataFailure = createAction(`${ns}GET_COMPANY_FORM_PREFACE_DATA_FAILURE`)

export const GetDelawareScrapedData = createAction(`${ns}GET_DELAWARE_SCRAPED_DATA`)
export const GetDelawareScrapedDataSuccess = createAction(`${ns}GET_DELAWARE_SCRAPED_DATA_SUCCESS`)
export const GetDelawareScrapedDataFailure = createAction(`${ns}GET_DELAWARE_SCRAPED_DATA_FAILURE`)

export const SaveCompanyFormPrefaceData = createAction(`${ns}SAVE_COMPANY_FORM_PREFACE_DATA`)
export const SaveCompanyFormPrefaceDataSuccess = createAction(`${ns}SAVE_COMPANY_FORM_PREFACE_DATA_SUCCESS`)
export const SaveCompanyFormPrefaceDataFailure = createAction(`${ns}SAVE_COMPANY_FORM_PREFACE_DATA_FAILURE`)
