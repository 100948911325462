import { IGlobalState } from '../../rootReducer'
import { connect } from 'react-redux'
import { BaseComponent } from 'components/BaseComponent'
import { userRole, userDetails } from './AuthSelectors'
import { GetDashboard$Response$Progress, ProfileRole, WhoAmI$Response } from 'services/api.types'
import { ReactNode } from 'react'
import RoleUtil from 'common/utils/roleUtil'
import { getUserProgress } from 'views/dashboard/DashboardViewSelectors'

export interface IMatchRolesProps {
  /**
   * User has to have only these roles
   */
  matchIdentical?: ProfileRole[]
  /**
   * User has to have all of these roles, but can have additional ones
   */
  matchAll?: ProfileRole[]
  /**
   * User has to have at least one of these roles
   */
  matchOne?: ProfileRole[]
  /**
   * User has to be Legal Entity Holder
   * For BE holder and legal holder are the same. And there is no difference.
   * In FE some views need to have difference.
   */
  legalHolder?: boolean
  /**
   * Render out this part when role validation fails
   */
  onMatchFail?: ReactNode
  /**
   * User has no role Selected
   */
  noRole?: boolean
}

interface IStateProps {
  role: ProfileRole
  whoAmI: WhoAmI$Response
  progress: GetDashboard$Response$Progress
}

interface IDispatchProps {}

type IMatchRolesWrapperProps = IMatchRolesProps & IStateProps & IDispatchProps

class MatchRolesWrapper extends BaseComponent<IMatchRolesWrapperProps> {
  /*
        Check if user has necessary roles to render it's content
     */
  private get validateRoles(): boolean {
    if (!this.props.role) {
      return this.props.noRole
    } else if (this.props.legalHolder) {
      /**
       * User has to be Legal Entity Holder
       * For BE holder and legal holder are the same. And there is no difference.
       * In FE some views need to have difference.
       */
      return (
        RoleUtil.matchOne([ProfileRole.HOLDER], [this.props.role]) &&
        this.props.progress &&
        this.props.progress.legalHolder
      )
    } else if (this.props.matchIdentical) {
      return RoleUtil.matchIdentical(this.props.matchIdentical, [this.props.role])
    } else if (this.props.matchAll) {
      return RoleUtil.matchAll(this.props.matchAll, [this.props.role])
    } else if (this.props.matchOne) {
      return RoleUtil.matchOne(this.props.matchOne, [this.props.role])
    } else {
      return true
    }
  }

  render(): ReactNode {
    return this.validateRoles ? this.props.children : this.props.onMatchFail ? this.props.onMatchFail : null
  }
}

const mapStateToProps = (state: IGlobalState): IStateProps => {
  return {
    role: userRole(state),
    whoAmI: userDetails(state),
    progress: getUserProgress(state),
  }
}

const mapDispatchToProps: IDispatchProps = {}

const MatchRoles = connect(mapStateToProps, mapDispatchToProps)(MatchRolesWrapper)

export default MatchRoles
