import * as React from 'react'
import { BaseComponent } from '../../BaseComponent'

import './Background.scss'

export interface IBackgroundProps {
  /**
   * Background type
   */
  type?: 'angle' | 'full' | 'angle-full' | 'angle-long' | 'white'
  /**
   * Show guardtime logo
   */
  showGuardtime?: boolean
  /**
   * Background if no header is displayed
   */
  noHeader?: boolean
}

class Background extends BaseComponent<IBackgroundProps> {
  static defaultProps: Partial<IBackgroundProps> = {
    showGuardtime: false,
    noHeader: false,
  }

  BEM(): string {
    const classArray = ['background']

    if (this.props.type) {
      classArray.push(`background--${this.props.type}`)
    }

    if (this.props.showGuardtime) {
      classArray.push(`background--guardtime`)
    }

    if (this.props.noHeader) {
      classArray.push(`background--no-header`)
    }

    return classArray.join(' ')
  }

  render(): JSX.Element {
    return (
      <div id="background" className={this.BEM()}>
        {this.props.type === 'angle-full' && <div className="background__wave" />}
        {this.props.children}
      </div>
    )
  }
}

export default Background
