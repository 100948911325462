import { IGlobalState } from '../../../rootReducer'

export const getLedgerBasicDetails = (state: IGlobalState) => state.common.ledgerBasicDetails
export const getLedgerDetails = (state: IGlobalState) => state.common.ledgerDetails

export const getEditableLedger = (state: IGlobalState) => state.common.ledgerBasicDetails.editableLedger
export const getHistoricLedger = (state: IGlobalState) => state.common.ledgerBasicDetails.historicLedger

export const getLedgerBasicDetailsLoaded = (state: IGlobalState) => state.common.ledgerBasicDetailsLoaded
export const getLedgerBasicDetailsLoading = (state: IGlobalState) => state.common.ledgerBasicDetailsLoading
export const getLedgerDetailsLoaded = (state: IGlobalState) => state.common.ledgerDetailsLoaded

export const getSearchedAccountsSelector = (state: IGlobalState, onboardedOnly: boolean = undefined) => {
  return state.common.searchedAccounts.filter(account => {
    if (typeof onboardedOnly === 'boolean') {
      return account.temporaryAccount === false
    }
    return account
  })
}

export const getIfThereAreSearchedAccounts = (state: IGlobalState) => {
  const { searchedAccounts, searchedAccountsLoaded, searchString } = state.common

  if (searchString === '') {
    if (searchedAccountsLoaded && searchedAccounts.length === 0) {
      return false
    }
  }
  return true
}

export const getSearchedDraftAccountsSelector = (state: IGlobalState) => state.common.searchedDraftAccounts

export const getSearchedAccountsLoaded = (state: IGlobalState) => state.common.searchedAccountsLoaded
export const getSearchedAccountsLoading = (state: IGlobalState) => state.common.searchedAccountsLoading

export const getSearchedDraftAccountsLoaded = (state: IGlobalState) => state.common.searchedDraftAccountsLoaded
export const getSearchedDraftAccountsLoading = (state: IGlobalState) => state.common.searchedDraftAccountsLoading

export const getSearchedAccountsWithStockSelector = (state: IGlobalState, onboardedOnly: boolean = undefined) => {
  return state.common.searchedAccountsWithStock.filter(account => {
    if (typeof onboardedOnly === 'boolean') {
      return account.temporaryAccount === false
    }
    return account
  })
}
export const getSearchedAccountsWithStockLoaded = (state: IGlobalState) => state.common.searchedAccountsWithStockLoaded
export const getSearchedAccountsWithStockLoading = (state: IGlobalState) =>
  state.common.searchedAccountsWithStockLoading

export const getIsHistoricImportMode = (state: IGlobalState) =>
  getHistoricLedger(state) && !state.dashboardView?.progress?.historicImport?.completed
export const getIsHistoricImportCompleted = (state: IGlobalState) =>
  getHistoricLedger(state) && !!state.dashboardView?.progress?.historicImport?.completed
