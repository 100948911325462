import { Action, handleActions } from 'redux-actions'
import {
  SearchAccountsSuccess,
  SearchAccounts,
  SearchAccountsFail,
  SearchAccountsWithStock,
  SearchAccountsWithStockFail,
  SearchAccountsWithStockSuccess,
  GetLedgerBasicDetails,
  GetLedgerBasicDetailsSuccess,
  GetLedgerBasicDetailsFail,
  SearchDraftAccounts,
  SearchDraftAccountsSuccess,
  SearchDraftAccountsFail,
  GetLedgerDetails,
  GetLedgerDetailsFail,
  GetLedgerDetailsSuccess,
} from './commonAction'
import { AccountDto, GetLedgerBasicDetails$Response, GetLedgerDetails$Response, HolderDto } from 'services/api.types'
import { SelectUserProfile } from 'common/auth/AuthActions'

class State {
  ledgerBasicDetails: GetLedgerBasicDetails$Response = {
    editableLedger: false,
    historicLedger: false,
    treasuryStockAccount: null,
    ledgerAdminUsers: null,
    treasury: {
      accountId: null,
      name: null,
      hasShares: null,
    },
    company: {
      name: null,
      address: null,
      registrationNumber: null,
    },
  }
  ledgerDetails: GetLedgerDetails$Response = {
    documents: [],
    id: null,
    name: null,
    officeAddress: null,
    registrationDate: null,
    registrationNumber: null,
    type: null,
    ledgerAdminUsers: null,
  }
  ledgerDetailsLoaded: boolean = false
  ledgerDetailsLoading: boolean = false
  ledgerBasicDetailsLoaded: boolean = false
  ledgerBasicDetailsLoading: boolean = false
  searchedAccounts: AccountDto[] = []
  searchedAccountsLoading: boolean = false
  searchedAccountsLoaded: boolean = false
  searchedDraftAccounts: AccountDto[] = []
  searchedDraftAccountsLoading: boolean = false
  searchedDraftAccountsLoaded: boolean = false
  searchedAccountsWithStock: AccountDto[] = []
  searchedAccountsWithStockLoading: boolean = false
  searchedAccountsWithStockLoaded: boolean = false
  searchString: string = ''
  error: boolean = false
}

const defaultState = new State()

const sortApplicableAccounts = (accounts: AccountDto[] = [], searchString: string) => {
  return accounts?.sort((a: AccountDto, b: AccountDto) => {
    return b?.holders?.find((h: HolderDto) => startsWith(h.lastName, searchString)) &&
      !a?.holders?.find((h: HolderDto) => startsWith(h.lastName, searchString))
      ? 1
      : 0
  })
}

export default handleActions<State, any>(
  {
    [String(SelectUserProfile)]: (state: State): State => ({
      ...state,
      searchedAccounts: defaultState.searchedAccounts,
      searchedDraftAccounts: defaultState.searchedDraftAccounts,
      searchedAccountsWithStock: defaultState.searchedAccountsWithStock,
      searchedAccountsLoaded: defaultState.searchedAccountsLoaded,
      searchedDraftAccountsLoaded: defaultState.searchedDraftAccountsLoaded,
      searchedAccountsWithStockLoaded: defaultState.searchedAccountsWithStockLoaded,
    }),
    [String(GetLedgerBasicDetails())]: (state: State): State => {
      return {
        ...state,
        ledgerBasicDetailsLoading: true,
      }
    },
    [String(GetLedgerBasicDetailsSuccess)]: (state: State, action: Action<GetLedgerBasicDetails$Response>): State => {
      return {
        ...state,
        ledgerBasicDetails: action.payload,
        ledgerBasicDetailsLoaded: true,
        ledgerBasicDetailsLoading: false,
        error: false,
      }
    },
    [String(GetLedgerBasicDetailsFail)]: (state: State): State => {
      return {
        ...state,
        ledgerBasicDetailsLoading: false,
        error: true,
      }
    },
    [String(GetLedgerDetails())]: (state: State): State => {
      return {
        ...state,
        ledgerDetailsLoading: true,
      }
    },
    [String(GetLedgerDetailsSuccess)]: (state: State, action: Action<GetLedgerDetails$Response>): State => {
      return {
        ...state,
        ledgerDetails: action.payload,
        ledgerDetailsLoaded: true,
        ledgerDetailsLoading: false,
        error: false,
      }
    },
    [String(GetLedgerDetailsFail)]: (state: State): State => {
      return {
        ...state,
        ledgerDetailsLoading: false,
        error: true,
      }
    },
    [String(SearchAccounts)]: (state: State, action: Action<string>): State => {
      return {
        ...state,
        searchedAccountsLoading: true,
        searchString: action.payload,
        searchedAccountsLoaded: false,
      }
    },
    [String(SearchDraftAccounts)]: (state: State, action: Action<string>): State => {
      return {
        ...state,
        searchedDraftAccountsLoading: true,
        searchString: action.payload,
        searchedDraftAccountsLoaded: false,
      }
    },
    [String(SearchAccountsWithStock)]: (state: State, action: Action<string>): State => {
      return {
        ...state,
        searchedAccountsWithStockLoading: true,
        searchString: action.payload,
        searchedAccountsWithStockLoaded: false,
      }
    },
    [String(SearchAccountsSuccess)]: (state: State, action: Action<AccountDto[]>): State => {
      const searchString = state.searchString ? state.searchString.toLowerCase() : ''
      const applicableAccounts = action.payload.filter(account => account.holders && account.holders.length)
      const sortedApplicableAccounts = sortApplicableAccounts(applicableAccounts, searchString)
      const treasuryAccount = action.payload.find(account => !!account.ledgerRootAccount)
      if (treasuryAccount) {
        sortedApplicableAccounts.unshift(treasuryAccount)
      }
      return {
        ...state,
        searchedAccounts: sortedApplicableAccounts,
        searchedAccountsLoading: false,
        searchedAccountsLoaded: true,
      }
    },
    [String(SearchDraftAccountsSuccess)]: (state: State, action: Action<AccountDto[]>): State => {
      const searchString = state.searchString ? state.searchString.toLowerCase() : ''
      const applicableAccounts = action?.payload?.filter(account => account.holders && account.holders.length)
      const sortedApplicableAccounts = sortApplicableAccounts(applicableAccounts, searchString)
      return {
        ...state,
        searchedDraftAccounts: sortedApplicableAccounts,
        searchedDraftAccountsLoading: false,
        searchedDraftAccountsLoaded: true,
      }
    },
    [String(SearchAccountsWithStockSuccess)]: (state: State, action: Action<AccountDto[]>): State => {
      const searchString = state.searchString ? state.searchString.toLowerCase() : ''
      const applicableAccounts = action.payload.filter(account => account.holders && account.holders.length)
      const sortedApplicableAccounts = sortApplicableAccounts(applicableAccounts, searchString)
      const treasuryAccount = action.payload.find(account => !!account.ledgerRootAccount)
      if (treasuryAccount) {
        sortedApplicableAccounts.unshift(treasuryAccount)
      }
      return {
        ...state,
        searchedAccountsWithStock: sortedApplicableAccounts,
        searchedAccountsWithStockLoading: false,
        searchedAccountsWithStockLoaded: true,
      }
    },
    [String(SearchAccountsFail)]: (state: State): State => {
      return { ...state, searchedAccountsLoading: false, searchedAccountsLoaded: true }
    },
    [String(SearchDraftAccountsFail)]: (state: State): State => {
      return { ...state, searchedDraftAccountsLoading: false, searchedDraftAccountsLoaded: true }
    },
    [String(SearchAccountsWithStockFail)]: (state: State): State => {
      return { ...state, searchedAccountsWithStockLoading: false, searchedAccountsWithStockLoaded: true }
    },
  },
  new State(),
)

function startsWith(name: string, str: string) {
  return name ? name.toLowerCase().startsWith(str) : true
}

export { State as CommonState }
