import { connect } from 'react-redux'
import { IGlobalState } from '../../../rootReducer'
import Header, { IDispatchProps, IStateProps } from './Header'
import { authenticated, currentProfile, shouldDisableMenu, userDetails } from 'common/auth/AuthSelectors'
import { paths } from 'common/router/routePaths'
import {
  LedgerWithProfilesDto,
  ProfileRole,
  SelectProfile$Request,
  WhoAmI$Response$CurrentProfile,
} from 'services/api.types'
import { INavigationLink } from './children/navigation/Navigation'
import { IGlobalNotificationProps } from 'components/ui/global-notification/GlobalNotification'

function getLinks(disabledItems: string[], profile: WhoAmI$Response$CurrentProfile) {
  let linksArray: INavigationLink[] = [
    {
      label: 'Stock',
      url: paths.stock.root,
      roles: [ProfileRole.LEDGER_ADMIN],
      showWhenNoRoles: true,
      disabled: disabledItems.includes(paths.stock.root),
    },
    {
      label: 'Stockholders',
      url: paths.holderList.root,
      roles: [ProfileRole.LEDGER_ADMIN],
      showWhenNoRoles: true,
      disabled: disabledItems.includes(paths.holderList.root),
    },
    {
      label: 'Transfers',
      url: paths.transfers.root,
      roles: [ProfileRole.LEDGER_ADMIN],
      showWhenNoRoles: true,
      disabled: disabledItems.includes(paths.transfers.root),
    },
    {
      label: 'Voting List',
      url: paths.votingList,
      roles: [ProfileRole.LEDGER_ADMIN],
      showWhenNoRoles: true,
      disabled: disabledItems.includes(paths.votingList),
    },
  ]

  if (profile && profile.role === ProfileRole.HOLDER) {
    linksArray = [
      {
        label: 'My Stock',
        url: paths.stock.root,
        roles: [ProfileRole.HOLDER],
        disabled: disabledItems.includes(paths.stock.root),
      },
      {
        label: 'Transfers',
        url: paths.transfers.root,
        roles: [ProfileRole.HOLDER],
        disabled: disabledItems.includes(paths.transfers.root),
      },
    ]
  }

  linksArray.unshift({
    label: 'Dashboard',
    url: paths.dashboard.root,
    roles: [ProfileRole.HOLDER, ProfileRole.LEDGER_ADMIN],
    disabled: disabledItems.includes(paths.dashboard.root),
    showWhenNoRoles: true,
  })

  return linksArray
}

interface IHeaderWrapperProps {
  toggleNavBar?: (show?: boolean) => void
  sideNavOpen?: boolean
  userLedgers?: LedgerWithProfilesDto[]
  selectProfile: (payload: SelectProfile$Request) => void
  logOut: () => void
  disabledItems: string[]
  isOnboardingCompleted: boolean
  globalNotification?: IGlobalNotificationProps
}

const mapStateToProps = (state: IGlobalState, ownProps: IHeaderWrapperProps): IStateProps => {
  return {
    isLoggedIn: authenticated(state),
    userDetails: userDetails(state),
    links: getLinks(ownProps.disabledItems, currentProfile(state)),
    toggleNavBar: ownProps.toggleNavBar,
    sideNavOpen: ownProps.sideNavOpen,
    userLedgers: ownProps.userLedgers,
    selectProfile: ownProps.selectProfile,
    logOut: ownProps.logOut,
    disabledItems: ownProps.disabledItems,
    isOnboardingCompleted: ownProps.isOnboardingCompleted,
  }
}

const mapDispatchToProps: IDispatchProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
