import * as Route from 'route-parser'
import * as _ from 'lodash'

// Route paths
export const paths = {
  home: '/',
  onboarding: {
    root: '/onboarding',
  },
  dashboard: {
    root: '/dashboard',
    historicLedger: {
      root: '/dashboard/existing-stockholder-data',
      details: '/dashboard/existing-stockholder-data/details',
      confirm: '/dashboard/existing-stockholder-data/confirm',
    },
  },
  stock: {
    root: '/stock',
    details: '/stock/view',
    restrictions: '/stock/restrictions',
    addDetails: '/stock/add-details',
    amend: '/stock/amend',
    amendmentOverview: '/stock/amendment-overivew',
    defineSeries: '/stock/define-series',
    issueStock: '/stock/issue-stock',
    newStockHolder: '/stock/new-stockholder',
    pendingIssuance: '/stock/pending-issuance',
    addStock: '/stock/add-stock',
    addStockNew: '/stock/add-stock-new',
    addStockOverview: '/stock/add-stock-overview',
    proformaIssuance: {
      issue: '/stock/issue-proforma',
      holder: '/stock/issue-proforma-holder',
      confirm: '/stock/issue-proforma-confirm',
    },
  },
  transfers: {
    root: '/transfers',
    new: '/transfers/new',
    editHistoric: '/transfers/edit-historic',
    bulkImport: '/transfers/bulk-import',
    bulkImportReview: '/transfers/bulk-import/review',
    cancel: '/transfers/cancel',
    report: '/transfers/report',
    cancelReport: '/transfers/cancel-report',
    importReport: '/transfers/import-report',
    details: '/transfer/details',
  },
  holderList: {
    root: '/holder-list',
    editStockHolder: '/holder-list/edit-stockholder',
    viewStockHolder: '/holder-list/view-stockholder',
  },
  votingList: '/voting-list',
  eVoting: '/e-voting',
  settings: '/settings',
  profiles: '/profiles',
  holderOnboarding: '/holder-onboarding',
  login: '/login',
  magiclink: '/magiclink',
  verification: '/verification',
  signing: '/signing',
  notification: '/notification',
  forgotPassword: '/forgot-password',
  resetPassword: '/resetpassword',
  recoverySms: '/recovery-sms',
  signOut: '/sign-out',
}
/*
    Gives path calls type-safe parameters
 */
function resolvePathParams<R extends object>(
  pathTemplate: string,
  pathParams: R,
  defaultsParamsForNilValues?: Partial<R>,
): string | never {
  const { pathParamsWithDefaults, unresolvedParamNames } = getPathParametersWithDefaults(
    pathParams,
    defaultsParamsForNilValues,
  )

  const route = new Route(pathTemplate)
  const resolvedPath = route.reverse(pathParamsWithDefaults)
  if (resolvedPath === false) {
    const message =
      'failed to resolve route path from\n"' +
      pathTemplate +
      '"\nbecause parameters\n' +
      JSON.stringify(unresolvedParamNames) +
      '\nwere not resolved from params:\n' +
      JSON.stringify(pathParams) +
      '\nand default parameter values:\n' +
      JSON.stringify(defaultsParamsForNilValues)
    throw new Error(message)
  }
  return resolvedPath
}

/**
 * @param {R} pathParams
 * @param {Partial<R extends object>} defaultsParamsForNilValues
 * @returns object based on pathParams, where values equal to `null` and ´undefined´ are replaced from `defaultsParamsForNilValues`
 */
function getPathParametersWithDefaults<R extends object>(
  pathParams: R,
  defaultsParamsForNilValues: Partial<R> | undefined,
): { pathParamsWithDefaults: R; unresolvedParamNames: string[] } {
  const pathParamsWithDefaults = _.extend({}, pathParams)
  const unresolvedParamNames: string[] = []
  Object.keys(pathParams)
    .filter(paramName => _.get(pathParams, paramName) == null)
    .forEach(paramName => {
      const defaultValue = _.get(defaultsParamsForNilValues, paramName)
      if (defaultValue != null) {
        _.set(pathParamsWithDefaults, paramName, defaultValue)
      } else {
        unresolvedParamNames.push(paramName)
      }
    })
  return {
    pathParamsWithDefaults,
    unresolvedParamNames,
  }
}
