import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getI18n } from 'react-i18next'
import { Action } from 'redux-actions'
import { push } from 'connected-react-router'

import {
  CreateLegalEntity,
  CreateNaturalPerson,
  EditHolderDetails,
  GetLegalEntityHolderDetails,
  GetLegalEntityHolderDetailsSuccess,
  GetNaturalPersonHolderDetails,
  GetNaturalPersonHolderDetailsSuccess,
  GetStockHolders,
  GetStockHoldersSuccess,
  GoBackToHoldersList,
  ResendHolderEmail,
  ResendHolderEmailFinished,
  UpdateLegalEntityHolderDetails,
  UpdateLegalEntityHolderDetailsSuccess,
  UpdateLegalEntityHolderDetailsFailure,
  UpdateNaturalPersonHolderDetails,
  UpdateNaturalPersonHolderDetailsSuccess,
  UpdateNaturalPersonHolderDetailsFailure,
  ViewHolderDetails,
  GetStockholderDocuments,
  GetStockholderDocumentsSuccess,
  GetStockholderDocumentsFail,
} from './HolderListViewActions'
import api from 'services/Api.services'
import {
  AccountId,
  AccountStockListDto,
  CreateLegalHolder$Request,
  CreateNaturalHolder$Request,
  GetAccountList$Response,
  GetHolderDocuments$Request,
  GetHolderDocuments$Response,
  GetLegalEntityHolderDetails$Response,
  GetNaturalPersonHolderDetails$Response,
  HolderDto,
  HolderType,
  ResendHolderEmail$Request,
  UpdateLegalEntityHolderDetails$Request,
  UpdateNaturalPersonHolderDetails$Request,
} from 'services/api.types'
import {
  getUpdateNaturalPersonHolderDetailsRequestData,
  getUpdateLegalEntityHolderDetailsRequestData,
} from './HolderListViewHelpers'
import { paths } from 'common/router/routePaths'
import { ILegalHolderFields, INaturalHolderFields } from 'components/functional/new-holder/NewHolderFieldNames'
import { addNotification } from 'common/store/notifications/NotificationActions'
import { NotificationCode, NotificationType } from 'components/ui/notification/Notification'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { getIsHistoricImportMode } from 'common/store/common/commonSelector'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { getFullName } from 'common/utils/nameUtil'
import DraftStorage from 'common/utils/draftStorage'
import { DEFAULT_HISTORIC_IMPORT_DRAFT, IHistoricLedgerImportDraft } from 'views/transfers/TransfersViewReducer'
import { IEditStockHolderDetailsViewRouterState } from './children/edit-stock-holder/EditStockHolderDetailsView'

/*
    Get Transfers List
 */
function* sagaGetHolderList() {
  try {
    const holderList: GetAccountList$Response = yield call(api.ledger.getAccountList)
    // Remove double or more whitespaces
    holderList.list.map((holder, i) => {
      const fixedAccountName = holder.accountName.replace(/\s\s+/g, ' ').replace(/^\s+|\s+$/g, '')
      holderList.list[i].accountName = fixedAccountName
    })
    yield put({ type: String(GetStockHoldersSuccess), payload: holderList.list })
  } catch (e) {
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaEditHolderDetails(action: Action<IEditStockHolderDetailsViewRouterState>) {
  yield put(
    push({
      pathname: paths.holderList.editStockHolder,
      state: action.payload,
    }),
  )
}

export interface IViewHolderDetails {
  holder: GetLegalEntityHolderDetails$Response | GetNaturalPersonHolderDetails$Response
  holderType: HolderType
  accountId: AccountId
  holderId: AccountId
  accountName: string
  relatedStock?: AccountStockListDto[]
}

function* sagaViewHolderDetails(action: Action<HolderDto[]>) {
  yield put(
    push({
      pathname: paths.holderList.viewStockHolder,
      state: action.payload,
    }),
  )
}

function* sagaGoBackToHoldersList() {
  yield put(push(paths.holderList.root))
}

/* Get Natural Person Holder Details */
function* sagaGetNaturalPersonHolderDetails(action: Action<{ holderId: string }>) {
  try {
    const historicImportMode: boolean = yield select(getIsHistoricImportMode)
    let naturalPersonDetails: GetNaturalPersonHolderDetails$Response

    if (historicImportMode) {
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

      for (const h of draft?.holders) {
        if (h?.data?.holderId === action.payload.holderId) {
          naturalPersonDetails = {
            givenNames: '',
            lastName: '',
            middleName: '',
            nameSuffix: '',
            ssn: '',
            confirmed: false,
            onboarded: false,
            emails: [],
            phones: [],
            ...h?.data,
            address: { ...h?.data?.address, id: null },
          }
        }
      }
    }

    naturalPersonDetails = {
      ...naturalPersonDetails,
      ...(yield call(api.ledger.getNaturalPersonHolderDetails, action.payload)),
    }

    yield put({ type: String(GetNaturalPersonHolderDetailsSuccess), payload: naturalPersonDetails })
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: getI18n().t('error.get-holder-details', {
        ns: 'frontendNotifications',
      }),
      code: NotificationCode.APP_ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
    console.log('Error during getNaturalPersonHolderDetails', e)
  }
}

/* Get Legal Entity Holder Details */
function* sagaGetLegalEntityHolderDetails(action: Action<{ holderId: string }>) {
  try {
    let legalEntity: GetLegalEntityHolderDetails$Response
    const historicImportMode: boolean = yield select(getIsHistoricImportMode)

    if (historicImportMode) {
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

      for (const h of draft?.holders) {
        if (h?.data?.holderId === action.payload.holderId) {
          legalEntity = {
            name: h?.data?.name,
            calculatedName: h?.data?.name,
            tin: null,
            confirmed: false,
            onboarded: false,
            emails: [],
            phones: [],
            ...h?.data,
            officeAddress: { ...h?.data?.address, id: null },
          }
        }
      }
    }

    legalEntity = { ...legalEntity, ...(yield call(api.ledger.getLegalEntityHolderDetails, action.payload)) }

    yield put({ type: String(GetLegalEntityHolderDetailsSuccess), payload: legalEntity })
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: getI18n().t('error.get-holder-details', {
        ns: 'frontendNotifications',
      }),
      code: NotificationCode.APP_ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
    console.log('Error during getLegalEntityCompanyDetails', e)
  }
}

/* Update Natural Person Holder Details */
function* sagaUpdateNaturalPersonHolderDetails(action: Action<INaturalHolderFields>) {
  const requestData: UpdateNaturalPersonHolderDetails$Request = getUpdateNaturalPersonHolderDetailsRequestData(
    action.payload,
  )
  const historicImportMode: boolean = yield select(getIsHistoricImportMode)

  try {
    if (historicImportMode) {
      const response: IHistoricLedgerImportDraft = yield call(DraftStorage.get, DraftStorageEnum.HISTORIC_LEDGER_IMPORT)
      const draft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...response }
      const { holders } = draft

      for (const [i, h] of holders.entries()) {
        if (h?.data?.holderId === requestData.holderId) {
          const calculatedName = getFullName({
            givenNames: requestData.givenNames,
            lastName: requestData.lastName,
            nameSuffix: requestData.nameSuffix,
            middleName: requestData.middleName,
          })
          holders[i].data = {
            ...holders[i].data,
            ...requestData,
            calculatedName,
            name: calculatedName,
          }
          break
        }
      }

      const data = JSON.stringify({ transfers: draft.transfers, holders, data: draft.data })
      yield call(DraftStorage.save, DraftStorageEnum.HISTORIC_LEDGER_IMPORT, data)
    }

    yield call(api.ledger.updateNaturalPersonHolderDetails, requestData)
    yield put({ type: String(UpdateNaturalPersonHolderDetailsSuccess), payload: action.payload })

    const notification: INotification = {
      type: NotificationType.SUCCESS,
      description: getI18n().t('success.update-holder-details', {
        ns: 'frontendNotifications',
      }),
    }
    // TODO: notifications not working here
    yield put({ type: String(addNotification), payload: notification })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: getI18n().t('error.update-holder-details', {
        ns: 'frontendNotifications',
      }),
      code: NotificationCode.APP_ERROR,
    }
    yield put({ type: String(UpdateNaturalPersonHolderDetailsFailure) })
    yield put({ type: String(addNotification), payload: notification })
    console.log('Error during updateNaturalPersonHolderDetails', e)
  }
}

/* Update Legal Entity Holder Details */
function* sagaUpdateLegalEntityHolderDetails(action: Action<ILegalHolderFields>) {
  const requestData: UpdateLegalEntityHolderDetails$Request = getUpdateLegalEntityHolderDetailsRequestData(
    action.payload,
  )
  const historicImportMode: boolean = yield select(getIsHistoricImportMode)

  try {
    if (historicImportMode) {
      const response: IHistoricLedgerImportDraft = yield call(DraftStorage.get, DraftStorageEnum.HISTORIC_LEDGER_IMPORT)
      const draft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...response }
      const { holders } = draft

      for (const [i, h] of holders.entries()) {
        if (h?.data?.holderId === requestData.holderId) {
          holders[i].data = { ...holders[i].data, ...requestData }
          break
        }
      }

      const data = JSON.stringify({ transfers: draft.transfers, holders })
      yield call(DraftStorage.save, DraftStorageEnum.HISTORIC_LEDGER_IMPORT, data)
    }

    yield call(api.ledger.updateLegalEntityHolderDetails, requestData)
    yield put({ type: String(UpdateLegalEntityHolderDetailsSuccess), payload: action.payload })

    const notification: INotification = {
      type: NotificationType.SUCCESS,
      description: getI18n().t('success.update-holder-details', {
        ns: 'frontendNotifications',
      }),
    }
    // TODO: notifications not working here
    yield put({ type: String(addNotification), payload: notification })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: getI18n().t('error.update-holder-details', {
        ns: 'frontendNotifications',
      }),
      code: NotificationCode.APP_ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
    yield put({ type: String(UpdateLegalEntityHolderDetailsFailure) })
    console.log('Error during updateLegalEntityHolderDetails', e)
  }
}

/* Create Natural Person */
function* sagaCreateNaturalPerson(action: Action<CreateNaturalHolder$Request>) {
  const requestData: CreateNaturalHolder$Request = action.payload
  try {
    yield call(api.ledger.createNaturalHolder, requestData)
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: getI18n().t('error.update-holder-details', {
        ns: 'frontendNotifications',
      }),
      code: NotificationCode.APP_ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

/* Create Legal Entity */
function* sagaCreateLegalEntity(action: Action<CreateLegalHolder$Request>) {
  const requestData: CreateLegalHolder$Request = action.payload
  try {
    yield call(api.ledger.createLegalHolder, requestData)
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: getI18n().t('error.update-holder-details', {
        ns: 'frontendNotifications',
      }),
      code: NotificationCode.APP_ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaResendHolderEmail(action: Action<ResendHolderEmail$Request>) {
  yield call(api.ledger.resendHolderEmail, action.payload)
  const notification: INotification = {
    type: NotificationType.SUCCESS,
    description: getI18n().t('success.resend-email', {
      ns: 'frontendNotifications',
    }),
  }
  yield put({ type: String(addNotification), payload: notification })
  yield put({ type: String(ResendHolderEmailFinished) })
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

function* sagaGetStockholderDocuments(action: Action<GetHolderDocuments$Request>) {
  try {
    const response: GetHolderDocuments$Response = yield call(api.ledger.getHolderDocuments, action.payload)
    yield put({ type: String(GetStockholderDocumentsSuccess), payload: response })
  } catch (e) {
    yield put({ type: String(GetStockholderDocumentsFail) })
    console.log('Error during sagaGetStockholderDocuments', e)
  }
}

export default function* holderListViewSagas() {
  yield all([
    takeEvery(String(GetStockHolders), sagaGetHolderList),
    takeEvery(String(EditHolderDetails), sagaEditHolderDetails),
    takeEvery(String(ViewHolderDetails), sagaViewHolderDetails),
    takeEvery(String(GetNaturalPersonHolderDetails), sagaGetNaturalPersonHolderDetails),
    takeEvery(String(GetLegalEntityHolderDetails), sagaGetLegalEntityHolderDetails),
    takeEvery(String(UpdateNaturalPersonHolderDetails), sagaUpdateNaturalPersonHolderDetails),
    takeEvery(String(UpdateLegalEntityHolderDetails), sagaUpdateLegalEntityHolderDetails),
    takeEvery(String(ResendHolderEmail), sagaResendHolderEmail),
    takeEvery(String(GoBackToHoldersList), sagaGoBackToHoldersList),
    takeEvery(String(CreateNaturalPerson), sagaCreateNaturalPerson),
    takeEvery(String(CreateLegalEntity), sagaCreateLegalEntity),
    takeEvery(String(GetStockholderDocuments), sagaGetStockholderDocuments),
  ])
}
