import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { Action } from 'redux-actions'
import { paths } from 'common/router/routePaths'

import { getSignActionId, getUrls, IRecoverySmsViewUrls } from './RecoverySmsViewSelectors'
import { RecoverySmsViewState } from '../recovery-sms/RecoverySmsViewReducer'
import { RecoverBySms, ReSendSms, StartRecoverBySms, UpdateState } from './RecoverySmsViewActions'

import api from 'services/Api.services'
import { RecoverBySms$Request, RecoverBySms$Response } from 'services/api.types'
import { addNotification } from 'common/store/notifications/NotificationActions'
import i18n from 'i18next'
import { NotificationType } from 'components/ui/notification/Notification'
import { delay } from 'redux-saga'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { Sign } from '../signing/SigningViewActions'

function* sagaStartRecoverBySms(action: Action<RecoverySmsViewState>) {
  yield put({
    type: String(UpdateState),
    payload: action.payload,
  })

  yield put(push(paths.recoverySms))
  yield put({
    type: String(addNotification),
    payload: {
      type: NotificationType.SUCCESS,
      description: i18n.t('frontendNotifications:success.recovery-by-sms-check-phone'),
    },
  })
}

function* sagaRecoverBySms(action: Action<RecoverBySms$Request>) {
  try {
    const signActionId: string = yield select(getSignActionId)
    const resp: RecoverBySms$Response = yield call(api.auth.recoverBySms, {
      id: signActionId,
      appKey: action.payload.appKey,
      smsCode: action.payload.smsCode,
    })

    if (resp.status === 'FAILURE') {
      yield put({
        type: String(addNotification),
        payload: {
          type: NotificationType.ERROR,
          description: i18n.t('frontendNotifications:error.recovery-by-sms-failed'),
        },
      })
      yield call(delay, 4000)
      yield put(push(paths.login))
      return
    }

    if (resp.status === 'SUCCESS') {
      // set success message
      const successAction = {
        type: String(addNotification),
        payload: {
          description: i18n.t('frontendNotifications:success.recovery-by-sms-verified'),
          type: NotificationType.SUCCESS,
        },
      }
      // set fail message
      const failAction = {
        type: String(addNotification),
        payload: {
          description: i18n.t('frontendNotifications:error.recovery-by-sms-failed'),
          type: NotificationType.ERROR,
        },
      }
      // set timeout message
      const timeoutAction = {
        type: String(addNotification),
        payload: {
          description: i18n.t('frontendNotifications:error.recovery-by-sms-timout'),
          type: NotificationType.ERROR,
        },
      }

      // redirect to signing
      yield put({
        type: String(Sign),
        payload: {
          signActionId: resp.signActionId,
          signedAction: successAction,
          signedUrl: paths.login,
          cancelledAction: failAction,
          cancelledUrl: paths.recoverySms,
          expiredAction: timeoutAction,
          expiredUrl: paths.recoverySms,
          deniedAction: failAction,
          deniedUrl: paths.recoverySms,
          title: i18n.t('signingView:sms-recovery.title'),
          successTitle: i18n.t('signingView:sms-recovery.successTitle'),
        },
      })
    } else {
      const notification: INotification = {
        type: NotificationType.ERROR,
        description: i18n.t('frontendNotifications:error.recovery-by-sms-failed'),
      }
      yield put({ type: String(addNotification), payload: notification })
      yield call(delay, 4000)
      yield put(push(paths.login))
    }

    // yield put({ type: String(GetRecoveryBySmsStatus), payload: {} });
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: i18n.t('frontendNotifications:error.recovery-by-sms-failed'),
    }
    yield put({ type: String(addNotification), payload: notification })
    yield call(delay, 4000)
    yield put(push(paths.login))
  }
}

function* sagaReSendSms() {
  const urls: IRecoverySmsViewUrls = yield select(getUrls)

  try {
    yield call(api.auth.startRecoverBySms)
    yield put({
      type: String(addNotification),
      payload: {
        type: NotificationType.SUCCESS,
        description: i18n.t('frontendNotifications:success.recovery-by-sms-resent'),
      },
    })
    return
  } catch (e) {
    const notification: INotification = {
      type: NotificationType.ERROR,
      description: i18n.t('frontendNotifications:error.recovery-by-sms-failed'),
    }
    yield put({ type: String(addNotification), payload: notification })
    yield call(delay, 4000)
    yield put(push(urls.errorUrl))
  }
}

export default function* recoverySmsViewSagas() {
  yield all([
    takeEvery(String(StartRecoverBySms), sagaStartRecoverBySms),
    takeEvery(String(RecoverBySms), sagaRecoverBySms),
    takeEvery(String(ReSendSms), sagaReSendSms),
  ])
}
