import { createAction } from 'redux-actions'

const ns = 'ledger-preferences/'

export const GetTransferRestrictions = createAction(`${ns}GET_TRANSFER_RESTRICTIONS`)
export const GetTransferRestrictionsSuccess = createAction(`${ns}GET_TRANSFER_RESTRICTIONS_SUCCESS`)
export const GetTransferRestrictionsFailure = createAction(`${ns}GET_TRANSFER_RESTRICTIONS_FAILURE`)

export const SaveTransferRestrictions = createAction(`${ns}SAVE_TRANSFER_RESTRICTIONS`)
export const SaveTransferRestrictionsSuccess = createAction(`${ns}SAVE_TRANSFER_RESTRICTIONS_SUCCESS`)
export const SaveTransferRestrictionsFailure = createAction(`${ns}SAVE_TRANSFER_RESTRICTIONS_FAILURE`)
