/**
 * @returns object that has the same properties as given object, but values are set based on property names.
 * Returned object type is both read-only (to avoid reassigning values)
 * and type-safely guaranteed to have the same properties as given object (so that IDE can autocomplete properties based on given object type properties).
 */
export default function createFieldNameConstants<K extends string, T>(obj: Record<K, T>): Readonly<Record<K, string>> {
  const res = {} as Record<K, string>
  Object.keys(obj).forEach(propName => (res[propName] = String(propName)))
  return res
}
