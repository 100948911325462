import { all, call, put, takeEvery } from 'redux-saga/effects'

import { GetCompanyDocuments, GetCompanyDocumentsFailure, GetCompanyDocumentsSuccess } from './CompanyHistory.actions'
import { GetLedgerDocuments$Response } from 'services/api.types'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification } from 'common/store/notifications/NotificationActions'
import api from 'services/Api.services'
import { NotificationType } from 'components/ui/notification/Notification'

function* sagaGetCompanyDocuments() {
  try {
    const response: GetLedgerDocuments$Response = yield call(api.ledger.getLedgerDocuments)

    yield put({ type: String(GetCompanyDocumentsSuccess), payload: response.list })
  } catch (e) {
    yield put({ type: String(GetCompanyDocumentsFailure) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

export default function* CompanyHistorySagas() {
  yield all([takeEvery(String(GetCompanyDocuments), sagaGetCompanyDocuments)])
}
