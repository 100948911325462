import { ProfileRole } from 'services/api.types'

export default class RoleUtil {
  // identical matches e.g. condition: ["LEDGER_ADMIN", "HOLDER"] === userRoles: ["LEDGER_ADMIN", "HOLDER"]
  public static matchIdentical(conditionRoles: ProfileRole[], userRoles: ProfileRole[]): boolean {
    return !!(
      userRoles &&
      conditionRoles.length === userRoles.length &&
      conditionRoles.find(
        (role: ProfileRole) => !!userRoles.find((userRole: ProfileRole) => String(userRole) === String(role)),
      )
    )
  }

  // must have all condition matches e.g. condition: ["LEDGER_ADMIN", "HOLDER"] === userRoles: ["LEDGER_ADMIN", "HOLDER", "ANONYMOUS"]
  public static matchAll(conditionRoles: ProfileRole[], userRoles: ProfileRole[]): boolean {
    return !!(
      userRoles &&
      conditionRoles.find(
        (role: ProfileRole) => !!userRoles.find((userRole: ProfileRole) => String(userRole) === String(role)),
      )
    )
  }

  // must have one of condition matches e.g. condition: ["LEDGER_ADMIN", "HOLDER"] === userRoles: ["LEDGER_ADMIN"]
  public static matchOne(conditionRoles: ProfileRole[], userRoles: ProfileRole[]): boolean {
    return !!(
      userRoles &&
      conditionRoles.find(
        (role: ProfileRole) => !!userRoles.find((userRole: ProfileRole) => String(userRole) === String(role)),
      )
    )
  }
}
