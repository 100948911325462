import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux-actions'

import {
  GetLedgerBasicDetails,
  GetLedgerBasicDetailsFail,
  GetLedgerBasicDetailsSuccess,
  SearchAccounts,
  SearchAccountsFail,
  SearchAccountsSuccess,
  SearchDraftAccounts,
  SearchDraftAccountsFail,
  SearchDraftAccountsSuccess,
  SearchAccountsWithStock,
  SearchAccountsWithStockFail,
  SearchAccountsWithStockSuccess,
  GetLedgerDetails,
  GetLedgerDetailsFail,
  GetLedgerDetailsSuccess,
} from './commonAction'
import api from 'services/Api.services'
import {
  GetDashboard$Response$Progress,
  GetLedgerBasicDetails$Response,
  GetLedgerDetails$Response,
  GetStockList$Response,
  SearchAccount$Response,
  SearchAccountWithStock$Response,
} from 'services/api.types'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { searchAccountListFromHistoricDraft } from 'views/transfers/children/new-transfer-historic-view/NewTransferHistoricView.helpers'
import { getIsHistoricImportMode } from './commonSelector'
import DraftStorage from 'common/utils/draftStorage'
import { DEFAULT_HISTORIC_IMPORT_DRAFT, IHistoricLedgerImportDraft } from 'views/transfers/TransfersViewReducer'

export function* sagaGetLedgerBasicDetails() {
  try {
    const payload: GetLedgerBasicDetails$Response = yield call(api.ledger.getLedgerBasicDetails)
    yield put({ type: String(GetLedgerBasicDetailsSuccess), payload })
  } catch (e) {
    yield put({ type: String(GetLedgerBasicDetailsFail), payload: e.message })
  }
}

export function* sagaGetLedgerDetails() {
  try {
    const payload: GetLedgerDetails$Response = yield call(api.ledger.getLedgerDetails)
    yield put(GetLedgerDetailsSuccess(payload))
  } catch (e) {
    yield put(GetLedgerDetailsFail(e.message))
  }
}

export function* sagaSearchAccounts(action: Action<string>) {
  try {
    let holders: SearchAccount$Response = { result: [] }
    const historicImportMode: GetDashboard$Response$Progress = yield select(getIsHistoricImportMode)

    if (historicImportMode) {
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }

      holders.result = searchAccountListFromHistoricDraft(action.payload, draft)
    } else {
      holders = yield call(api.ledger.searchAccount, { searchText: action.payload })
    }

    yield put({ type: String(SearchAccountsSuccess), payload: holders.result })
  } catch (e) {
    yield put({ type: String(SearchAccountsFail) })
    console.log('Error during searching accounts', e)
  }
}

export function* sagaSearchDraftAccounts(action: Action<string>) {
  try {
    const holders: SearchAccount$Response = { result: [] }
    holders.result = yield call(api.ledger.searchDraftAccount, { searchText: action.payload })

    yield put({ type: String(SearchDraftAccountsSuccess), payload: holders.result })
  } catch (e) {
    yield put({ type: String(SearchDraftAccountsFail) })
    console.log('Error during searching draft accounts', e)
  }
}

export function* sagaSearchAccountsWithStocks(action: Action<string>) {
  try {
    let holders: SearchAccountWithStock$Response = { result: [] }
    const historicImportMode: GetDashboard$Response$Progress = yield select(getIsHistoricImportMode)

    if (historicImportMode) {
      const { list: stockList }: GetStockList$Response = yield call(api.ledger.getStockList)
      const draftResponse: IHistoricLedgerImportDraft = yield call(
        DraftStorage.get,
        DraftStorageEnum.HISTORIC_LEDGER_IMPORT,
      )
      const draft: IHistoricLedgerImportDraft = { ...DEFAULT_HISTORIC_IMPORT_DRAFT, ...draftResponse }
      holders.result = searchAccountListFromHistoricDraft(action.payload, draft, true, stockList)
    } else {
      holders = yield call(api.ledger.searchAccountWithStock, {
        searchText: action.payload,
      })
    }
    yield put({ type: String(SearchAccountsWithStockSuccess), payload: holders.result })
  } catch (e) {
    yield put({ type: String(SearchAccountsWithStockFail) })
    console.log('Error during searching accounts', e)
  }
}

export default function* commonSaga() {
  yield all([
    takeLatest(String(SearchAccounts), sagaSearchAccounts),
    takeLatest(String(SearchDraftAccounts), sagaSearchDraftAccounts),
    takeLatest(String(SearchAccountsWithStock), sagaSearchAccountsWithStocks),
    takeLatest(String(GetLedgerBasicDetails), sagaGetLedgerBasicDetails),
    takeLatest(String(GetLedgerDetails), sagaGetLedgerDetails),
  ])
}
