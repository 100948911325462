import { IGlobalState } from '../../../../rootReducer'

export const getCompanyData = (state: IGlobalState) => state.companyForm.company
export const getCompanyDataLoading = (state: IGlobalState) => state.companyForm.loading
export const getCompanyDataLoaded = (state: IGlobalState) => state.companyForm.loaded
export const getCompanyPrefaceData = (state: IGlobalState) => state.companyForm.prefaceData
export const getCompanyPrefaceDataLoading = (state: IGlobalState) => state.companyForm.prefaceData.loading
export const getDelawareScrapedData = (state: IGlobalState) => state.companyForm.delawareScrapedData
export const getDelawareScrapeError = (state: IGlobalState) => state.companyForm.delawareScrapeError
export const getDelawareScrapeLoading = (state: IGlobalState) => state.companyForm.delawareScrapeLoading
export const getDelawareScrapeHasLoaded = (state: IGlobalState) => state.companyForm.delawareScrapeHasLoaded
