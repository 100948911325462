import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  CancelVerification,
  ClearVerificationData,
  CreateVerification,
  SetVerificationUrls,
  UpdateVerificationCode,
  VerificationLoading,
} from './VerificationViewActions'
import { push, replace } from 'connected-react-router'
import { paths } from 'common/router/routePaths'
import { Action } from 'redux-actions'
import { getCancelUrl, getFailUrl } from './VerificationViewSelectors'
import { VerificationViewState } from './VerificationViewReducer'
import { getCurrentPath } from 'common/router/RouterSelectors'
import { sagaWhoAmI } from 'common/auth/AuthSagas'
import api from 'services/Api.services'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { NotificationType } from 'components/ui/notification/Notification'
import { AddDashboardNotification } from '../dashboard/DashboardViewActions'
import { getI18n } from 'react-i18next'
import { SettingsViews } from 'views/settings/SettingsViewReducer'
import { CredentialTabs } from 'views/settings/children/credentials/CredentialsView'

function* sagaCreateVerification(action: Action<VerificationViewState>) {
  // if no urls are given then fallback to current url
  const currentPath: string = yield select(getCurrentPath)
  action.payload.successUrl = action.payload.successUrl || currentPath
  action.payload.failUrl = action.payload.failUrl || currentPath
  action.payload.cancelUrl = action.payload.cancelUrl || currentPath
  yield put({
    type: String(SetVerificationUrls),
    payload: action.payload,
  })
  yield put(push(paths.verification))
}

function* sagaHandleCodeSubmit(action: Action<string>) {
  // when code is 6 digits long then submit it
  if (action.payload && action.payload.length === 6) {
    try {
      yield put({ type: String(VerificationLoading) })
      yield call(api.user.verifyPhoneNumber, { code: action.payload })
      yield call(sagaWhoAmI)

      yield put({
        type: String(AddDashboardNotification),
        payload: { title: getI18n().t('success.phone-number-verified', { ns: 'frontendNotifications' }) },
      })

      yield put(
        replace({
          pathname: paths.settings,
          state: { view: SettingsViews.CREDENTIALS, credentialTab: CredentialTabs.PHONE_NUMBER },
        }),
      )
      yield put({ type: String(ClearVerificationData) })
    } catch (e) {
      const failUrl: string = yield select(getFailUrl)
      const notifications: INotification[] = [
        {
          ...e.response.data,
          type: NotificationType.ERROR,
        },
      ]

      yield put(replace(failUrl, { notifications }))
      yield put({ type: String(ClearVerificationData) })
    }
  }
}

function* sagaCancelVerification() {
  const cancelUrl: string = yield select(getCancelUrl)
  yield put(replace(cancelUrl))
  yield put({ type: String(ClearVerificationData) })
}

export default function* verificationViewSagas() {
  yield all([
    takeEvery(String(CreateVerification), sagaCreateVerification),
    takeEvery(String(UpdateVerificationCode), sagaHandleCodeSubmit),
    takeEvery(String(CancelVerification), sagaCancelVerification),
  ])
}
