import { createAction } from 'redux-actions'

const ns = 'verification/'

export const CreateVerification = createAction(`${ns}CREATE_VERIFICATION`)
export const SetVerificationUrls = createAction(`${ns}SET_VERIFICATION_URLS`)
export const UpdateVerificationCode = createAction(`${ns}UPDATE_VERIFICATION_CODE`)
export const VerificationLoading = createAction(`${ns}VERIFICATION_LOADING`)
export const ClearVerificationData = createAction(`${ns}CLEAR_VERIFICATION_DATA`)
export const CancelVerification = createAction(`${ns}CANCEL_VERIFICATION`)
