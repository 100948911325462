import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import i18n from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import { Action } from 'redux-actions'
import {
  GetCompanyData,
  GetCompanyDataFail,
  GetCompanyDataSuccess,
  SaveLedgerDetails,
  UpdateLedger,
  GetCompanyFormPrefaceData,
  SaveCompanyFormPrefaceData,
  GetCompanyFormPrefaceDataSuccess,
  SaveCompanyFormPrefaceDataSuccess,
  GetCompanyFormPrefaceDataFailure,
  SaveCompanyFormPrefaceDataFailure,
  GetDelawareScrapedData,
  GetDelawareScrapedDataFailure,
  GetDelawareScrapedDataSuccess,
} from './CompanyFormActions'
import {
  GetLedgerDetails$Response,
  LedgerCompanyType,
  SaveLedgerDetails$Request,
  StockType,
  UpdateLedgerDetails$Request,
} from 'services/api.types'
import { INotification } from 'common/store/notifications/NotificationReducer'
import { addNotification, clearNotification } from 'common/store/notifications/NotificationActions'
import { AddDashboardNotification } from 'views/dashboard/DashboardViewActions'
import { ICompanyFormFields } from './CompanyFormFieldNames'
import { paths } from 'common/router/routePaths'
import { push } from 'connected-react-router'
import api from 'services/Api.services'
import { NotificationType } from 'components/ui/notification/Notification'
import { getCompanyDataLoading } from './CompanyFormSelectors'
import { SetLoaded, SetLoading } from 'views/issuance/IssuanceViewActions'
import * as _ from 'lodash'
import { DraftStorageEnum } from 'common/constants/draft-storage'
import { ICompanyFormPrefaceData } from 'views/settings/children/company/CompanyFormReducer'
import DraftStorage from 'common/utils/draftStorage'
import delawareScrape, { IDelawareData, IDelawareStockDetails } from '../../../../common/utils/delawareScrape'
import { getI18n } from 'react-i18next'
import { CommonStockMode, IStockFormPrefaceData, StockFormMode } from '../stock/StockFormReducer'
import { IStockFormTypes, IStockFormFields } from '../stock/StockFormFieldNames'

function* sagaGetCompanyData() {
  try {
    const response: GetLedgerDetails$Response = yield call(api.ledger.getLedgerDetails)
    let regNr
    if (response.registrationNumber) {
      regNr = response.registrationNumber
    } else {
      const preface: ICompanyFormPrefaceData = yield call(DraftStorage.get, DraftStorageEnum.ONBOARDING_COMPANY)
      regNr = preface?.registrationNumber
    }

    if (response.name) {
      const company: ICompanyFormFields = {
        address: {
          apartment: response.officeAddress.apartment,
          buildingNumber: response.officeAddress.buildingNumber,
          city: response.officeAddress.city,
          country: response.officeAddress.country,
          state: response.officeAddress.state,
          streetAddress: response.officeAddress.streetAddress,
          zipCode: response.officeAddress.zipCode,
        },
        historicLedger: undefined,
        registrationNumber: regNr,
        registrationDate: response.registrationDate,
        name: response.name,
        documents: response.documents,
      }
      yield put({ type: String(GetCompanyDataSuccess), payload: company })
    } else {
      yield put({ type: String(GetCompanyDataSuccess) })
    }
  } catch (e) {
    yield put({ type: String(GetCompanyDataFail) })
    const notification: INotification = {
      ...e.response?.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaUpdateLedger(action: Action<ICompanyFormFields>) {
  const isLoading: boolean = yield select(getCompanyDataLoading)
  if (isLoading) {
    return
  }
  try {
    yield put({ type: String(SetLoading) })
    const requestData: UpdateLedgerDetails$Request = getRequestData(action.payload)

    yield call(api.ledger.updateLedgerDetails, requestData)
    yield put({ type: String(GetCompanyData) })

    yield put(push(paths.dashboard.root))

    // set dashboard success message
    yield put({
      type: String(AddDashboardNotification),
      payload: {
        title: i18n.t('frontendNotifications:success.update-company-data'),
      },
    })
    yield put({ type: String(SetLoaded) })
  } catch (e) {
    yield put({ type: String(SetLoaded) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaCreateLedger(action: Action<ICompanyFormFields>) {
  const isLoading: boolean = yield select(getCompanyDataLoading)
  if (isLoading) {
    return
  }
  try {
    yield put({ type: String(SetLoading) })
    const requestData: SaveLedgerDetails$Request = {
      ..._.omit(getRequestData(action.payload), 'type'),
      historicLedger: action.payload.historicLedger,
    }

    yield call(api.ledger.saveLedgerDetails, requestData)
    yield put({ type: String(GetCompanyData) })

    yield put({ type: String(SetLoaded) })
  } catch (e) {
    yield put({ type: String(SetLoaded) })
    const notification: INotification = {
      ...e.response.data,
      type: NotificationType.ERROR,
    }
    yield put({ type: String(addNotification), payload: notification })
  }
}

function* sagaGetCompanyFormPrefaceData() {
  try {
    const data: ICompanyFormPrefaceData = yield call(DraftStorage.get, DraftStorageEnum.ONBOARDING_COMPANY)
    yield put(GetCompanyFormPrefaceDataSuccess(data))
  } catch (e) {
    yield put(GetCompanyFormPrefaceDataFailure(e))
  }
}

function* sagaSaveCompanyFormPrefaceData(action: Action<ICompanyFormPrefaceData>) {
  const prefaceData = { ...action.payload }
  try {
    const data = JSON.stringify(prefaceData)
    yield call(DraftStorage.save, DraftStorageEnum.ONBOARDING_COMPANY, data)
    yield put(SaveCompanyFormPrefaceDataSuccess())
  } catch (e) {
    yield put(SaveCompanyFormPrefaceDataFailure(e))
  }
}

function* sagaGetDelawareScrapedData(action: Action<string>) {
  try {
    const delawareScrapedData: IDelawareData = yield call(delawareScrape, action.payload)
    if (_.isEmpty(delawareScrapedData?.companyDetails)) {
      const notification: INotification = {
        description: getI18n().t(`info.delaware-scrapeing-notification`, { ns: 'frontendNotifications' }),
        type: NotificationType.INFO,
      }
      yield call(DraftStorage.clear, DraftStorageEnum.ONBOARDING_STOCK)
      yield put({ type: String(addNotification), payload: notification })
      yield put(GetDelawareScrapedDataFailure())
    } else {
      if (!_.isEmpty(delawareScrapedData?.stockDetails)) {
        const commonStock: IStockFormFields[] = delawareScrapedData.stockDetails
          .filter(stock => stock.class.toLowerCase().includes('common'))
          .map((stock, i) => {
            const noParValue = stock.parValue === 0
            return generateStocks(i, noParValue, stock, StockType.COMMON, true)
          })
        const preferredStock: IStockFormFields[] = delawareScrapedData.stockDetails
          .filter(
            stock => stock.class.toLowerCase().includes('pref') || stock.class.toLowerCase().includes('preferred'),
          )
          .map((stock, i) => {
            const noParValue = stock.parValue === 0
            return generateStocks(i, noParValue, stock, StockType.PREFERRED, null)
          })
        const otherStock: IStockFormFields[] = delawareScrapedData.stockDetails
          .filter(
            stock =>
              !stock.class.toLowerCase().includes('pref') &&
              !stock.class.toLowerCase().includes('preferred') &&
              !stock.class.toLowerCase().includes('common'),
          )
          .map((stock, i) => {
            const noParValue = stock.parValue === 0
            return generateStocks(i, noParValue, stock, StockType.OTHER, null)
          })

        const remappedValues: IStockFormTypes = {
          otherStock,
          preferredStock,
          commonStock,
        }
        const delawareScrapedStock: IStockFormPrefaceData = {
          formMode: {
            mode: StockFormMode.FULL,
            commonStock: CommonStockMode.CLASSES,
          },
          delawareStock: remappedValues,
        }
        const stockData = JSON.stringify(delawareScrapedStock)
        yield call(DraftStorage.save, DraftStorageEnum.ONBOARDING_STOCK, stockData)
      } else {
        yield call(DraftStorage.clear, DraftStorageEnum.ONBOARDING_STOCK)
      }

      yield put({ type: String(clearNotification) })
      const notification: INotification = {
        description: getI18n().t(`success.delaware-scrapeing-success`, { ns: 'frontendNotifications' }),
        type: NotificationType.SUCCESS,
      }
      yield put({ type: String(addNotification), payload: notification })
      yield put(GetDelawareScrapedDataSuccess(delawareScrapedData))
    }
  } catch (e) {
    yield put(GetDelawareScrapedDataFailure(e))
  }
}

export default function* CompanyFormSagas() {
  yield all([
    takeEvery(String(GetCompanyData), sagaGetCompanyData),
    takeEvery(String(SaveLedgerDetails), sagaCreateLedger),
    takeEvery(String(UpdateLedger), sagaUpdateLedger),
    takeEvery(String(GetDelawareScrapedData), sagaGetDelawareScrapedData),
    takeEvery(String(GetCompanyFormPrefaceData), sagaGetCompanyFormPrefaceData),
    takeEvery(String(SaveCompanyFormPrefaceData), sagaSaveCompanyFormPrefaceData),
  ])
}

function generateStocks(
  i: number,
  noParValue: boolean,
  stock: IDelawareStockDetails,
  type: StockType,
  voting: boolean,
): IStockFormFields {
  return {
    id: uuidv4(),
    name: stock.class,
    firstInOrder: i === 0,
    totalNumberOfShares: stock.numberOfShares,
    parValue: stock.parValue,
    type,
    noParValue,
    isRequired: false,
    votesPerShare: voting ? 1 : 0,
    voting,
    series: [],
    parentId: '',
    parentTotalNumberOfShares: 0,
  }
}

function getRequestData(payload: ICompanyFormFields): UpdateLedgerDetails$Request {
  const { documents, name, address, registrationDate, registrationNumber } = payload

  return {
    documents,
    name,
    officeAddress: {
      apartment: address.apartment,
      buildingNumber: address.buildingNumber,
      city: address.city,
      country: address.country,
      state: address.state,
      streetAddress: address.streetAddress,
      zipCode: address.zipCode,
      notEmpty: false,
    },
    registrationDate,
    registrationNumber,
    type: LedgerCompanyType.CORPORATION,
  }
}
